<template>
  <div
    class="rounded-lg shadow-lg bg-white"
    aria-orientation="vertical"
    aria-labelledby="user-menu-button"
    tabindex="-1"
  >
    <header class="text-gray-800 text-xl p-5 text-center">
      {{ label }}
    </header>

    <div class="p-3">
      <template v-for="link in links" :key="`link-${link.label}`">
        <router-link
          v-if="hasPermission(link.permission)"
          :to="link.to"
          class="block px-4 py-3 text-sm text-gray-800 text-center break-words my-2"
          :class="{
            dark: isDark,
            light: isLight,
            colorful: isColorful,
          }"
        >
          {{ link.label }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
import { permissionMixin } from "@/mixins/permission.js";
import { mapGetters } from "vuex";

export default {
  props: {
    label: {
      type: String,
      default: "Actions",
    },
    links: {
      type: Array,
      required: true,
    },
  },
  mixins: [permissionMixin],
  computed: {
    ...mapGetters("user", ["isLight", "isDark", "isColorful"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/option-links.scss";
</style>
