<template>
  <BaseLabel :label="label" v-if="label" />

  <div class="flex relative">
    <div :class="disabledClass">{{ value }}</div>
    <!-- <input type="text" :class="disabledClass" :value="value" disabled="true" /> -->
    <span
      class="inline-flex items-center px-3 border-t border-r border-b border-gray-300 text-gray-500 shadow-sm text-sm bg-gray-100"
    >
      {{ addonLabel }}
    </span>
  </div>
</template>

<script>
import { inputMixin } from "@/mixins/form.js";

export default {
  props: {
    size: {
      type: String,
      default: "md",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    addonLabel: {
      type: String,
      default: "",
    },
  },
  mixins: [inputMixin],
};
</script>

<style lang="scss" scoped>
div {
  min-height: 40px;
  min-width: 100px;
}
</style>
