const VIEW_ANY_PERMISSION_CATEGORIES = "view-any-permission-categories";
const VIEW_PERMISSION_CATEGORY = "view-permission-category";
const CREATE_PERMISSION_CATEGORY = "create-permission-category";
const UPDATE_PERMISSION_CATEGORY = "update-permission-category";
const DELETE_PERMISSION_CATEGORY = "delete-permission-category";
const VIEW_ANY_USERS = "view-any-users";
const VIEW_USER = "view-user";
const CREATE_USER = "create-user";
const UPDATE_USER = "update-user";
const DELETE_USER = "delete-user";
const CHANGE_PASSWORD_USER = "change-password-user";
const STRIPE_CREATE_CUSTOMER_USER = "stripe-create-customer-user";
const STRIPE_VIEW_CUSTOMER_USER = "stripe-view-customer-user";
const STRIPE_UPDATE_CUSTOMER_USER = "stripe-update-customer-user";
const STRIPE_VIEW_CARDS_USER = "stripe-view-cards-user";
const STRIPE_CREATE_CARD_USER = "stripe-create-card-user";
const STRIPE_UPDATE_CARD_USER = "stripe-update-card-user";
const STRIPE_DELETE_CARD_USER = "stripe-delete-card-user";
const STRIPE_CREATE_PAYMENT_INTENT_USER = "stripe-create-payment-intent-user";
const STRIPE_VIEW_INVOICES_USER = "stripe-view-invoices-user";
const STRIPE_VIEW_PAYMENTS_USER = "stripe-view-payments-user";
const VIEW_ANY_GENDERS = "view-any-genders";
const VIEW_GENDER = "view-gender";
const CREATE_GENDER = "create-gender";
const UPDATE_GENDER = "update-gender";
const DELETE_GENDER = "delete-gender";
const VIEW_ANY_STAFF = "view-any-staff";
const VIEW_STAFF = "view-staff";
const CREATE_STAFF = "create-staff";
const UPDATE_STAFF = "update-staff";
const DELETE_STAFF = "delete-staff";
const VIEW_REPORT_STAFF = "view-report-staff";
const VIEW_ANY_CHAPTERS = "view-any-chapters";
const VIEW_CHAPTER = "view-chapter";
const CREATE_CHAPTER = "create-chapter";
const UPDATE_CHAPTER = "update-chapter";
const DELETE_CHAPTER = "delete-chapter";
const VIEW_REPORT_CHAPTER = "view-report-chapter";
const VIEW_ANY_FAMILY_STATUSES = "view-any-family-statuses";
const VIEW_FAMILY_STATUS = "view-family-status";
const CREATE_FAMILY_STATUS = "create-family-status";
const UPDATE_FAMILY_STATUS = "update-family-status";
const DELETE_FAMILY_STATUS = "delete-family-status";
const VIEW_ANY_DROPPED_STATUSES = "view-any-dropped-statuses";
const VIEW_DROPPED_STATUS = "view-dropped-status";
const CREATE_DROPPED_STATUS = "create-dropped-status";
const UPDATE_DROPPED_STATUS = "update-dropped-status";
const DELETE_DROPPED_STATUS = "delete-dropped-status";
const VIEW_ANY_LIMBO_ARCHIVE_REASONS = "view-any-limbo-archive-reasons";
const VIEW_LIMBO_ARCHIVE_REASON = "view-limbo-archive-reason";
const CREATE_LIMBO_ARCHIVE_REASON = "create-limbo-archive-reason";
const UPDATE_LIMBO_ARCHIVE_REASON = "update-limbo-archive-reason";
const DELETE_LIMBO_ARCHIVE_REASON = "delete-limbo-archive-reason";
const VIEW_ANY_EDUCATION = "view-any-education";
const VIEW_EDUCATION = "view-education";
const CREATE_EDUCATION = "create-education";
const UPDATE_EDUCATION = "update-education";
const DELETE_EDUCATION = "delete-education";
const VIEW_ANY_LANGUAGES = "view-any-languages";
const VIEW_LANGUAGE = "view-language";
const CREATE_LANGUAGE = "create-language";
const UPDATE_LANGUAGE = "update-language";
const DELETE_LANGUAGE = "delete-language";
const VIEW_ANY_CONTACTS = "view-any-contacts";
const VIEW_CONTACT = "view-contact";
const CREATE_CONTACT = "create-contact";
const UPDATE_CONTACT = "update-contact";
const DELETE_CONTACT = "delete-contact";
const VIEW_ANY_HEARD_FROMS = "view-any-heard-froms";
const VIEW_HEARD_FROM = "view-heard-from";
const CREATE_HEARD_FROM = "create-heard-from";
const UPDATE_HEARD_FROM = "update-heard-from";
const DELETE_HEARD_FROM = "delete-heard-from";
const VIEW_ANY_PARENT_TYPES = "view-any-parent-types";
const VIEW_PARENT_TYPE = "view-parent-type";
const CREATE_PARENT_TYPE = "create-parent-type";
const UPDATE_PARENT_TYPE = "update-parent-type";
const DELETE_PARENT_TYPE = "delete-parent-type";
const VIEW_ANY_FAMILIES = "view-any-families";
const VIEW_FAMILY = "view-family";
const CREATE_FAMILY = "create-family";
const UPDATE_FAMILY = "update-family";
const DELETE_FAMILY = "delete-family";
const TOGGLE_FAMILY = "toggle-family";
const EXPORT_FAMILY = "export-family";
const ARCHIVE_FAMILY = "archive-family";
const APPROVE_FAMILY = "approve-family";
const TOGGLE_CONTACTED_FAMILY = "toggle-contacted-family";
const SEND_IN_PERSON_PAPERWORK_REMINDER_FAMILY =
  "send-in-person-paperwork-reminder-family";
const SEND_SKYPE_PAPERWORK_REMINDER_FAMILY =
  "send-skype-paperwork-reminder-family";
const SEND_PROGRAM_NOTIFICATION_FAMILY = "send-program-notification-family";
const TOGGLE_COURSES_FAMILY = "toggle-courses-family";
const VIEW_ANY_ROLES = "view-any-roles";
const VIEW_ROLE = "view-role";
const CREATE_ROLE = "create-role";
const UPDATE_ROLE = "update-role";
const DELETE_ROLE = "delete-role";
const ATTACH_PERMISSION_ROLE = "attach-permission-role";
const VIEW_ANY_STAFF_TEAMS = "view-any-staff-teams";
const VIEW_STAFF_TEAM = "view-staff-team";
const CREATE_STAFF_TEAM = "create-staff-team";
const UPDATE_STAFF_TEAM = "update-staff-team";
const DELETE_STAFF_TEAM = "delete-staff-team";
const SORT_STAFF_TEAM = "sort-staff-team";
const VIEW_FAMILY_STAFF_TEAM = "view-family-staff-team";
const VIEW_ANY_PROGRAM_TYPES = "view-any-program-types";
const VIEW_PROGRAM_TYPE = "view-program-type";
const CREATE_PROGRAM_TYPE = "create-program-type";
const UPDATE_PROGRAM_TYPE = "update-program-type";
const DELETE_PROGRAM_TYPE = "delete-program-type";
const VIEW_ANY_PROGRAM_CODES = "view-any-program-codes";
const VIEW_PROGRAM_CODE = "view-program-code";
const CREATE_PROGRAM_CODE = "create-program-code";
const UPDATE_PROGRAM_CODE = "update-program-code";
const DELETE_PROGRAM_CODE = "delete-program-code";
const VIEW_ANY_CLIENT_LIVE_WITHS = "view-any-client-live-withs";
const VIEW_CLIENT_LIVE_WITH = "view-client-live-with";
const CREATE_CLIENT_LIVE_WITH = "create-client-live-with";
const UPDATE_CLIENT_LIVE_WITH = "update-client-live-with";
const DELETE_CLIENT_LIVE_WITH = "delete-client-live-with";
const VIEW_ANY_CLIENT_STATUSES = "view-any-client-statuses";
const VIEW_CLIENT_STATUS = "view-client-status";
const CREATE_CLIENT_STATUS = "create-client-status";
const UPDATE_CLIENT_STATUS = "update-client-status";
const DELETE_CLIENT_STATUS = "delete-client-status";
const VIEW_ANY_CLIENTS = "view-any-clients";
const VIEW_CLIENT = "view-client";
const CREATE_CLIENT = "create-client";
const UPDATE_CLIENT = "update-client";
const DELETE_CLIENT = "delete-client";
const EXPORT_CLIENT = "export-client";
const SEND_EVALUATION_VIDEO_COMMENT_NOTIFICATION_CLIENT =
  "send-evaluation-video-comment-notification-client";
const CONTACT_COACH_CLIENT = "contact-coach-client";
const VIEW_OWN_CLIENT = "view-own-client";
const VIEW_ANY_EMAIL_LOGS = "view-any-email-logs";
const VIEW_EMAIL_LOG = "view-email-log";
const CREATE_EMAIL_LOG = "create-email-log";
const UPDATE_EMAIL_LOG = "update-email-log";
const DELETE_EMAIL_LOG = "delete-email-log";
const VIEW_ANY_EVALUATION_VIDEO_TAGS = "view-any-evaluation-video-tags";
const VIEW_EVALUATION_VIDEO_TAG = "view-evaluation-video-tag";
const CREATE_EVALUATION_VIDEO_TAG = "create-evaluation-video-tag";
const UPDATE_EVALUATION_VIDEO_TAG = "update-evaluation-video-tag";
const DELETE_EVALUATION_VIDEO_TAG = "delete-evaluation-video-tag";
const VIEW_ANY_EVALUATION_VIDEOS = "view-any-evaluation-videos";
const VIEW_EVALUATION_VIDEO = "view-evaluation-video";
const CREATE_EVALUATION_VIDEO = "create-evaluation-video";
const UPDATE_EVALUATION_VIDEO = "update-evaluation-video";
const DELETE_EVALUATION_VIDEO = "delete-evaluation-video";
const TOGGLE_ALL_SEEN_EVALUATION_VIDEO = "toggle-all-seen-evaluation-video";
const VIEW_CLIENT_EVALUATION_VIDEO = "view-client-evaluation-video";
const VIEW_ANY_ACTIVITY_CATEGORIES = "view-any-activity-categories";
const VIEW_ACTIVITY_CATEGORY = "view-activity-category";
const CREATE_ACTIVITY_CATEGORY = "create-activity-category";
const UPDATE_ACTIVITY_CATEGORY = "update-activity-category";
const DELETE_ACTIVITY_CATEGORY = "delete-activity-category";
const VIEW_ANY_ACTIVITIES = "view-any-activities";
const VIEW_ACTIVITY = "view-activity";
const CREATE_ACTIVITY = "create-activity";
const UPDATE_ACTIVITY = "update-activity";
const DELETE_ACTIVITY = "delete-activity";
const VIEW_ANY_ACTIVITY_LINK_TYPES = "view-any-activity-link-types";
const VIEW_ACTIVITY_LINK_TYPE = "view-activity-link-type";
const CREATE_ACTIVITY_LINK_TYPE = "create-activity-link-type";
const UPDATE_ACTIVITY_LINK_TYPE = "update-activity-link-type";
const DELETE_ACTIVITY_LINK_TYPE = "delete-activity-link-type";
const VIEW_ANY_ACTIVITY_LINKS = "view-any-activity-links";
const VIEW_ACTIVITY_LINK = "view-activity-link";
const CREATE_ACTIVITY_LINK = "create-activity-link";
const UPDATE_ACTIVITY_LINK = "update-activity-link";
const DELETE_ACTIVITY_LINK = "delete-activity-link";
const VIEW_ANY_PROGRAMS = "view-any-programs";
const VIEW_PROGRAM = "view-program";
const CREATE_PROGRAM = "create-program";
const UPDATE_PROGRAM = "update-program";
const DELETE_PROGRAM = "delete-program";
const COPY_PROGRAM = "copy-program";
const GENERATE_EVALUATOR_PDF_PROGRAM = "generate-evaluator-pdf-program";
const GENERATE_FAMILY_PDF_PROGRAM = "generate-family-pdf-program";
const VIEW_ANY_PROGRAM_ACTIVITIES = "view-any-program-activities";
const VIEW_PROGRAM_ACTIVITY = "view-program-activity";
const CREATE_PROGRAM_ACTIVITY = "create-program-activity";
const UPDATE_PROGRAM_ACTIVITY = "update-program-activity";
const DELETE_PROGRAM_ACTIVITY = "delete-program-activity";
const SORT_PROGRAM_ACTIVITY = "sort-program-activity";
const VIEW_CLIENT_PROGRAM_ACTIVITY = "view-client-program-activity";
const VIEW_ANY_DOCUMENT_TYPES = "view-any-document-types";
const VIEW_DOCUMENT_TYPE = "view-document-type";
const CREATE_DOCUMENT_TYPE = "create-document-type";
const UPDATE_DOCUMENT_TYPE = "update-document-type";
const DELETE_DOCUMENT_TYPE = "delete-document-type";
const VIEW_ANY_DOCUMENTS = "view-any-documents";
const VIEW_DOCUMENT = "view-document";
const CREATE_DOCUMENT = "create-document";
const UPDATE_DOCUMENT = "update-document";
const DELETE_DOCUMENT = "delete-document";
const VIEW_ANY_DEVELOPMENTAL_PROFILE_STATUSES =
  "view-any-developmental-profile-statuses";
const VIEW_DEVELOPMENTAL_PROFILE_STATUS = "view-developmental-profile-status";
const CREATE_DEVELOPMENTAL_PROFILE_STATUS =
  "create-developmental-profile-status";
const UPDATE_DEVELOPMENTAL_PROFILE_STATUS =
  "update-developmental-profile-status";
const DELETE_DEVELOPMENTAL_PROFILE_STATUS =
  "delete-developmental-profile-status";
const VIEW_ANY_EVALUATION_TYPES = "view-any-evaluation-types";
const VIEW_EVALUATION_TYPE = "view-evaluation-type";
const CREATE_EVALUATION_TYPE = "create-evaluation-type";
const UPDATE_EVALUATION_TYPE = "update-evaluation-type";
const DELETE_EVALUATION_TYPE = "delete-evaluation-type";
const VIEW_ANY_DEVELOPMENTAL_PROFILE_CATEGORIES =
  "view-any-developmental-profile-categories";
const VIEW_DEVELOPMENTAL_PROFILE_CATEGORY =
  "view-developmental-profile-category";
const CREATE_DEVELOPMENTAL_PROFILE_CATEGORY =
  "create-developmental-profile-category";
const UPDATE_DEVELOPMENTAL_PROFILE_CATEGORY =
  "update-developmental-profile-category";
const DELETE_DEVELOPMENTAL_PROFILE_CATEGORY =
  "delete-developmental-profile-category";
const VIEW_ANY_DEVELOPMENTAL_PROFILE_DESCRIPTIONS =
  "view-any-developmental-profile-descriptions";
const VIEW_DEVELOPMENTAL_PROFILE_DESCRIPTION =
  "view-developmental-profile-description";
const CREATE_DEVELOPMENTAL_PROFILE_DESCRIPTION =
  "create-developmental-profile-description";
const UPDATE_DEVELOPMENTAL_PROFILE_DESCRIPTION =
  "update-developmental-profile-description";
const DELETE_DEVELOPMENTAL_PROFILE_DESCRIPTION =
  "delete-developmental-profile-description";
const VIEW_ANY_DEVELOPMENTAL_PROFILE_ITEMS =
  "view-any-developmental-profile-items";
const VIEW_DEVELOPMENTAL_PROFILE_ITEM = "view-developmental-profile-item";
const CREATE_DEVELOPMENTAL_PROFILE_ITEM = "create-developmental-profile-item";
const UPDATE_DEVELOPMENTAL_PROFILE_ITEM = "update-developmental-profile-item";
const DELETE_DEVELOPMENTAL_PROFILE_ITEM = "delete-developmental-profile-item";
const SORT_DEVELOPMENTAL_PROFILE_ITEM = "sort-developmental-profile-item";

const VIEW_ANY_COMMON_FORMS = "view-any-common-forms";
const VIEW_COMMON_FORM = "view-common-form";
const CREATE_COMMON_FORM = "create-common-form";
const UPDATE_COMMON_FORM = "update-common-form";
const DELETE_COMMON_FORM = "delete-common-form";
const SORT_COMMON_FORM = "sort-common-form";

const VIEW_ANY_EVALUATIONS = "view-any-evaluations";
const VIEW_EVALUATION = "view-evaluation";
const CREATE_EVALUATION = "create-evaluation";
const UPDATE_EVALUATION = "update-evaluation";
const DELETE_EVALUATION = "delete-evaluation";
const GENERATE_PDF_EVALUATION = "generate-pdf-evaluation";
const VIEW_CLIENT_EVALUATION = "view-client-evaluation";
const VIEW_ANY_RE_EVALUATION_TIME_PERIODS =
  "view-any-re-evaluation-time-periods";
const VIEW_RE_EVALUATION_TIME_PERIOD = "view-re-evaluation-time-period";
const CREATE_RE_EVALUATION_TIME_PERIOD = "create-re-evaluation-time-period";
const UPDATE_RE_EVALUATION_TIME_PERIOD = "update-re-evaluation-time-period";
const DELETE_RE_EVALUATION_TIME_PERIOD = "delete-re-evaluation-time-period";
const VIEW_ANY_RE_EVALUATION_STATUSES = "view-any-re-evaluation-statuses";
const VIEW_RE_EVALUATION_STATUS = "view-re-evaluation-status";
const CREATE_RE_EVALUATION_STATUS = "create-re-evaluation-status";
const UPDATE_RE_EVALUATION_STATUS = "update-re-evaluation-status";
const DELETE_RE_EVALUATION_STATUS = "delete-re-evaluation-status";
const VIEW_ANY_RE_EVALUATION_ASSISTANTS = "view-any-re-evaluation-assistants";
const VIEW_RE_EVALUATION_ASSISTANT = "view-re-evaluation-assistant";
const CREATE_RE_EVALUATION_ASSISTANT = "create-re-evaluation-assistant";
const UPDATE_RE_EVALUATION_ASSISTANT = "update-re-evaluation-assistant";
const DELETE_RE_EVALUATION_ASSISTANT = "delete-re-evaluation-assistant";
const VIEW_ANY_RE_EVALUATION_PROCESSING_ACTIVITY_TYPES =
  "view-any-re-evaluation-processing-activity-types";
const VIEW_RE_EVALUATION_PROCESSING_ACTIVITY_TYPE =
  "view-re-evaluation-processing-activity-type";
const CREATE_RE_EVALUATION_PROCESSING_ACTIVITY_TYPE =
  "create-re-evaluation-processing-activity-type";
const UPDATE_RE_EVALUATION_PROCESSING_ACTIVITY_TYPE =
  "update-re-evaluation-processing-activity-type";
const DELETE_RE_EVALUATION_PROCESSING_ACTIVITY_TYPE =
  "delete-re-evaluation-processing-activity-type";
const VIEW_ANY_RE_EVALUATIONS = "view-any-re-evaluations";
const VIEW_RE_EVALUATION = "view-re-evaluation";
const CREATE_RE_EVALUATION = "create-re-evaluation";
const UPDATE_RE_EVALUATION = "update-re-evaluation";
const DELETE_RE_EVALUATION = "delete-re-evaluation";
const GENERATE_PDF_RE_EVALUATION = "generate-pdf-re-evaluation";
const TOGGLE_RE_EVALUATION = "toggle-re-evaluation";
const VIEW_CLIENT_RE_EVALUATION = "view-client-re-evaluation";
const VIEW_ANY_RE_EVALUATION_ACTIVITIES = "view-any-re-evaluation-activities";
const VIEW_RE_EVALUATION_ACTIVITY = "view-re-evaluation-activity";
const CREATE_RE_EVALUATION_ACTIVITY = "create-re-evaluation-activity";
const UPDATE_RE_EVALUATION_ACTIVITY = "update-re-evaluation-activity";
const DELETE_RE_EVALUATION_ACTIVITY = "delete-re-evaluation-activity";
const VIEW_CLIENT_RE_EVALUATION_ACTIVITY = "view-client-re-evaluation-activity";
const VIEW_ANY_RE_EVALUATION_CLASSES = "view-any-re-evaluation-classes";
const VIEW_RE_EVALUATION_CLASS = "view-re-evaluation-class";
const CREATE_RE_EVALUATION_CLASS = "create-re-evaluation-class";
const UPDATE_RE_EVALUATION_CLASS = "update-re-evaluation-class";
const DELETE_RE_EVALUATION_CLASS = "delete-re-evaluation-class";
const VIEW_CLIENT_RE_EVALUATION_CLASS = "view-client-re-evaluation-class";
const VIEW_ANY_RE_EVALUATION_DOCTORS = "view-any-re-evaluation-doctors";
const VIEW_RE_EVALUATION_DOCTOR = "view-re-evaluation-doctor";
const CREATE_RE_EVALUATION_DOCTOR = "create-re-evaluation-doctor";
const UPDATE_RE_EVALUATION_DOCTOR = "update-re-evaluation-doctor";
const DELETE_RE_EVALUATION_DOCTOR = "delete-re-evaluation-doctor";
const VIEW_CLIENT_RE_EVALUATION_DOCTOR = "view-client-re-evaluation-doctor";
const VIEW_ANY_RE_EVALUATION_MEDICATIONS = "view-any-re-evaluation-medications";
const VIEW_RE_EVALUATION_MEDICATION = "view-re-evaluation-medication";
const CREATE_RE_EVALUATION_MEDICATION = "create-re-evaluation-medication";
const UPDATE_RE_EVALUATION_MEDICATION = "update-re-evaluation-medication";
const DELETE_RE_EVALUATION_MEDICATION = "delete-re-evaluation-medication";
const VIEW_CLIENT_RE_EVALUATION_MEDICATION =
  "view-client-re-evaluation-medication";
const VIEW_ANY_COURSE_STATUSES = "view-any-course-statuses";
const VIEW_COURSE_STATUS = "view-course-status";
const CREATE_COURSE_STATUS = "create-course-status";
const UPDATE_COURSE_STATUS = "update-course-status";
const DELETE_COURSE_STATUS = "delete-course-status";
const VIEW_ANY_COURSES = "view-any-courses";
const VIEW_COURSE = "view-course";
const CREATE_COURSE = "create-course";
const UPDATE_COURSE = "update-course";
const DELETE_COURSE = "delete-course";
const SORT_COURSE = "sort-course";
const VIEW_OWN_COURSE = "view-own-course";
const VIEW_ANY_OWN_COURSE = "view-any-own-course";
const VIEW_ANY_COURSE_CATEGORIES = "view-any-course-categories";
const VIEW_COURSE_CATEGORY = "view-course-category";
const CREATE_COURSE_CATEGORY = "create-course-category";
const UPDATE_COURSE_CATEGORY = "update-course-category";
const DELETE_COURSE_CATEGORY = "delete-course-category";
const SORT_COURSE_CATEGORY = "sort-course-category";
const VIEW_OWN_COURSE_CATEGORY = "view-own-course-category";
const VIEW_ANY_COURSE_ACTIVITIES = "view-any-course-activities";
const VIEW_COURSE_ACTIVITY = "view-course-activity";
const CREATE_COURSE_ACTIVITY = "create-course-activity";
const UPDATE_COURSE_ACTIVITY = "update-course-activity";
const DELETE_COURSE_ACTIVITY = "delete-course-activity";
const SORT_COURSE_ACTIVITY = "sort-course-activity";
const VIEW_OWN_COURSE_ACTIVITY = "view-own-course-activity";
const VIEW_ANY_COURSE_CATEGORY_CONTENTS = "view-any-course-category-contents";
const VIEW_COURSE_CATEGORY_CONTENT = "view-course-category-content";
const CREATE_COURSE_CATEGORY_CONTENT = "create-course-category-content";
const UPDATE_COURSE_CATEGORY_CONTENT = "update-course-category-content";
const DELETE_COURSE_CATEGORY_CONTENT = "delete-course-category-content";
const VIEW_ANY_LIMBO_STEPS = "view-any-limbo-steps";
const VIEW_LIMBO_STEP = "view-limbo-step";
const CREATE_LIMBO_STEP = "create-limbo-step";
const UPDATE_LIMBO_STEP = "update-limbo-step";
const DELETE_LIMBO_STEP = "delete-limbo-step";
const CHANGE_STATUS_LIMBO_STEP = "change-status-limbo-step";
const VIEW_ANY_LIMBO_STEP_ARTICLES = "view-any-limbo-step-articles";
const VIEW_LIMBO_STEP_ARTICLE = "view-limbo-step-article";
const CREATE_LIMBO_STEP_ARTICLE = "create-limbo-step-article";
const UPDATE_LIMBO_STEP_ARTICLE = "update-limbo-step-article";
const DELETE_LIMBO_STEP_ARTICLE = "delete-limbo-step-article";
const VIEW_ANY_APPLICATION_FORM_CATEGORIES =
  "view-any-application-form-categories";
const VIEW_APPLICATION_FORM_CATEGORY = "view-application-form-category";
const CREATE_APPLICATION_FORM_CATEGORY = "create-application-form-category";
const UPDATE_APPLICATION_FORM_CATEGORY = "update-application-form-category";
const DELETE_APPLICATION_FORM_CATEGORY = "delete-application-form-category";
const VIEW_ANY_APPLICATION_FORM_TYPES = "view-any-application-form-types";
const VIEW_APPLICATION_FORM_TYPE = "view-application-form-type";
const CREATE_APPLICATION_FORM_TYPE = "create-application-form-type";
const UPDATE_APPLICATION_FORM_TYPE = "update-application-form-type";
const DELETE_APPLICATION_FORM_TYPE = "delete-application-form-type";
const VIEW_FAMILY_APPLICATION_FORM_TYPE = "view-family-application-form-type";
const VIEW_ANY_APPLICATION_FORM_PAGES = "view-any-application-form-pages";
const VIEW_APPLICATION_FORM_PAGE = "view-application-form-page";
const CREATE_APPLICATION_FORM_PAGE = "create-application-form-page";
const UPDATE_APPLICATION_FORM_PAGE = "update-application-form-page";
const DELETE_APPLICATION_FORM_PAGE = "delete-application-form-page";
const VIEW_ANY_APPLICATION_FORM_COUNTRIES =
  "view-any-application-form-countries";
const VIEW_APPLICATION_FORM_COUNTRY = "view-application-form-country";
const CREATE_APPLICATION_FORM_COUNTRY = "create-application-form-country";
const UPDATE_APPLICATION_FORM_COUNTRY = "update-application-form-country";
const DELETE_APPLICATION_FORM_COUNTRY = "delete-application-form-country";
const SORT_APPLICATION_FORM_COUNTRY = "sort-application-form-country";
const VIEW_ANY_APPLICATION_FORM_DISCOUNTS =
  "view-any-application-form-discounts";
const VIEW_APPLICATION_FORM_DISCOUNT = "view-application-form-discount";
const CREATE_APPLICATION_FORM_DISCOUNT = "create-application-form-discount";
const UPDATE_APPLICATION_FORM_DISCOUNT = "update-application-form-discount";
const DELETE_APPLICATION_FORM_DISCOUNT = "delete-application-form-discount";
const VIEW_ANY_APPLICATION_FORM_PAGE_ASSIGNMENTS =
  "view-any-application-form-page-assignments";
const VIEW_APPLICATION_FORM_PAGE_ASSIGNMENT =
  "view-application-form-page-assignment";
const CREATE_APPLICATION_FORM_PAGE_ASSIGNMENT =
  "create-application-form-page-assignment";
const UPDATE_APPLICATION_FORM_PAGE_ASSIGNMENT =
  "update-application-form-page-assignment";
const DELETE_APPLICATION_FORM_PAGE_ASSIGNMENT =
  "delete-application-form-page-assignment";
const SORT_APPLICATION_FORM_PAGE_ASSIGNMENT =
  "sort-application-form-page-assignment";
const VIEW_FAMILY_APPLICATION_FORM_PAGE_ASSIGNMENT =
  "view-family-application-form-page-assignment";
const VIEW_ANY_AUTH_LOGS = "view-any-auth-logs";
const VIEW_AUTH_LOG = "view-auth-log";
const CREATE_AUTH_LOG = "create-auth-log";
const UPDATE_AUTH_LOG = "update-auth-log";
const DELETE_AUTH_LOG = "delete-auth-log";
const VIEW_ANY_LOGS = "view-any-logs";
const VIEW_LOG = "view-log";
const CREATE_LOG = "create-log";
const UPDATE_LOG = "update-log";
const DELETE_LOG = "delete-log";
const VIEW_ANY_TRACK_TYPES = "view-any-track-types";
const VIEW_TRACK_TYPE = "view-track-type";
const CREATE_TRACK_TYPE = "create-track-type";
const UPDATE_TRACK_TYPE = "update-track-type";
const DELETE_TRACK_TYPE = "delete-track-type";
const VIEW_ANY_TRACK_OPERATIONS = "view-any-track-operations";
const VIEW_TRACK_OPERATION = "view-track-operation";
const CREATE_TRACK_OPERATION = "create-track-operation";
const UPDATE_TRACK_OPERATION = "update-track-operation";
const DELETE_TRACK_OPERATION = "delete-track-operation";
const VIEW_ANY_TRACKS = "view-any-tracks";
const VIEW_TRACK = "view-track";
const CREATE_TRACK = "create-track";
const UPDATE_TRACK = "update-track";
const DELETE_TRACK = "delete-track";
const VIEW_ANY_REPORT_STATUSES = "view-any-report-statuses";
const VIEW_REPORT_STATUS = "view-report-status";
const CREATE_REPORT_STATUS = "create-report-status";
const UPDATE_REPORT_STATUS = "update-report-status";
const DELETE_REPORT_STATUS = "delete-report-status";
const VIEW_ANY_REPORT_TYPES = "view-any-report-types";
const VIEW_REPORT_TYPE = "view-report-type";
const CREATE_REPORT_TYPE = "create-report-type";
const UPDATE_REPORT_TYPE = "update-report-type";
const DELETE_REPORT_TYPE = "delete-report-type";
const VIEW_ANY_REPORTS = "view-any-reports";
const VIEW_REPORT = "view-report";
const CREATE_REPORT = "create-report";
const UPDATE_REPORT = "update-report";
const DELETE_REPORT = "delete-report";
const VIEW_ANY_APPLICATION_FORM_STATUSES = "view-any-application-form-statuses";
const VIEW_APPLICATION_FORM_STATUS = "view-application-form-status";
const CREATE_APPLICATION_FORM_STATUS = "create-application-form-status";
const UPDATE_APPLICATION_FORM_STATUS = "update-application-form-status";
const DELETE_APPLICATION_FORM_STATUS = "delete-application-form-status";
const VIEW_ANY_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDERS =
  "view-any-application-form-physical-motor-skill-disorders";
const VIEW_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDER =
  "view-application-form-physical-motor-skill-disorder";
const CREATE_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDER =
  "create-application-form-physical-motor-skill-disorder";
const UPDATE_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDER =
  "update-application-form-physical-motor-skill-disorder";
const DELETE_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDER =
  "delete-application-form-physical-motor-skill-disorder";
const VIEW_ANY_APPLICATION_FORM_MATH_SKILLS =
  "view-any-application-form-math-skills";
const VIEW_APPLICATION_FORM_MATH_SKILL = "view-application-form-math-skill";
const CREATE_APPLICATION_FORM_MATH_SKILL = "create-application-form-math-skill";
const UPDATE_APPLICATION_FORM_MATH_SKILL = "update-application-form-math-skill";
const DELETE_APPLICATION_FORM_MATH_SKILL = "delete-application-form-math-skill";
const VIEW_ANY_APPLICATION_FORM_BEHAVIOUR_DISORDERS =
  "view-any-application-form-behaviour-disorders";
const VIEW_APPLICATION_FORM_BEHAVIOUR_DISORDER =
  "view-application-form-behaviour-disorder";
const CREATE_APPLICATION_FORM_BEHAVIOUR_DISORDER =
  "create-application-form-behaviour-disorder";
const UPDATE_APPLICATION_FORM_BEHAVIOUR_DISORDER =
  "update-application-form-behaviour-disorder";
const DELETE_APPLICATION_FORM_BEHAVIOUR_DISORDER =
  "delete-application-form-behaviour-disorder";
const VIEW_ANY_APPLICATION_FORM_CLIENT_LIVE_WITHS =
  "view-any-application-form-client-live-withs";
const VIEW_APPLICATION_FORM_CLIENT_LIVE_WITH =
  "view-application-form-client-live-with";
const CREATE_APPLICATION_FORM_CLIENT_LIVE_WITH =
  "create-application-form-client-live-with";
const UPDATE_APPLICATION_FORM_CLIENT_LIVE_WITH =
  "update-application-form-client-live-with";
const DELETE_APPLICATION_FORM_CLIENT_LIVE_WITH =
  "delete-application-form-client-live-with";
const VIEW_ANY_APPLICATION_FORM_COGNITIVE_SKILLS =
  "view-any-application-form-cognitive-skills";
const VIEW_APPLICATION_FORM_COGNITIVE_SKILL =
  "view-application-form-cognitive-skill";
const CREATE_APPLICATION_FORM_COGNITIVE_SKILL =
  "create-application-form-cognitive-skill";
const UPDATE_APPLICATION_FORM_COGNITIVE_SKILL =
  "update-application-form-cognitive-skill";
const DELETE_APPLICATION_FORM_COGNITIVE_SKILL =
  "delete-application-form-cognitive-skill";
const VIEW_ANY_APPLICATION_FORM_COME_FROMS =
  "view-any-application-form-come-froms";
const VIEW_APPLICATION_FORM_COME_FROM = "view-application-form-come-from";
const CREATE_APPLICATION_FORM_COME_FROM = "create-application-form-come-from";
const UPDATE_APPLICATION_FORM_COME_FROM = "update-application-form-come-from";
const DELETE_APPLICATION_FORM_COME_FROM = "delete-application-form-come-from";
const VIEW_ANY_APPLICATION_FORM_COMMON_ANSWERS =
  "view-any-application-form-common-answers";
const VIEW_APPLICATION_FORM_COMMON_ANSWER =
  "view-application-form-common-answer";
const CREATE_APPLICATION_FORM_COMMON_ANSWER =
  "create-application-form-common-answer";
const UPDATE_APPLICATION_FORM_COMMON_ANSWER =
  "update-application-form-common-answer";
const DELETE_APPLICATION_FORM_COMMON_ANSWER =
  "delete-application-form-common-answer";
const VIEW_ANY_APPLICATION_FORM_COMPLETED_BIES =
  "view-any-application-form-completed-bies";
const VIEW_APPLICATION_FORM_COMPLETED_BY = "view-application-form-completed-by";
const CREATE_APPLICATION_FORM_COMPLETED_BY =
  "create-application-form-completed-by";
const UPDATE_APPLICATION_FORM_COMPLETED_BY =
  "update-application-form-completed-by";
const DELETE_APPLICATION_FORM_COMPLETED_BY =
  "delete-application-form-completed-by";
const VIEW_ANY_APPLICATION_FORM_EDUCATIONAL_PLACEMENTS =
  "view-any-application-form-educational-placements";
const VIEW_APPLICATION_FORM_EDUCATIONAL_PLACEMENT =
  "view-application-form-educational-placement";
const CREATE_APPLICATION_FORM_EDUCATIONAL_PLACEMENT =
  "create-application-form-educational-placement";
const UPDATE_APPLICATION_FORM_EDUCATIONAL_PLACEMENT =
  "update-application-form-educational-placement";
const DELETE_APPLICATION_FORM_EDUCATIONAL_PLACEMENT =
  "delete-application-form-educational-placement";
const VIEW_ANY_APPLICATION_FORM_GUIDES = "view-any-application-form-guides";
const VIEW_APPLICATION_FORM_GUIDE = "view-application-form-guide";
const CREATE_APPLICATION_FORM_GUIDE = "create-application-form-guide";
const UPDATE_APPLICATION_FORM_GUIDE = "update-application-form-guide";
const DELETE_APPLICATION_FORM_GUIDE = "delete-application-form-guide";
const VIEW_ANY_APPLICATION_FORM_HAND_PREFERENCE_ABILITIES =
  "view-any-application-form-hand-preference-abilities";
const VIEW_APPLICATION_FORM_HAND_PREFERENCE_ABILITY =
  "view-application-form-hand-preference-ability";
const CREATE_APPLICATION_FORM_HAND_PREFERENCE_ABILITY =
  "create-application-form-hand-preference-ability";
const UPDATE_APPLICATION_FORM_HAND_PREFERENCE_ABILITY =
  "update-application-form-hand-preference-ability";
const DELETE_APPLICATION_FORM_HAND_PREFERENCE_ABILITY =
  "delete-application-form-hand-preference-ability";
const VIEW_ANY_APPLICATION_FORM_HAND_PREFERENCE_HANDS =
  "view-any-application-form-hand-preference-hands";
const VIEW_APPLICATION_FORM_HAND_PREFERENCE_HAND =
  "view-application-form-hand-preference-hand";
const CREATE_APPLICATION_FORM_HAND_PREFERENCE_HAND =
  "create-application-form-hand-preference-hand";
const UPDATE_APPLICATION_FORM_HAND_PREFERENCE_HAND =
  "update-application-form-hand-preference-hand";
const DELETE_APPLICATION_FORM_HAND_PREFERENCE_HAND =
  "delete-application-form-hand-preference-hand";
const VIEW_ANY_APPLICATION_FORM_HEALTH_EARS =
  "view-any-application-form-health-ears";
const VIEW_APPLICATION_FORM_HEALTH_EAR = "view-application-form-health-ear";
const CREATE_APPLICATION_FORM_HEALTH_EAR = "create-application-form-health-ear";
const UPDATE_APPLICATION_FORM_HEALTH_EAR = "update-application-form-health-ear";
const DELETE_APPLICATION_FORM_HEALTH_EAR = "delete-application-form-health-ear";
const VIEW_ANY_APPLICATION_FORM_HEALTH_EYE_DISEASES =
  "view-any-application-form-health-eye-diseases";
const VIEW_APPLICATION_FORM_HEALTH_EYE_DISEASE =
  "view-application-form-health-eye-disease";
const CREATE_APPLICATION_FORM_HEALTH_EYE_DISEASE =
  "create-application-form-health-eye-disease";
const UPDATE_APPLICATION_FORM_HEALTH_EYE_DISEASE =
  "update-application-form-health-eye-disease";
const DELETE_APPLICATION_FORM_HEALTH_EYE_DISEASE =
  "delete-application-form-health-eye-disease";
const VIEW_ANY_APPLICATION_FORM_HEALTH_FOOD_ALLERGIES =
  "view-any-application-form-health-food-allergies";
const VIEW_APPLICATION_FORM_HEALTH_FOOD_ALLERGY =
  "view-application-form-health-food-allergy";
const CREATE_APPLICATION_FORM_HEALTH_FOOD_ALLERGY =
  "create-application-form-health-food-allergy";
const UPDATE_APPLICATION_FORM_HEALTH_FOOD_ALLERGY =
  "update-application-form-health-food-allergy";
const DELETE_APPLICATION_FORM_HEALTH_FOOD_ALLERGY =
  "delete-application-form-health-food-allergy";
const VIEW_ANY_APPLICATION_FORM_HEALTH_PRODUCTS =
  "view-any-application-form-health-products";
const VIEW_APPLICATION_FORM_HEALTH_PRODUCT =
  "view-application-form-health-product";
const CREATE_APPLICATION_FORM_HEALTH_PRODUCT =
  "create-application-form-health-product";
const UPDATE_APPLICATION_FORM_HEALTH_PRODUCT =
  "update-application-form-health-product";
const DELETE_APPLICATION_FORM_HEALTH_PRODUCT =
  "delete-application-form-health-product";
const VIEW_ANY_APPLICATION_FORM_HEALTH_SPECIALISTS =
  "view-any-application-form-health-specialists";
const VIEW_APPLICATION_FORM_HEALTH_SPECIALIST =
  "view-application-form-health-specialist";
const CREATE_APPLICATION_FORM_HEALTH_SPECIALIST =
  "create-application-form-health-specialist";
const UPDATE_APPLICATION_FORM_HEALTH_SPECIALIST =
  "update-application-form-health-specialist";
const DELETE_APPLICATION_FORM_HEALTH_SPECIALIST =
  "delete-application-form-health-specialist";
const VIEW_ANY_APPLICATION_FORM_HEALTH_TIME_PERIODS =
  "view-any-application-form-health-time-periods";
const VIEW_APPLICATION_FORM_HEALTH_TIME_PERIOD =
  "view-application-form-health-time-period";
const CREATE_APPLICATION_FORM_HEALTH_TIME_PERIOD =
  "create-application-form-health-time-period";
const UPDATE_APPLICATION_FORM_HEALTH_TIME_PERIOD =
  "update-application-form-health-time-period";
const DELETE_APPLICATION_FORM_HEALTH_TIME_PERIOD =
  "delete-application-form-health-time-period";
const VIEW_ANY_APPLICATION_FORM_INFO_TYPES =
  "view-any-application-form-info-types";
const VIEW_APPLICATION_FORM_INFO_TYPE = "view-application-form-info-type";
const CREATE_APPLICATION_FORM_INFO_TYPE = "create-application-form-info-type";
const UPDATE_APPLICATION_FORM_INFO_TYPE = "update-application-form-info-type";
const DELETE_APPLICATION_FORM_INFO_TYPE = "delete-application-form-info-type";
const VIEW_ANY_APPLICATION_FORM_LANGUAGE_SKILLS =
  "view-any-application-form-language-skills";
const VIEW_APPLICATION_FORM_LANGUAGE_SKILL =
  "view-application-form-language-skill";
const CREATE_APPLICATION_FORM_LANGUAGE_SKILL =
  "create-application-form-language-skill";
const UPDATE_APPLICATION_FORM_LANGUAGE_SKILL =
  "update-application-form-language-skill";
const DELETE_APPLICATION_FORM_LANGUAGE_SKILL =
  "delete-application-form-language-skill";
const VIEW_ANY_APPLICATION_FORM_INCOMES = "view-any-application-form-incomes";
const VIEW_APPLICATION_FORM_INCOME = "view-application-form-income";
const CREATE_APPLICATION_FORM_INCOME = "create-application-form-income";
const UPDATE_APPLICATION_FORM_INCOME = "update-application-form-income";
const DELETE_APPLICATION_FORM_INCOME = "delete-application-form-income";
const VIEW_ANY_APPLICATION_FORMS = "view-any-application-forms";
const VIEW_APPLICATION_FORM = "view-application-form";
const CREATE_APPLICATION_FORM = "create-application-form";
const UPDATE_APPLICATION_FORM = "update-application-form";
const DELETE_APPLICATION_FORM = "delete-application-form";
const ASSIGN_APPLICATION_FORM = "assign-application-form";
const DETACH_APPLICATION_FORM = "detach-application-form";
const IMPORT_TO_NEW_CLIENT_APPLICATION_FORM =
  "import-to-new-client-application-form";
const IMPORT_TO_EXISTING_CLIENT_APPLICATION_FORM =
  "import-to-existing-client-application-form";
const IMPORT_TO_FAMILY_APPLICATION_FORM = "import-to-family-application-form";

const GENERATE_PDF_APPLICATION_FORM = "generate-pdf-application-form";
const TOGGLE_ARCHIVE_APPLICATION_FORM = "toggle-archive-application-form";
const TOGGLE_COMPLETE_APPLICATION_FORM = "toggle-complete-application-form";
const VIEW_CLIENT_APPLICATION_FORM = "view-client-application-form";
const VIEW_FAMILY_APPLICATION_FORM = "view-family-application-form";
const VIEW_ANY_APPLICATION_FORM_CHILD_INFOS =
  "view-any-application-form-child-infos";
const VIEW_APPLICATION_FORM_CHILD_INFO = "view-application-form-child-info";
const CREATE_APPLICATION_FORM_CHILD_INFO = "create-application-form-child-info";
const UPDATE_APPLICATION_FORM_CHILD_INFO = "update-application-form-child-info";
const DELETE_APPLICATION_FORM_CHILD_INFO = "delete-application-form-child-info";
const VIEW_FAMILY_APPLICATION_FORM_CHILD_INFO =
  "view-family-application-form-child-info";
const VIEW_ANY_APPLICATION_FORM_HAND_PREFERENCE_OTHERS =
  "view-any-application-form-hand-preference-others";
const VIEW_APPLICATION_FORM_HAND_PREFERENCE_OTHER =
  "view-application-form-hand-preference-other";
const CREATE_APPLICATION_FORM_HAND_PREFERENCE_OTHER =
  "create-application-form-hand-preference-other";
const UPDATE_APPLICATION_FORM_HAND_PREFERENCE_OTHER =
  "update-application-form-hand-preference-other";
const DELETE_APPLICATION_FORM_HAND_PREFERENCE_OTHER =
  "delete-application-form-hand-preference-other";
const VIEW_FAMILY_APPLICATION_FORM_HAND_PREFERENCE_OTHER =
  "view-family-application-form-hand-preference-other";
const VIEW_ANY_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITIES =
  "view-any-application-form-health-physical-activities";
const VIEW_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY =
  "view-application-form-health-physical-activity";
const CREATE_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY =
  "create-application-form-health-physical-activity";
const UPDATE_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY =
  "update-application-form-health-physical-activity";
const DELETE_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY =
  "delete-application-form-health-physical-activity";
const VIEW_FAMILY_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY =
  "view-family-application-form-health-physical-activity";
const VIEW_ANY_APPLICATION_FORM_MEDICAL_HISTORY_TESTINGS =
  "view-any-application-form-medical-history-testings";
const VIEW_APPLICATION_FORM_MEDICAL_HISTORY_TESTING =
  "view-application-form-medical-history-testing";
const CREATE_APPLICATION_FORM_MEDICAL_HISTORY_TESTING =
  "create-application-form-medical-history-testing";
const UPDATE_APPLICATION_FORM_MEDICAL_HISTORY_TESTING =
  "update-application-form-medical-history-testing";
const DELETE_APPLICATION_FORM_MEDICAL_HISTORY_TESTING =
  "delete-application-form-medical-history-testing";
const VIEW_FAMILY_APPLICATION_FORM_MEDICAL_HISTORY_TESTING =
  "view-family-application-form-medical-history-testing";
const VIEW_ANY_APPLICATION_FORM_FEES = "view-any-application-form-fees";
const VIEW_APPLICATION_FORM_FEE = "view-application-form-fee";
const CREATE_APPLICATION_FORM_FEE = "create-application-form-fee";
const UPDATE_APPLICATION_FORM_FEE = "update-application-form-fee";
const DELETE_APPLICATION_FORM_FEE = "delete-application-form-fee";
const VIEW_ANY_APPLICATION_FORM_PARTS = "view-any-application-form-parts";
const VIEW_APPLICATION_FORM_PART = "view-application-form-part";
const CREATE_APPLICATION_FORM_PART = "create-application-form-part";
const UPDATE_APPLICATION_FORM_PART = "update-application-form-part";
const DELETE_APPLICATION_FORM_PART = "delete-application-form-part";
const SORT_APPLICATION_FORM_PART = "sort-application-form-part";
const VIEW_ANY_TIME_ZONES = "view-any-time-zones";
const VIEW_TIME_ZONE = "view-time-zone";
const CREATE_TIME_ZONE = "create-time-zone";
const UPDATE_TIME_ZONE = "update-time-zone";
const DELETE_TIME_ZONE = "delete-time-zone";
const VIEW_ANY_EMAIL_LAYOUTS = "view-any-email-layouts";
const VIEW_EMAIL_LAYOUT = "view-email-layout";
const CREATE_EMAIL_LAYOUT = "create-email-layout";
const UPDATE_EMAIL_LAYOUT = "update-email-layout";
const DELETE_EMAIL_LAYOUT = "delete-email-layout";
const VIEW_ANY_EMAIL_NOTIFICATIONS = "view-any-email-notifications";
const VIEW_EMAIL_NOTIFICATION = "view-email-notification";
const CREATE_EMAIL_NOTIFICATION = "create-email-notification";
const UPDATE_EMAIL_NOTIFICATION = "update-email-notification";
const DELETE_EMAIL_NOTIFICATION = "delete-email-notification";
const VIEW_ANY_MONTHS = "view-any-months";
const VIEW_MONTH = "view-month";
const CREATE_MONTH = "create-month";
const UPDATE_MONTH = "update-month";
const DELETE_MONTH = "delete-month";
const VIEW_ANY_DAYS = "view-any-days";
const VIEW_DAY = "view-day";
const CREATE_DAY = "create-day";
const UPDATE_DAY = "update-day";
const DELETE_DAY = "delete-day";
const VIEW_ANY_CRON_EMAIL_LAYOUTS = "view-any-cron-email-layouts";
const VIEW_CRON_EMAIL_LAYOUT = "view-cron-email-layout";
const CREATE_CRON_EMAIL_LAYOUT = "create-cron-email-layout";
const UPDATE_CRON_EMAIL_LAYOUT = "update-cron-email-layout";
const DELETE_CRON_EMAIL_LAYOUT = "delete-cron-email-layout";
const VIEW_ANY_PERMISSIONS = "view-any-permissions";
const VIEW_PERMISSION = "view-permission";
const CREATE_PERMISSION = "create-permission";
const UPDATE_PERMISSION = "update-permission";
const DELETE_PERMISSION = "delete-permission";
const VIEW_ANY_COURSE_INTRO_ACTIVITIES = "view-any-course-intro-activities";
const VIEW_COURSE_INTRO_ACTIVITY = "view-course-intro-activity";
const CREATE_COURSE_INTRO_ACTIVITY = "create-course-intro-activity";
const UPDATE_COURSE_INTRO_ACTIVITY = "update-course-intro-activity";
const DELETE_COURSE_INTRO_ACTIVITY = "delete-course-intro-activity";
const VIEW_OWN_COURSE_INTRO_ACTIVITY = "view-own-course-intro-activity";
const VIEW_ANY_FAMILY_PAGE_SETTINGS = "view-any-family-page-settings";
const VIEW_FAMILY_PAGE_SETTINGS = "view-family-page-settings";
const CREATE_FAMILY_PAGE_SETTINGS = "create-family-page-settings";
const UPDATE_FAMILY_PAGE_SETTINGS = "update-family-page-settings";
const DELETE_FAMILY_PAGE_SETTINGS = "delete-family-page-settings";
const VIEW_ANY_CLIENT_PAGE_SETTINGS = "view-any-client-page-settings";
const VIEW_CLIENT_PAGE_SETTINGS = "view-client-page-settings";
const CREATE_CLIENT_PAGE_SETTINGS = "create-client-page-settings";
const UPDATE_CLIENT_PAGE_SETTINGS = "update-client-page-settings";
const DELETE_CLIENT_PAGE_SETTINGS = "delete-client-page-settings";
const VIEW_ANY_LIMBO_STEP_STATUSES = "view-any-limbo-step-statuses";
const VIEW_LIMBO_STEP_STATUS = "view-limbo-step-status";
const CREATE_LIMBO_STEP_STATUS = "create-limbo-step-status";
const UPDATE_LIMBO_STEP_STATUS = "update-limbo-step-status";
const DELETE_LIMBO_STEP_STATUS = "delete-limbo-step-status";
const VIEW_ANY_PAPER_APPLICATION_FORMS = "view-any-paper-application-forms";
const VIEW_PAPER_APPLICATION_FORM = "view-paper-application-form";
const CREATE_PAPER_APPLICATION_FORM = "create-paper-application-form";
const UPDATE_PAPER_APPLICATION_FORM = "update-paper-application-form";
const DELETE_PAPER_APPLICATION_FORM = "delete-paper-application-form";
const VIEW_ANY_APPLICATION_FORM_COUNTRY_INCOME_FEES =
  "view-any-application-form-country-income-fees";
const VIEW_APPLICATION_FORM_COUNTRY_INCOME_FEE =
  "view-application-form-country-income-fee";
const CREATE_APPLICATION_FORM_COUNTRY_INCOME_FEE =
  "create-application-form-country-income-fee";
const UPDATE_APPLICATION_FORM_COUNTRY_INCOME_FEE =
  "update-application-form-country-income-fee";
const DELETE_APPLICATION_FORM_COUNTRY_INCOME_FEE =
  "delete-application-form-country-income-fee";
const SORT_APPLICATION_FORM_COUNTRY_INCOME_FEE =
  "sort-application-form-country-income-fee";
const VIEW_ANY_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNTS =
  "view-any-application-form-country-income-discounts";
const VIEW_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT =
  "view-application-form-country-income-discount";
const CREATE_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT =
  "create-application-form-country-income-discount";
const UPDATE_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT =
  "update-application-form-country-income-discount";
const DELETE_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT =
  "delete-application-form-country-income-discount";
const SORT_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT =
  "sort-application-form-country-income-discount";
const VIEW_ANY_NOTES = "view-any-notes";
const VIEW_NOTE = "view-note";
const CREATE_NOTE = "create-note";
const UPDATE_NOTE = "update-note";
const DELETE_NOTE = "delete-note";
const VIEW_ANY_FAMILY_LIMBO_STEPS = "view-any-family-limbo-steps";
const VIEW_FAMILY_LIMBO_STEP = "view-family-limbo-step";
const CREATE_FAMILY_LIMBO_STEP = "create-family-limbo-step";
const UPDATE_FAMILY_LIMBO_STEP = "update-family-limbo-step";
const DELETE_FAMILY_LIMBO_STEP = "delete-family-limbo-step";
const VIEW_ANY_EVALUATION_VIDEO_COMMENTS = "view-any-evaluation-video-comments";
const VIEW_EVALUATION_VIDEO_COMMENT = "view-evaluation-video-comment";
const CREATE_EVALUATION_VIDEO_COMMENT = "create-evaluation-video-comment";
const UPDATE_EVALUATION_VIDEO_COMMENT = "update-evaluation-video-comment";
const DELETE_EVALUATION_VIDEO_COMMENT = "delete-evaluation-video-comment";

const VIEW_ANY_ANNOUNCEMENT_TYPES = "view-any-announcement-types";
const VIEW_ANNOUNCEMENT_TYPE = "view-announcement-type";
const CREATE_ANNOUNCEMENT_TYPE = "create-announcement-type";
const UPDATE_ANNOUNCEMENT_TYPE = "update-announcement-type";
const DELETE_ANNOUNCEMENT_TYPE = "delete-announcement-type";

const VIEW_ANY_ANNOUNCEMENT_MESSAGE_TYPES =
  "view-any-announcement-message-types";
const VIEW_ANNOUNCEMENT_MESSAGE_TYPE = "view-announcement-message-type";
const CREATE_ANNOUNCEMENT_MESSAGE_TYPE = "create-announcement-message-type";
const UPDATE_ANNOUNCEMENT_MESSAGE_TYPE = "update-announcement-message-type";
const DELETE_ANNOUNCEMENT_MESSAGE_TYPE = "delete-announcement-message-type";

const VIEW_ANY_ANNOUNCEMENTS = "view-any-announcements";
const VIEW_ANNOUNCEMENT = "view-announcement";
const CREATE_ANNOUNCEMENT = "create-announcement";
const UPDATE_ANNOUNCEMENT = "update-announcement";
const DELETE_ANNOUNCEMENT = "delete-announcement";
const SORT_ANNOUNCEMENT = "sort-announcement";
const TOGGLE_ANNOUNCEMENT = "toggle-announcement";

const VIEW_ANY_THEMES = "view-any-themes";
const VIEW_THEME = "view-theme";
const CREATE_THEME = "create-theme";
const UPDATE_THEME = "update-theme";
const DELETE_THEME = "delete-theme";

const VIEW_ANY_SCHEDULE_TYPES = "view-any-schedule-types";
const VIEW_SCHEDULE_TYPE = "view-schedule-type";
const CREATE_SCHEDULE_TYPE = "create-schedule-type";
const UPDATE_SCHEDULE_TYPE = "update-schedule-type";
const DELETE_SCHEDULE_TYPE = "delete-schedule-type";

const VIEW_ANY_UPCOMING_EVALUATION_TIME_ZONES =
  "view-any-upcoming-evaluation-time-zones";
const VIEW_UPCOMING_EVALUATION_TIME_ZONE = "view-upcoming-evaluation-time-zone";
const CREATE_UPCOMING_EVALUATION_TIME_ZONE =
  "create-upcoming-evaluation-time-zone";
const UPDATE_UPCOMING_EVALUATION_TIME_ZONE =
  "update-upcoming-evaluation-time-zone";
const DELETE_UPCOMING_EVALUATION_TIME_ZONE =
  "delete-upcoming-evaluation-time-zone";

const VIEW_ANY_HOURS = "view-any-hours";
const VIEW_HOUR = "view-hour";
const CREATE_HOUR = "create-hour";
const UPDATE_HOUR = "update-hour";
const DELETE_HOUR = "delete-hour";

const VIEW_ANY_MINUTES = "view-any-minutes";
const VIEW_MINUTE = "view-minute";
const CREATE_MINUTE = "create-minute";
const UPDATE_MINUTE = "update-minute";
const DELETE_MINUTE = "delete-minute";

const VIEW_ANY_TIME_PERIODS = "view-any-time-periods";
const VIEW_TIME_PERIOD = "view-time-period";
const CREATE_TIME_PERIOD = "create-time-period";
const UPDATE_TIME_PERIOD = "update-time-period";
const DELETE_TIME_PERIOD = "delete-time-period";

export {
  VIEW_ANY_ANNOUNCEMENT_TYPES,
  VIEW_ANNOUNCEMENT_TYPE,
  CREATE_ANNOUNCEMENT_TYPE,
  UPDATE_ANNOUNCEMENT_TYPE,
  DELETE_ANNOUNCEMENT_TYPE,
  VIEW_ANY_ANNOUNCEMENT_MESSAGE_TYPES,
  VIEW_ANNOUNCEMENT_MESSAGE_TYPE,
  CREATE_ANNOUNCEMENT_MESSAGE_TYPE,
  UPDATE_ANNOUNCEMENT_MESSAGE_TYPE,
  DELETE_ANNOUNCEMENT_MESSAGE_TYPE,
  VIEW_ANY_ANNOUNCEMENTS,
  VIEW_ANNOUNCEMENT,
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  DELETE_ANNOUNCEMENT,
  SORT_ANNOUNCEMENT,
  TOGGLE_ANNOUNCEMENT,
  VIEW_ANY_PERMISSION_CATEGORIES,
  VIEW_PERMISSION_CATEGORY,
  CREATE_PERMISSION_CATEGORY,
  UPDATE_PERMISSION_CATEGORY,
  DELETE_PERMISSION_CATEGORY,
  VIEW_ANY_USERS,
  VIEW_USER,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  CHANGE_PASSWORD_USER,
  STRIPE_CREATE_CUSTOMER_USER,
  STRIPE_VIEW_CUSTOMER_USER,
  STRIPE_UPDATE_CUSTOMER_USER,
  STRIPE_VIEW_CARDS_USER,
  STRIPE_CREATE_CARD_USER,
  STRIPE_UPDATE_CARD_USER,
  STRIPE_DELETE_CARD_USER,
  STRIPE_CREATE_PAYMENT_INTENT_USER,
  STRIPE_VIEW_INVOICES_USER,
  STRIPE_VIEW_PAYMENTS_USER,
  VIEW_ANY_GENDERS,
  VIEW_GENDER,
  CREATE_GENDER,
  UPDATE_GENDER,
  DELETE_GENDER,
  VIEW_ANY_STAFF,
  VIEW_STAFF,
  CREATE_STAFF,
  UPDATE_STAFF,
  DELETE_STAFF,
  VIEW_REPORT_STAFF,
  VIEW_ANY_CHAPTERS,
  VIEW_CHAPTER,
  CREATE_CHAPTER,
  UPDATE_CHAPTER,
  DELETE_CHAPTER,
  VIEW_REPORT_CHAPTER,
  VIEW_ANY_FAMILY_STATUSES,
  VIEW_FAMILY_STATUS,
  CREATE_FAMILY_STATUS,
  UPDATE_FAMILY_STATUS,
  DELETE_FAMILY_STATUS,
  VIEW_ANY_DROPPED_STATUSES,
  VIEW_DROPPED_STATUS,
  CREATE_DROPPED_STATUS,
  UPDATE_DROPPED_STATUS,
  DELETE_DROPPED_STATUS,
  VIEW_ANY_LIMBO_ARCHIVE_REASONS,
  VIEW_LIMBO_ARCHIVE_REASON,
  CREATE_LIMBO_ARCHIVE_REASON,
  UPDATE_LIMBO_ARCHIVE_REASON,
  DELETE_LIMBO_ARCHIVE_REASON,
  VIEW_ANY_EDUCATION,
  VIEW_EDUCATION,
  CREATE_EDUCATION,
  UPDATE_EDUCATION,
  DELETE_EDUCATION,
  VIEW_ANY_LANGUAGES,
  VIEW_LANGUAGE,
  CREATE_LANGUAGE,
  UPDATE_LANGUAGE,
  DELETE_LANGUAGE,
  VIEW_ANY_CONTACTS,
  VIEW_CONTACT,
  CREATE_CONTACT,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  VIEW_ANY_HEARD_FROMS,
  VIEW_HEARD_FROM,
  CREATE_HEARD_FROM,
  UPDATE_HEARD_FROM,
  DELETE_HEARD_FROM,
  VIEW_ANY_PARENT_TYPES,
  VIEW_PARENT_TYPE,
  CREATE_PARENT_TYPE,
  UPDATE_PARENT_TYPE,
  DELETE_PARENT_TYPE,
  VIEW_ANY_FAMILIES,
  VIEW_FAMILY,
  CREATE_FAMILY,
  UPDATE_FAMILY,
  DELETE_FAMILY,
  TOGGLE_FAMILY,
  EXPORT_FAMILY,
  ARCHIVE_FAMILY,
  APPROVE_FAMILY,
  TOGGLE_CONTACTED_FAMILY,
  SEND_IN_PERSON_PAPERWORK_REMINDER_FAMILY,
  SEND_SKYPE_PAPERWORK_REMINDER_FAMILY,
  SEND_PROGRAM_NOTIFICATION_FAMILY,
  TOGGLE_COURSES_FAMILY,
  VIEW_ANY_ROLES,
  VIEW_ROLE,
  CREATE_ROLE,
  UPDATE_ROLE,
  DELETE_ROLE,
  ATTACH_PERMISSION_ROLE,
  VIEW_ANY_STAFF_TEAMS,
  VIEW_STAFF_TEAM,
  CREATE_STAFF_TEAM,
  UPDATE_STAFF_TEAM,
  DELETE_STAFF_TEAM,
  SORT_STAFF_TEAM,
  VIEW_FAMILY_STAFF_TEAM,
  VIEW_ANY_PROGRAM_TYPES,
  VIEW_PROGRAM_TYPE,
  CREATE_PROGRAM_TYPE,
  UPDATE_PROGRAM_TYPE,
  DELETE_PROGRAM_TYPE,
  VIEW_ANY_PROGRAM_CODES,
  VIEW_PROGRAM_CODE,
  CREATE_PROGRAM_CODE,
  UPDATE_PROGRAM_CODE,
  DELETE_PROGRAM_CODE,
  VIEW_ANY_CLIENT_LIVE_WITHS,
  VIEW_CLIENT_LIVE_WITH,
  CREATE_CLIENT_LIVE_WITH,
  UPDATE_CLIENT_LIVE_WITH,
  DELETE_CLIENT_LIVE_WITH,
  VIEW_ANY_CLIENT_STATUSES,
  VIEW_CLIENT_STATUS,
  CREATE_CLIENT_STATUS,
  UPDATE_CLIENT_STATUS,
  DELETE_CLIENT_STATUS,
  VIEW_ANY_CLIENTS,
  VIEW_CLIENT,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  EXPORT_CLIENT,
  SEND_EVALUATION_VIDEO_COMMENT_NOTIFICATION_CLIENT,
  CONTACT_COACH_CLIENT,
  VIEW_OWN_CLIENT,
  VIEW_ANY_EMAIL_LOGS,
  VIEW_EMAIL_LOG,
  CREATE_EMAIL_LOG,
  UPDATE_EMAIL_LOG,
  DELETE_EMAIL_LOG,
  VIEW_ANY_EVALUATION_VIDEO_TAGS,
  VIEW_EVALUATION_VIDEO_TAG,
  CREATE_EVALUATION_VIDEO_TAG,
  UPDATE_EVALUATION_VIDEO_TAG,
  DELETE_EVALUATION_VIDEO_TAG,
  VIEW_ANY_EVALUATION_VIDEOS,
  VIEW_EVALUATION_VIDEO,
  CREATE_EVALUATION_VIDEO,
  UPDATE_EVALUATION_VIDEO,
  DELETE_EVALUATION_VIDEO,
  TOGGLE_ALL_SEEN_EVALUATION_VIDEO,
  VIEW_CLIENT_EVALUATION_VIDEO,
  VIEW_ANY_ACTIVITY_CATEGORIES,
  VIEW_ACTIVITY_CATEGORY,
  CREATE_ACTIVITY_CATEGORY,
  UPDATE_ACTIVITY_CATEGORY,
  DELETE_ACTIVITY_CATEGORY,
  VIEW_ANY_ACTIVITIES,
  VIEW_ACTIVITY,
  CREATE_ACTIVITY,
  UPDATE_ACTIVITY,
  DELETE_ACTIVITY,
  VIEW_ANY_ACTIVITY_LINK_TYPES,
  VIEW_ACTIVITY_LINK_TYPE,
  CREATE_ACTIVITY_LINK_TYPE,
  UPDATE_ACTIVITY_LINK_TYPE,
  DELETE_ACTIVITY_LINK_TYPE,
  VIEW_ANY_ACTIVITY_LINKS,
  VIEW_ACTIVITY_LINK,
  CREATE_ACTIVITY_LINK,
  UPDATE_ACTIVITY_LINK,
  DELETE_ACTIVITY_LINK,
  VIEW_ANY_PROGRAMS,
  VIEW_PROGRAM,
  CREATE_PROGRAM,
  UPDATE_PROGRAM,
  DELETE_PROGRAM,
  COPY_PROGRAM,
  GENERATE_EVALUATOR_PDF_PROGRAM,
  GENERATE_FAMILY_PDF_PROGRAM,
  VIEW_ANY_PROGRAM_ACTIVITIES,
  VIEW_PROGRAM_ACTIVITY,
  CREATE_PROGRAM_ACTIVITY,
  UPDATE_PROGRAM_ACTIVITY,
  DELETE_PROGRAM_ACTIVITY,
  SORT_PROGRAM_ACTIVITY,
  VIEW_CLIENT_PROGRAM_ACTIVITY,
  VIEW_ANY_DOCUMENT_TYPES,
  VIEW_DOCUMENT_TYPE,
  CREATE_DOCUMENT_TYPE,
  UPDATE_DOCUMENT_TYPE,
  DELETE_DOCUMENT_TYPE,
  VIEW_ANY_DOCUMENTS,
  VIEW_DOCUMENT,
  CREATE_DOCUMENT,
  UPDATE_DOCUMENT,
  DELETE_DOCUMENT,
  VIEW_ANY_DEVELOPMENTAL_PROFILE_STATUSES,
  VIEW_DEVELOPMENTAL_PROFILE_STATUS,
  CREATE_DEVELOPMENTAL_PROFILE_STATUS,
  UPDATE_DEVELOPMENTAL_PROFILE_STATUS,
  DELETE_DEVELOPMENTAL_PROFILE_STATUS,
  VIEW_ANY_EVALUATION_TYPES,
  VIEW_EVALUATION_TYPE,
  CREATE_EVALUATION_TYPE,
  UPDATE_EVALUATION_TYPE,
  DELETE_EVALUATION_TYPE,
  VIEW_ANY_DEVELOPMENTAL_PROFILE_CATEGORIES,
  VIEW_DEVELOPMENTAL_PROFILE_CATEGORY,
  CREATE_DEVELOPMENTAL_PROFILE_CATEGORY,
  UPDATE_DEVELOPMENTAL_PROFILE_CATEGORY,
  DELETE_DEVELOPMENTAL_PROFILE_CATEGORY,
  VIEW_ANY_DEVELOPMENTAL_PROFILE_DESCRIPTIONS,
  VIEW_DEVELOPMENTAL_PROFILE_DESCRIPTION,
  CREATE_DEVELOPMENTAL_PROFILE_DESCRIPTION,
  UPDATE_DEVELOPMENTAL_PROFILE_DESCRIPTION,
  DELETE_DEVELOPMENTAL_PROFILE_DESCRIPTION,
  VIEW_ANY_DEVELOPMENTAL_PROFILE_ITEMS,
  VIEW_DEVELOPMENTAL_PROFILE_ITEM,
  CREATE_DEVELOPMENTAL_PROFILE_ITEM,
  UPDATE_DEVELOPMENTAL_PROFILE_ITEM,
  DELETE_DEVELOPMENTAL_PROFILE_ITEM,
  SORT_DEVELOPMENTAL_PROFILE_ITEM,
  VIEW_ANY_COMMON_FORMS,
  VIEW_COMMON_FORM,
  CREATE_COMMON_FORM,
  UPDATE_COMMON_FORM,
  DELETE_COMMON_FORM,
  SORT_COMMON_FORM,
  VIEW_ANY_EVALUATIONS,
  VIEW_EVALUATION,
  CREATE_EVALUATION,
  UPDATE_EVALUATION,
  DELETE_EVALUATION,
  GENERATE_PDF_EVALUATION,
  VIEW_CLIENT_EVALUATION,
  VIEW_ANY_RE_EVALUATION_TIME_PERIODS,
  VIEW_RE_EVALUATION_TIME_PERIOD,
  CREATE_RE_EVALUATION_TIME_PERIOD,
  UPDATE_RE_EVALUATION_TIME_PERIOD,
  DELETE_RE_EVALUATION_TIME_PERIOD,
  VIEW_ANY_RE_EVALUATION_STATUSES,
  VIEW_RE_EVALUATION_STATUS,
  CREATE_RE_EVALUATION_STATUS,
  UPDATE_RE_EVALUATION_STATUS,
  DELETE_RE_EVALUATION_STATUS,
  VIEW_ANY_RE_EVALUATION_ASSISTANTS,
  VIEW_RE_EVALUATION_ASSISTANT,
  CREATE_RE_EVALUATION_ASSISTANT,
  UPDATE_RE_EVALUATION_ASSISTANT,
  DELETE_RE_EVALUATION_ASSISTANT,
  VIEW_ANY_RE_EVALUATION_PROCESSING_ACTIVITY_TYPES,
  VIEW_RE_EVALUATION_PROCESSING_ACTIVITY_TYPE,
  CREATE_RE_EVALUATION_PROCESSING_ACTIVITY_TYPE,
  UPDATE_RE_EVALUATION_PROCESSING_ACTIVITY_TYPE,
  DELETE_RE_EVALUATION_PROCESSING_ACTIVITY_TYPE,
  VIEW_ANY_RE_EVALUATIONS,
  VIEW_RE_EVALUATION,
  CREATE_RE_EVALUATION,
  UPDATE_RE_EVALUATION,
  DELETE_RE_EVALUATION,
  GENERATE_PDF_RE_EVALUATION,
  TOGGLE_RE_EVALUATION,
  VIEW_CLIENT_RE_EVALUATION,
  VIEW_ANY_RE_EVALUATION_ACTIVITIES,
  VIEW_RE_EVALUATION_ACTIVITY,
  CREATE_RE_EVALUATION_ACTIVITY,
  UPDATE_RE_EVALUATION_ACTIVITY,
  DELETE_RE_EVALUATION_ACTIVITY,
  VIEW_CLIENT_RE_EVALUATION_ACTIVITY,
  VIEW_ANY_RE_EVALUATION_CLASSES,
  VIEW_RE_EVALUATION_CLASS,
  CREATE_RE_EVALUATION_CLASS,
  UPDATE_RE_EVALUATION_CLASS,
  DELETE_RE_EVALUATION_CLASS,
  VIEW_CLIENT_RE_EVALUATION_CLASS,
  VIEW_ANY_RE_EVALUATION_DOCTORS,
  VIEW_RE_EVALUATION_DOCTOR,
  CREATE_RE_EVALUATION_DOCTOR,
  UPDATE_RE_EVALUATION_DOCTOR,
  DELETE_RE_EVALUATION_DOCTOR,
  VIEW_CLIENT_RE_EVALUATION_DOCTOR,
  VIEW_ANY_RE_EVALUATION_MEDICATIONS,
  VIEW_RE_EVALUATION_MEDICATION,
  CREATE_RE_EVALUATION_MEDICATION,
  UPDATE_RE_EVALUATION_MEDICATION,
  DELETE_RE_EVALUATION_MEDICATION,
  VIEW_CLIENT_RE_EVALUATION_MEDICATION,
  VIEW_ANY_COURSE_STATUSES,
  VIEW_COURSE_STATUS,
  CREATE_COURSE_STATUS,
  UPDATE_COURSE_STATUS,
  DELETE_COURSE_STATUS,
  VIEW_ANY_COURSES,
  VIEW_COURSE,
  CREATE_COURSE,
  UPDATE_COURSE,
  DELETE_COURSE,
  SORT_COURSE,
  VIEW_OWN_COURSE,
  VIEW_ANY_OWN_COURSE,
  VIEW_ANY_COURSE_CATEGORIES,
  VIEW_COURSE_CATEGORY,
  CREATE_COURSE_CATEGORY,
  UPDATE_COURSE_CATEGORY,
  DELETE_COURSE_CATEGORY,
  SORT_COURSE_CATEGORY,
  VIEW_OWN_COURSE_CATEGORY,
  VIEW_ANY_COURSE_ACTIVITIES,
  VIEW_COURSE_ACTIVITY,
  CREATE_COURSE_ACTIVITY,
  UPDATE_COURSE_ACTIVITY,
  DELETE_COURSE_ACTIVITY,
  SORT_COURSE_ACTIVITY,
  VIEW_OWN_COURSE_ACTIVITY,
  VIEW_ANY_COURSE_CATEGORY_CONTENTS,
  VIEW_COURSE_CATEGORY_CONTENT,
  CREATE_COURSE_CATEGORY_CONTENT,
  UPDATE_COURSE_CATEGORY_CONTENT,
  DELETE_COURSE_CATEGORY_CONTENT,
  VIEW_ANY_LIMBO_STEPS,
  VIEW_LIMBO_STEP,
  CREATE_LIMBO_STEP,
  UPDATE_LIMBO_STEP,
  DELETE_LIMBO_STEP,
  CHANGE_STATUS_LIMBO_STEP,
  VIEW_ANY_LIMBO_STEP_ARTICLES,
  VIEW_LIMBO_STEP_ARTICLE,
  CREATE_LIMBO_STEP_ARTICLE,
  UPDATE_LIMBO_STEP_ARTICLE,
  DELETE_LIMBO_STEP_ARTICLE,
  VIEW_ANY_APPLICATION_FORM_CATEGORIES,
  VIEW_APPLICATION_FORM_CATEGORY,
  CREATE_APPLICATION_FORM_CATEGORY,
  UPDATE_APPLICATION_FORM_CATEGORY,
  DELETE_APPLICATION_FORM_CATEGORY,
  VIEW_ANY_APPLICATION_FORM_TYPES,
  VIEW_APPLICATION_FORM_TYPE,
  CREATE_APPLICATION_FORM_TYPE,
  UPDATE_APPLICATION_FORM_TYPE,
  DELETE_APPLICATION_FORM_TYPE,
  VIEW_FAMILY_APPLICATION_FORM_TYPE,
  VIEW_ANY_APPLICATION_FORM_PAGES,
  VIEW_APPLICATION_FORM_PAGE,
  CREATE_APPLICATION_FORM_PAGE,
  UPDATE_APPLICATION_FORM_PAGE,
  DELETE_APPLICATION_FORM_PAGE,
  VIEW_ANY_APPLICATION_FORM_COUNTRIES,
  VIEW_APPLICATION_FORM_COUNTRY,
  CREATE_APPLICATION_FORM_COUNTRY,
  UPDATE_APPLICATION_FORM_COUNTRY,
  DELETE_APPLICATION_FORM_COUNTRY,
  SORT_APPLICATION_FORM_COUNTRY,
  VIEW_ANY_APPLICATION_FORM_DISCOUNTS,
  VIEW_APPLICATION_FORM_DISCOUNT,
  CREATE_APPLICATION_FORM_DISCOUNT,
  UPDATE_APPLICATION_FORM_DISCOUNT,
  DELETE_APPLICATION_FORM_DISCOUNT,
  VIEW_ANY_APPLICATION_FORM_PAGE_ASSIGNMENTS,
  VIEW_APPLICATION_FORM_PAGE_ASSIGNMENT,
  CREATE_APPLICATION_FORM_PAGE_ASSIGNMENT,
  UPDATE_APPLICATION_FORM_PAGE_ASSIGNMENT,
  DELETE_APPLICATION_FORM_PAGE_ASSIGNMENT,
  SORT_APPLICATION_FORM_PAGE_ASSIGNMENT,
  VIEW_FAMILY_APPLICATION_FORM_PAGE_ASSIGNMENT,
  VIEW_ANY_AUTH_LOGS,
  VIEW_AUTH_LOG,
  CREATE_AUTH_LOG,
  UPDATE_AUTH_LOG,
  DELETE_AUTH_LOG,
  VIEW_ANY_LOGS,
  VIEW_LOG,
  CREATE_LOG,
  UPDATE_LOG,
  DELETE_LOG,
  VIEW_ANY_TRACK_TYPES,
  VIEW_TRACK_TYPE,
  CREATE_TRACK_TYPE,
  UPDATE_TRACK_TYPE,
  DELETE_TRACK_TYPE,
  VIEW_ANY_TRACK_OPERATIONS,
  VIEW_TRACK_OPERATION,
  CREATE_TRACK_OPERATION,
  UPDATE_TRACK_OPERATION,
  DELETE_TRACK_OPERATION,
  VIEW_ANY_TRACKS,
  VIEW_TRACK,
  CREATE_TRACK,
  UPDATE_TRACK,
  DELETE_TRACK,
  VIEW_ANY_REPORT_STATUSES,
  VIEW_REPORT_STATUS,
  CREATE_REPORT_STATUS,
  UPDATE_REPORT_STATUS,
  DELETE_REPORT_STATUS,
  VIEW_ANY_REPORT_TYPES,
  VIEW_REPORT_TYPE,
  CREATE_REPORT_TYPE,
  UPDATE_REPORT_TYPE,
  DELETE_REPORT_TYPE,
  VIEW_ANY_REPORTS,
  VIEW_REPORT,
  CREATE_REPORT,
  UPDATE_REPORT,
  DELETE_REPORT,
  VIEW_ANY_APPLICATION_FORM_STATUSES,
  VIEW_APPLICATION_FORM_STATUS,
  CREATE_APPLICATION_FORM_STATUS,
  UPDATE_APPLICATION_FORM_STATUS,
  DELETE_APPLICATION_FORM_STATUS,
  VIEW_ANY_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDERS,
  VIEW_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDER,
  CREATE_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDER,
  UPDATE_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDER,
  DELETE_APPLICATION_FORM_PHYSICAL_MOTOR_SKILL_DISORDER,
  VIEW_ANY_APPLICATION_FORM_MATH_SKILLS,
  VIEW_APPLICATION_FORM_MATH_SKILL,
  CREATE_APPLICATION_FORM_MATH_SKILL,
  UPDATE_APPLICATION_FORM_MATH_SKILL,
  DELETE_APPLICATION_FORM_MATH_SKILL,
  VIEW_ANY_APPLICATION_FORM_BEHAVIOUR_DISORDERS,
  VIEW_APPLICATION_FORM_BEHAVIOUR_DISORDER,
  CREATE_APPLICATION_FORM_BEHAVIOUR_DISORDER,
  UPDATE_APPLICATION_FORM_BEHAVIOUR_DISORDER,
  DELETE_APPLICATION_FORM_BEHAVIOUR_DISORDER,
  VIEW_ANY_APPLICATION_FORM_CLIENT_LIVE_WITHS,
  VIEW_APPLICATION_FORM_CLIENT_LIVE_WITH,
  CREATE_APPLICATION_FORM_CLIENT_LIVE_WITH,
  UPDATE_APPLICATION_FORM_CLIENT_LIVE_WITH,
  DELETE_APPLICATION_FORM_CLIENT_LIVE_WITH,
  VIEW_ANY_APPLICATION_FORM_COGNITIVE_SKILLS,
  VIEW_APPLICATION_FORM_COGNITIVE_SKILL,
  CREATE_APPLICATION_FORM_COGNITIVE_SKILL,
  UPDATE_APPLICATION_FORM_COGNITIVE_SKILL,
  DELETE_APPLICATION_FORM_COGNITIVE_SKILL,
  VIEW_ANY_APPLICATION_FORM_COME_FROMS,
  VIEW_APPLICATION_FORM_COME_FROM,
  CREATE_APPLICATION_FORM_COME_FROM,
  UPDATE_APPLICATION_FORM_COME_FROM,
  DELETE_APPLICATION_FORM_COME_FROM,
  VIEW_ANY_APPLICATION_FORM_COMMON_ANSWERS,
  VIEW_APPLICATION_FORM_COMMON_ANSWER,
  CREATE_APPLICATION_FORM_COMMON_ANSWER,
  UPDATE_APPLICATION_FORM_COMMON_ANSWER,
  DELETE_APPLICATION_FORM_COMMON_ANSWER,
  VIEW_ANY_APPLICATION_FORM_COMPLETED_BIES,
  VIEW_APPLICATION_FORM_COMPLETED_BY,
  CREATE_APPLICATION_FORM_COMPLETED_BY,
  UPDATE_APPLICATION_FORM_COMPLETED_BY,
  DELETE_APPLICATION_FORM_COMPLETED_BY,
  VIEW_ANY_APPLICATION_FORM_EDUCATIONAL_PLACEMENTS,
  VIEW_APPLICATION_FORM_EDUCATIONAL_PLACEMENT,
  CREATE_APPLICATION_FORM_EDUCATIONAL_PLACEMENT,
  UPDATE_APPLICATION_FORM_EDUCATIONAL_PLACEMENT,
  DELETE_APPLICATION_FORM_EDUCATIONAL_PLACEMENT,
  VIEW_ANY_APPLICATION_FORM_GUIDES,
  VIEW_APPLICATION_FORM_GUIDE,
  CREATE_APPLICATION_FORM_GUIDE,
  UPDATE_APPLICATION_FORM_GUIDE,
  DELETE_APPLICATION_FORM_GUIDE,
  VIEW_ANY_APPLICATION_FORM_HAND_PREFERENCE_ABILITIES,
  VIEW_APPLICATION_FORM_HAND_PREFERENCE_ABILITY,
  CREATE_APPLICATION_FORM_HAND_PREFERENCE_ABILITY,
  UPDATE_APPLICATION_FORM_HAND_PREFERENCE_ABILITY,
  DELETE_APPLICATION_FORM_HAND_PREFERENCE_ABILITY,
  VIEW_ANY_APPLICATION_FORM_HAND_PREFERENCE_HANDS,
  VIEW_APPLICATION_FORM_HAND_PREFERENCE_HAND,
  CREATE_APPLICATION_FORM_HAND_PREFERENCE_HAND,
  UPDATE_APPLICATION_FORM_HAND_PREFERENCE_HAND,
  DELETE_APPLICATION_FORM_HAND_PREFERENCE_HAND,
  VIEW_ANY_APPLICATION_FORM_HEALTH_EARS,
  VIEW_APPLICATION_FORM_HEALTH_EAR,
  CREATE_APPLICATION_FORM_HEALTH_EAR,
  UPDATE_APPLICATION_FORM_HEALTH_EAR,
  DELETE_APPLICATION_FORM_HEALTH_EAR,
  VIEW_ANY_APPLICATION_FORM_HEALTH_EYE_DISEASES,
  VIEW_APPLICATION_FORM_HEALTH_EYE_DISEASE,
  CREATE_APPLICATION_FORM_HEALTH_EYE_DISEASE,
  UPDATE_APPLICATION_FORM_HEALTH_EYE_DISEASE,
  DELETE_APPLICATION_FORM_HEALTH_EYE_DISEASE,
  VIEW_ANY_APPLICATION_FORM_HEALTH_FOOD_ALLERGIES,
  VIEW_APPLICATION_FORM_HEALTH_FOOD_ALLERGY,
  CREATE_APPLICATION_FORM_HEALTH_FOOD_ALLERGY,
  UPDATE_APPLICATION_FORM_HEALTH_FOOD_ALLERGY,
  DELETE_APPLICATION_FORM_HEALTH_FOOD_ALLERGY,
  VIEW_ANY_APPLICATION_FORM_HEALTH_PRODUCTS,
  VIEW_APPLICATION_FORM_HEALTH_PRODUCT,
  CREATE_APPLICATION_FORM_HEALTH_PRODUCT,
  UPDATE_APPLICATION_FORM_HEALTH_PRODUCT,
  DELETE_APPLICATION_FORM_HEALTH_PRODUCT,
  VIEW_ANY_APPLICATION_FORM_HEALTH_SPECIALISTS,
  VIEW_APPLICATION_FORM_HEALTH_SPECIALIST,
  CREATE_APPLICATION_FORM_HEALTH_SPECIALIST,
  UPDATE_APPLICATION_FORM_HEALTH_SPECIALIST,
  DELETE_APPLICATION_FORM_HEALTH_SPECIALIST,
  VIEW_ANY_APPLICATION_FORM_HEALTH_TIME_PERIODS,
  VIEW_APPLICATION_FORM_HEALTH_TIME_PERIOD,
  CREATE_APPLICATION_FORM_HEALTH_TIME_PERIOD,
  UPDATE_APPLICATION_FORM_HEALTH_TIME_PERIOD,
  DELETE_APPLICATION_FORM_HEALTH_TIME_PERIOD,
  VIEW_ANY_APPLICATION_FORM_INFO_TYPES,
  VIEW_APPLICATION_FORM_INFO_TYPE,
  CREATE_APPLICATION_FORM_INFO_TYPE,
  UPDATE_APPLICATION_FORM_INFO_TYPE,
  DELETE_APPLICATION_FORM_INFO_TYPE,
  VIEW_ANY_APPLICATION_FORM_LANGUAGE_SKILLS,
  VIEW_APPLICATION_FORM_LANGUAGE_SKILL,
  CREATE_APPLICATION_FORM_LANGUAGE_SKILL,
  UPDATE_APPLICATION_FORM_LANGUAGE_SKILL,
  DELETE_APPLICATION_FORM_LANGUAGE_SKILL,
  VIEW_ANY_APPLICATION_FORM_INCOMES,
  VIEW_APPLICATION_FORM_INCOME,
  CREATE_APPLICATION_FORM_INCOME,
  UPDATE_APPLICATION_FORM_INCOME,
  DELETE_APPLICATION_FORM_INCOME,
  VIEW_ANY_APPLICATION_FORMS,
  VIEW_APPLICATION_FORM,
  CREATE_APPLICATION_FORM,
  UPDATE_APPLICATION_FORM,
  DELETE_APPLICATION_FORM,
  ASSIGN_APPLICATION_FORM,
  DETACH_APPLICATION_FORM,
  IMPORT_TO_NEW_CLIENT_APPLICATION_FORM,
  IMPORT_TO_EXISTING_CLIENT_APPLICATION_FORM,
  IMPORT_TO_FAMILY_APPLICATION_FORM,
  GENERATE_PDF_APPLICATION_FORM,
  TOGGLE_ARCHIVE_APPLICATION_FORM,
  TOGGLE_COMPLETE_APPLICATION_FORM,
  VIEW_CLIENT_APPLICATION_FORM,
  VIEW_FAMILY_APPLICATION_FORM,
  VIEW_ANY_APPLICATION_FORM_CHILD_INFOS,
  VIEW_APPLICATION_FORM_CHILD_INFO,
  CREATE_APPLICATION_FORM_CHILD_INFO,
  UPDATE_APPLICATION_FORM_CHILD_INFO,
  DELETE_APPLICATION_FORM_CHILD_INFO,
  VIEW_FAMILY_APPLICATION_FORM_CHILD_INFO,
  VIEW_ANY_APPLICATION_FORM_HAND_PREFERENCE_OTHERS,
  VIEW_APPLICATION_FORM_HAND_PREFERENCE_OTHER,
  CREATE_APPLICATION_FORM_HAND_PREFERENCE_OTHER,
  UPDATE_APPLICATION_FORM_HAND_PREFERENCE_OTHER,
  DELETE_APPLICATION_FORM_HAND_PREFERENCE_OTHER,
  VIEW_FAMILY_APPLICATION_FORM_HAND_PREFERENCE_OTHER,
  VIEW_ANY_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITIES,
  VIEW_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY,
  CREATE_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY,
  UPDATE_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY,
  DELETE_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY,
  VIEW_FAMILY_APPLICATION_FORM_HEALTH_PHYSICAL_ACTIVITY,
  VIEW_ANY_APPLICATION_FORM_MEDICAL_HISTORY_TESTINGS,
  VIEW_APPLICATION_FORM_MEDICAL_HISTORY_TESTING,
  CREATE_APPLICATION_FORM_MEDICAL_HISTORY_TESTING,
  UPDATE_APPLICATION_FORM_MEDICAL_HISTORY_TESTING,
  DELETE_APPLICATION_FORM_MEDICAL_HISTORY_TESTING,
  VIEW_FAMILY_APPLICATION_FORM_MEDICAL_HISTORY_TESTING,
  VIEW_ANY_APPLICATION_FORM_FEES,
  VIEW_APPLICATION_FORM_FEE,
  CREATE_APPLICATION_FORM_FEE,
  UPDATE_APPLICATION_FORM_FEE,
  DELETE_APPLICATION_FORM_FEE,
  VIEW_ANY_APPLICATION_FORM_PARTS,
  VIEW_APPLICATION_FORM_PART,
  CREATE_APPLICATION_FORM_PART,
  UPDATE_APPLICATION_FORM_PART,
  DELETE_APPLICATION_FORM_PART,
  SORT_APPLICATION_FORM_PART,
  VIEW_ANY_TIME_ZONES,
  VIEW_TIME_ZONE,
  CREATE_TIME_ZONE,
  UPDATE_TIME_ZONE,
  DELETE_TIME_ZONE,
  VIEW_ANY_EMAIL_LAYOUTS,
  VIEW_EMAIL_LAYOUT,
  CREATE_EMAIL_LAYOUT,
  UPDATE_EMAIL_LAYOUT,
  DELETE_EMAIL_LAYOUT,
  VIEW_ANY_EMAIL_NOTIFICATIONS,
  VIEW_EMAIL_NOTIFICATION,
  CREATE_EMAIL_NOTIFICATION,
  UPDATE_EMAIL_NOTIFICATION,
  DELETE_EMAIL_NOTIFICATION,
  VIEW_ANY_MONTHS,
  VIEW_MONTH,
  CREATE_MONTH,
  UPDATE_MONTH,
  DELETE_MONTH,
  VIEW_ANY_DAYS,
  VIEW_DAY,
  CREATE_DAY,
  UPDATE_DAY,
  DELETE_DAY,
  VIEW_ANY_CRON_EMAIL_LAYOUTS,
  VIEW_CRON_EMAIL_LAYOUT,
  CREATE_CRON_EMAIL_LAYOUT,
  UPDATE_CRON_EMAIL_LAYOUT,
  DELETE_CRON_EMAIL_LAYOUT,
  VIEW_ANY_PERMISSIONS,
  VIEW_PERMISSION,
  CREATE_PERMISSION,
  UPDATE_PERMISSION,
  DELETE_PERMISSION,
  VIEW_ANY_COURSE_INTRO_ACTIVITIES,
  VIEW_COURSE_INTRO_ACTIVITY,
  CREATE_COURSE_INTRO_ACTIVITY,
  UPDATE_COURSE_INTRO_ACTIVITY,
  DELETE_COURSE_INTRO_ACTIVITY,
  VIEW_OWN_COURSE_INTRO_ACTIVITY,
  VIEW_ANY_FAMILY_PAGE_SETTINGS,
  VIEW_FAMILY_PAGE_SETTINGS,
  CREATE_FAMILY_PAGE_SETTINGS,
  UPDATE_FAMILY_PAGE_SETTINGS,
  DELETE_FAMILY_PAGE_SETTINGS,
  VIEW_ANY_CLIENT_PAGE_SETTINGS,
  VIEW_CLIENT_PAGE_SETTINGS,
  CREATE_CLIENT_PAGE_SETTINGS,
  UPDATE_CLIENT_PAGE_SETTINGS,
  DELETE_CLIENT_PAGE_SETTINGS,
  VIEW_ANY_LIMBO_STEP_STATUSES,
  VIEW_LIMBO_STEP_STATUS,
  CREATE_LIMBO_STEP_STATUS,
  UPDATE_LIMBO_STEP_STATUS,
  DELETE_LIMBO_STEP_STATUS,
  VIEW_ANY_PAPER_APPLICATION_FORMS,
  VIEW_PAPER_APPLICATION_FORM,
  CREATE_PAPER_APPLICATION_FORM,
  UPDATE_PAPER_APPLICATION_FORM,
  DELETE_PAPER_APPLICATION_FORM,
  VIEW_ANY_APPLICATION_FORM_COUNTRY_INCOME_FEES,
  VIEW_APPLICATION_FORM_COUNTRY_INCOME_FEE,
  CREATE_APPLICATION_FORM_COUNTRY_INCOME_FEE,
  UPDATE_APPLICATION_FORM_COUNTRY_INCOME_FEE,
  DELETE_APPLICATION_FORM_COUNTRY_INCOME_FEE,
  SORT_APPLICATION_FORM_COUNTRY_INCOME_FEE,
  VIEW_ANY_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNTS,
  VIEW_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT,
  CREATE_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT,
  UPDATE_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT,
  DELETE_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT,
  SORT_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT,
  VIEW_ANY_NOTES,
  VIEW_NOTE,
  CREATE_NOTE,
  UPDATE_NOTE,
  DELETE_NOTE,
  VIEW_ANY_FAMILY_LIMBO_STEPS,
  VIEW_FAMILY_LIMBO_STEP,
  CREATE_FAMILY_LIMBO_STEP,
  UPDATE_FAMILY_LIMBO_STEP,
  DELETE_FAMILY_LIMBO_STEP,
  VIEW_ANY_EVALUATION_VIDEO_COMMENTS,
  VIEW_EVALUATION_VIDEO_COMMENT,
  CREATE_EVALUATION_VIDEO_COMMENT,
  UPDATE_EVALUATION_VIDEO_COMMENT,
  DELETE_EVALUATION_VIDEO_COMMENT,
  VIEW_ANY_THEMES,
  VIEW_THEME,
  CREATE_THEME,
  UPDATE_THEME,
  DELETE_THEME,
  VIEW_ANY_SCHEDULE_TYPES,
  VIEW_SCHEDULE_TYPE,
  CREATE_SCHEDULE_TYPE,
  UPDATE_SCHEDULE_TYPE,
  DELETE_SCHEDULE_TYPE,
  VIEW_ANY_UPCOMING_EVALUATION_TIME_ZONES,
  VIEW_UPCOMING_EVALUATION_TIME_ZONE,
  CREATE_UPCOMING_EVALUATION_TIME_ZONE,
  UPDATE_UPCOMING_EVALUATION_TIME_ZONE,
  DELETE_UPCOMING_EVALUATION_TIME_ZONE,
  VIEW_ANY_HOURS,
  VIEW_HOUR,
  CREATE_HOUR,
  UPDATE_HOUR,
  DELETE_HOUR,
  VIEW_ANY_MINUTES,
  VIEW_MINUTE,
  CREATE_MINUTE,
  UPDATE_MINUTE,
  DELETE_MINUTE,
  VIEW_ANY_TIME_PERIODS,
  VIEW_TIME_PERIOD,
  CREATE_TIME_PERIOD,
  UPDATE_TIME_PERIOD,
  DELETE_TIME_PERIOD,
};
