import { createStore } from "vuex";
import * as user from "@/store/modules/user.js";
import * as notification from "@/store/modules/notification.js";
import * as announcement from "@/store/modules/announcement.js";

export default createStore({
  modules: {
    user,
    notification,
    announcement,
  },
  state: {
    mobileHidden: true,
    hidden: true,
    familyHidden: true,
    clientHidden: true,
  },
  mutations: {
    TOGGLE_MOBILE_DROP_DOWN(state) {
      state.mobileHidden = !state.mobileHidden;
    },
    CLOSE_MOBILE_DROP_DOWN(state) {
      if (!state.mobileHidden) {
        state.mobileHidden = !state.mobileHidden;
      }
    },
    TOGGLE_PROFILE_DROP_DOWN(state) {
      state.hidden = !state.hidden;
    },
    CLOSE_PROFILE_DROP_DOWN(state) {
      if (!state.hidden) {
        state.hidden = !state.hidden;
      }
    },
    TOGGLE_FAMILIES(state) {
      state.familyHidden = !state.familyHidden;
    },
    CLOSE_FAMILIES(state) {
      if (!state.familyHidden) {
        state.familyHidden = !state.familyHidden;
      }
    },
    TOGGLE_CLIENTS(state) {
      state.clientHidden = !state.clientHidden;
    },
    CLOSE_CLIENTS(state) {
      if (!state.clientHidden) {
        state.clientHidden = !state.clientHidden;
      }
    },
  },
  actions: {
    toggleMobileDropdown({ commit }) {
      commit("TOGGLE_MOBILE_DROP_DOWN");
    },
    closeMobileDropdown({ commit }) {
      commit("CLOSE_MOBILE_DROP_DOWN");
    },
    toggleProfileDropdown({ commit }) {
      commit("TOGGLE_PROFILE_DROP_DOWN");
    },
    closeProfileDropdown({ commit }) {
      commit("CLOSE_PROFILE_DROP_DOWN");
    },
    toggleFamilies({ commit }) {
      commit("TOGGLE_FAMILIES");
    },
    closeFamilies({ commit }) {
      commit("CLOSE_FAMILIES");
    },
    toggleClients({ commit }) {
      commit("TOGGLE_CLIENTS");
    },
    closeClients({ commit }) {
      commit("CLOSE_CLIENTS");
    },
  },
});
