import {
  ADD_ACTIVITY,
  MANAGE_ACTIVITIES,
  ACTIVITY_CATEGORIES,
  ACTIVITY_LINK_TYPES,
} from "@/rbac/links.js";

import { UPDATE_ACTIVITY } from "@/rbac/permissions.js";

const activityRoutes = [
  {
    path: "/activities",
    component: () =>
      import(
        /* webpackChunkName: "activity-layout-full-width" */ "@/views/activity/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ActivityIndex",
        meta: { title: "Manage Activities", permission: MANAGE_ACTIVITIES },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,

          description: route.query.description || null,
          order_by_description: route.query.order_by_description || null,

          content: route.query.content || null,

          activity_category_id:
            parseInt(route.query.activity_category_id) || null,
          order_by_activity_category_name:
            route.query.order_by_activity_category_name || null,

          is_active: route.query.is_active
            ? parseInt(route.query.is_active)
            : null,
          order_by_is_active_name: route.query.order_by_is_active_name || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "activity-index" */ "@/views/activity/Index.vue"
          ),
      },
      {
        path: "create",
        name: "ActivityCreate",
        meta: { title: "Add Activity", permission: ADD_ACTIVITY },
        component: () =>
          import(
            /* webpackChunkName: "activity-create" */ "@/views/activity/Create.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "ActivityEdit",
        meta: { title: "Edit Activity", permission: UPDATE_ACTIVITY },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "activity-edit" */ "@/views/activity/Edit.vue"
          ),
      },
    ],
  },
  {
    path: "/activity-categories",
    component: () =>
      import(
        /* webpackChunkName: "activity-layout-full-width" */ "@/views/activity/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ActivityCategoryIndex",
        meta: { title: "Activity Categories", permission: ACTIVITY_CATEGORIES },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,

          description: route.query.description || null,
          order_by_description: route.query.order_by_description || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "activity-category-index" */ "@/views/activity-category/Index.vue"
          ),
      },
    ],
  },
  {
    path: "/activity-link-types",
    component: () =>
      import(
        /* webpackChunkName: "activity-layout-full-width" */ "@/views/activity/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ActivityLinkTypeIndex",
        meta: { title: "Activity Link Types", permission: ACTIVITY_LINK_TYPES },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,
          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,
          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "activity-link-type-index" */ "@/views/dropdown/ActivityLinkType.vue"
          ),
      },
    ],
  },
];

export default activityRoutes;
