import { EMAIL_NOTIFICATIONS } from "@/rbac/links.js";
import {
  CREATE_EMAIL_NOTIFICATION,
  VIEW_EMAIL_NOTIFICATION,
  UPDATE_EMAIL_NOTIFICATION,
} from "@/rbac/permissions.js";

const emailNotificationRoutes = [
  {
    path: "/email-notifications",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "EmailNotificationIndex",
        meta: { title: "Email Notifications", permission: EMAIL_NOTIFICATIONS },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,

          subject: route.query.subject || null,
          order_by_subject: route.query.order_by_subject || null,

          layout_id: parseInt(route.query.layout_id) || null,
          order_by_layout_name: route.query.order_by_layout_name || null,

          order_by_created_at: route.query.order_by_created_at || null,
          order_by_updated_at: route.query.order_by_updated_at || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "email-notification-index" */ "@/views/system/email-notification/Index.vue"
          ),
      },
      {
        path: "create",
        name: "EmailNotificationCreate",
        meta: {
          title: "Create Email Notification",
          permission: CREATE_EMAIL_NOTIFICATION,
        },
        component: () =>
          import(
            /* webpackChunkName: "email-notification-create" */ "@/views/system/email-notification/Create.vue"
          ),
      },
      {
        path: ":id/view",
        name: "EmailNotificationView",
        meta: {
          title: "View Email Notification",
          permission: VIEW_EMAIL_NOTIFICATION,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "email-notification-view" */ "@/views/system/email-notification/View.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "EmailNotificationEdit",
        meta: {
          title: "Edit Email Notification",
          permission: UPDATE_EMAIL_NOTIFICATION,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "email-notification-edit" */ "@/views/system/email-notification/Edit.vue"
          ),
      },
    ],
  },
];

export default emailNotificationRoutes;
