import { ERROR_LOGS } from "@/rbac/links.js";

const logRoutes = [
  {
    path: "/logs",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "LogIndex",
        meta: { title: "Error Logs", permission: ERROR_LOGS },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          level_name: route.query.level_name || null,
          message: route.query.message || null,
          context: route.query.context || null,

          order_by_created_at: route.query.order_by_created_at || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "log-index" */ "@/views/system/log/Index.vue"
          ),
      },
    ],
  },
];

export default logRoutes;
