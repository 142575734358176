import { AUTH_LOGS } from "@/rbac/links.js";

const authLogRoutes = [
  {
    path: "/auth-logs",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "AuthLogIndex",
        meta: { title: "Auth Logs", permission: AUTH_LOGS },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          user_id: parseInt(route.query.user_id) || null,
          username: route.query.username || null,
          email: route.query.email || null,
          ip: route.query.ip || null,
          device: route.query.device || null,
          os: route.query.os || null,
          browser: route.query.browser || null,
          language: route.query.language || null,
          country: route.query.country || null,
          city: route.query.city || null,
          postal_code: route.query.postal_code || null,
          timezone: route.query.timezone || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "auth-log-index" */ "@/views/system/auth-log/Index.vue"
          ),
      },
    ],
  },
];

export default authLogRoutes;
