import { APPLICATION_FORM_FEES } from "@/rbac/links.js";
import {
  CREATE_APPLICATION_FORM_FEE,
  VIEW_APPLICATION_FORM_FEE,
  UPDATE_APPLICATION_FORM_FEE,
} from "@/rbac/permissions.js";

const applicationFormFeeRoutes = [
  {
    path: "/application-form-fees",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ApplicationFormFeeIndex",
        meta: {
          title: "Application Form Fees",
          permission: APPLICATION_FORM_FEES,
        },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,

          initial_evaluation: parseInt(route.query.initial_evaluation) || null,
          order_by_initial_evaluation:
            route.query.order_by_initial_evaluation || null,

          deposit: parseInt(route.query.deposit) || null,
          order_by_deposit: route.query.order_by_deposit || null,

          monthly_membership: parseInt(route.query.monthly_membership) || null,
          order_by_monthly_membership:
            route.query.order_by_monthly_membership || null,

          additional_members_initial_evaluation:
            parseInt(route.query.additional_members_initial_evaluation) || null,
          order_by_additional_members_initial_evaluation:
            route.query.order_by_additional_members_initial_evaluation || null,

          additional_members_deposit:
            parseInt(route.query.additional_members_deposit) || null,
          order_by_additional_members_deposit:
            route.query.order_by_additional_members_deposit || null,

          additional_members_monthly_membership:
            parseInt(route.query.additional_members_monthly_membership) || null,
          order_by_additional_members_monthly_membership:
            route.query.order_by_additional_members_monthly_membership || null,

          additional_services:
            parseInt(route.query.additional_services) || null,
          order_by_additional_services:
            route.query.order_by_additional_services || null,

          rescheduling_fee: parseInt(route.query.rescheduling_fee) || null,
          order_by_rescheduling_fee:
            route.query.order_by_rescheduling_fee || null,

          fee: parseInt(route.query.fee) || null,
          order_by_fee: route.query.order_by_fee || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "application-form-fee-index" */ "@/views/system/application-form-fee/Index.vue"
          ),
      },
      {
        path: "create",
        name: "ApplicationFormFeeCreate",
        meta: {
          title: "Create Application Form Fee",
          permission: CREATE_APPLICATION_FORM_FEE,
        },
        component: () =>
          import(
            /* webpackChunkName: "application-form-fee-create" */ "@/views/system/application-form-fee/Create.vue"
          ),
      },
      {
        path: ":id/view",
        name: "ApplicationFormFeeView",
        meta: {
          title: "View Application Form Fee",
          permission: VIEW_APPLICATION_FORM_FEE,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "application-form-fee-view" */ "@/views/system/application-form-fee/View.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "ApplicationFormFeeEdit",
        meta: {
          title: "Edit Application Form Fee",
          permission: UPDATE_APPLICATION_FORM_FEE,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "application-form-fee-edit" */ "@/views/system/application-form-fee/Edit.vue"
          ),
      },
    ],
  },
];

export default applicationFormFeeRoutes;
