<template>
  <div class="grid grid-cols-1 sm:grid-cols-4 gap-4">
    <div class="sm:col-span-3">
      <router-view />
    </div>
    <div>
      <BaseActions :links="links" label="Sub-Menu" />
    </div>
  </div>
</template>

<script>
import {
  MY_DASHBOARD,
  VIEW_MY_PROFILE,
  EDIT_MY_PROFILE,
  CHANGE_MY_PASSWORD,
} from "@/rbac/links.js";

export default {
  data() {
    return {
      links: [
        {
          to: { name: "ProfileDashboard" },
          label: "My Dashboard",
          permission: MY_DASHBOARD,
        },
        {
          to: { name: "ProfileView" },
          label: "View My Profile",
          permission: VIEW_MY_PROFILE,
        },
        {
          to: { name: "ProfileEdit" },
          label: "Edit My Profile",
          permission: EDIT_MY_PROFILE,
        },
        {
          to: { name: "ProfileChangePassword" },
          label: "Change Password",
          permission: CHANGE_MY_PASSWORD,
        },
      ],
    };
  },
};
</script>
