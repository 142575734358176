import {
  NEW_EVALUATION_VIDEOS,
  ALL_UPLOADED_VIDEOS,
  RECOMMENDED_EVALUATION_VIDEOS,
} from "@/rbac/links.js";

function props(route) {
  let orderByDefault = route.query.order_by_created_at || "desc";

  if (
    route.query.order_by_id ||
    route.query.order_by_client_id ||
    route.query.order_by_client_full_name ||
    route.query.order_by_family_full_name ||
    route.query.order_by_chapter_name ||
    route.query.order_by_evaluator_full_name ||
    route.query.order_by_coach_full_name ||
    route.query.order_by_tag_name ||
    route.query.order_by_name ||
    route.query.order_by_description ||
    route.query.order_by_has_staff_comments_name ||
    route.query.order_by_has_family_comments_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    client_id: route.query.client_id || null,
    order_by_client_id: route.query.order_by_client_id || null,

    client_full_name: route.query.client_full_name || null,
    order_by_client_full_name: route.query.order_by_client_full_name || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    chapter_id: parseInt(route.query.chapter_id) || null,
    order_by_chapter_name: route.query.order_by_chapter_name || null,

    evaluator_id: parseInt(route.query.evaluator_id) || null,
    order_by_evaluator_full_name:
      route.query.order_by_evaluator_full_name || null,

    coach_id: parseInt(route.query.coach_id) || null,
    order_by_coach_full_name: route.query.order_by_coach_full_name || null,

    tag_id: parseInt(route.query.tag_id) || null,
    order_by_tag_name: route.query.order_by_tag_name || null,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    description: route.query.description || null,
    order_by_description: route.query.order_by_description || null,

    has_staff_comments: route.query.has_staff_comments
      ? parseInt(route.query.has_staff_comments)
      : null,
    order_by_has_staff_comments_name:
      route.query.order_by_has_staff_comments_name || null,

    has_family_comments: route.query.has_family_comments
      ? parseInt(route.query.has_family_comments)
      : null,
    order_by_has_family_comments_name:
      route.query.order_by_has_family_comments_name || null,

    order_by_created_at: orderByDefault,
  };
}

function newProps(route) {
  let orderByDefault = route.query.order_by_created_at || "desc";

  if (
    route.query.order_by_id ||
    route.query.order_by_client_id ||
    route.query.order_by_client_full_name ||
    route.query.order_by_family_full_name ||
    route.query.order_by_chapter_name ||
    route.query.order_by_evaluator_full_name ||
    route.query.order_by_coach_full_name ||
    route.query.order_by_tag_name ||
    route.query.order_by_name ||
    route.query.order_by_description ||
    route.query.order_by_has_staff_comments_name ||
    route.query.order_by_has_family_comments_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    client_id: route.query.client_id || null,
    order_by_client_id: route.query.order_by_client_id || null,

    client_full_name: route.query.client_full_name || null,
    order_by_client_full_name: route.query.order_by_client_full_name || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    chapter_id: parseInt(route.query.chapter_id) || null,
    order_by_chapter_name: route.query.order_by_chapter_name || null,

    evaluator_id: parseInt(route.query.evaluator_id) || null,
    order_by_evaluator_full_name:
      route.query.order_by_evaluator_full_name || null,

    coach_id: parseInt(route.query.coach_id) || null,
    order_by_coach_full_name: route.query.order_by_coach_full_name || null,

    tag_id: parseInt(route.query.tag_id) || null,
    order_by_tag_name: route.query.order_by_tag_name || null,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    description: route.query.description || null,
    order_by_description: route.query.order_by_description || null,

    has_staff_comments: route.query.has_staff_comments
      ? parseInt(route.query.has_staff_comments)
      : null,
    order_by_has_staff_comments_name:
      route.query.order_by_has_staff_comments_name || null,

    has_family_comments: route.query.has_family_comments
      ? parseInt(route.query.has_family_comments)
      : null,
    order_by_has_family_comments_name:
      route.query.order_by_has_family_comments_name || null,

    is_coach_seen: route.query.is_coach_seen
      ? parseInt(route.query.is_coach_seen)
      : null,
    order_by_is_coach_seen_name:
      route.query.order_by_is_coach_seen_name || null,

    is_evaluator_seen: route.query.is_evaluator_seen
      ? parseInt(route.query.is_evaluator_seen)
      : null,
    order_by_is_evaluator_seen_name:
      route.query.order_by_is_evaluator_seen_name || null,

    order_by_created_at: orderByDefault,
  };
}

function recommendedProps(route) {
  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    client_id: route.query.client_id || null,
    order_by_client_id: route.query.order_by_client_id || null,

    client_full_name: route.query.client_full_name || null,
    order_by_client_full_name: route.query.order_by_client_full_name || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    chapter_id: parseInt(route.query.chapter_id) || null,
    order_by_chapter_name: route.query.order_by_chapter_name || null,

    evaluator_id: parseInt(route.query.evaluator_id) || null,
    order_by_evaluator_full_name:
      route.query.order_by_evaluator_full_name || null,

    coach_id: parseInt(route.query.coach_id) || null,
    order_by_coach_full_name: route.query.order_by_coach_full_name || null,

    tag_id: parseInt(route.query.tag_id) || null,
    order_by_tag_name: route.query.order_by_tag_name || null,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    description: route.query.description || null,
    order_by_description: route.query.order_by_description || null,

    staff_comments: route.query.staff_comments || null,
    order_by_staff_comments: route.query.order_by_staff_comments || null,

    family_comments: route.query.family_comments || null,
    order_by_family_comments: route.query.order_by_family_comments || null,
  };
}

const evaluationVideoRoutes = [
  {
    path: "/evaluation-videos",
    component: () =>
      import(
        /* webpackChunkName: "layout-full-width" */ "@/views/layouts/FullWidth.vue"
      ),
    children: [
      {
        path: "",
        name: "EvaluationVideoIndex",
        meta: { title: "All Uploaded Videos", permission: ALL_UPLOADED_VIDEOS },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "evaluation-video-index" */ "@/views/evaluation-video/Index.vue"
          ),
      },
    ],
  },
  {
    path: "/evaluation-new-videos",
    component: () =>
      import(
        /* webpackChunkName: "layout-full-width" */ "@/views/layouts/FullWidth.vue"
      ),
    children: [
      {
        path: "",
        name: "EvaluationNewVideoIndex",
        meta: {
          title: "New Evaluation Videos",
          permission: NEW_EVALUATION_VIDEOS,
        },
        props: (route) => newProps(route),
        component: () =>
          import(
            /* webpackChunkName: "evaluation-new-video-index" */ "@/views/evaluation-new-video/Index.vue"
          ),
      },
    ],
  },
  {
    path: "/evaluation-recommended-videos",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "EvaluationRecommendedVideoIndex",
        meta: {
          title: "Recommended Evaluation Videos",
          permission: RECOMMENDED_EVALUATION_VIDEOS,
        },
        props: (route) => recommendedProps(route),
        component: () =>
          import(
            /* webpackChunkName: "evaluation-recommended-video-index" */ "@/views/system/evaluation-video/Recommended.vue"
          ),
      },
    ],
  },
];

export default evaluationVideoRoutes;
