<template>
  <Navigation />
  <div
    class="mx-auto p-4 sm:p-8 min-h-screen h-full"
    :class="{
      'bg-gray-100': isDark,
      'bg-light-green': isLight,
      'bg-pink-100': isColorful,
    }"
  >
    <Announcements :type_id="typeId" />

    <router-view />
  </div>
  <Footer />
</template>

<script>
import Navigation from "@/views/layouts/Navigation";
import Footer from "@/views/layouts/Footer";
import Announcements from "@/components/announcement/Announcements.vue";

import { ANNOUNCEMENT_TYPE_STAFF } from "@/constants";

import { mapGetters } from "vuex";

export default {
  components: {
    Navigation,
    Announcements,
    Footer,
  },
  data() {
    return {
      typeId: ANNOUNCEMENT_TYPE_STAFF,
    };
  },
  computed: {
    ...mapGetters("user", ["isLight", "isDark", "isColorful"]),
  },
};
</script>
