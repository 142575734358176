<template>
  <label class="font-medium text-gray-700" :class="size">
    {{ label }}
    <slot />
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "text-sm",
    },
  },
};
</script>
