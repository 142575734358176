<template>
  <div @click="away">
    <BaseFlash
      v-for="notification in notifications"
      :notification="notification"
      :key="notification.id"
    />
    <router-view />
  </div>
  <Feedback :model="user" v-if="user" />
</template>

<script>
import Feedback from "@/components/misc/Feedback.vue";
import { mapState } from "vuex";

export default {
  components: { Feedback },
  methods: {
    away() {
      this.$store.dispatch("closeMobileDropdown");
      this.$store.dispatch("closeProfileDropdown");
      this.$store.dispatch("closeFamilies");
      this.$store.dispatch("closeClients");
    },
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("notification", ["notifications"]),
  },
};
</script>
<style lang="scss">
@import "@/assets/styles/global.scss";
</style>
