import { VIEW_NACD_COURSE_ACTIVITY } from "@/rbac/links.js";

const courseActivityRoutes = [
  {
    path: "/academy/course-activities",
    component: () =>
      import(
        /* webpackChunkName: "academy-course-activity-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: ":id",
        name: "AcademyCourseActivityView",
        meta: {
          title: "View Course Activity",
          permission: VIEW_NACD_COURSE_ACTIVITY,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "academy-course-activity-view" */ "@/views/academy/activity/View.vue"
          ),
      },
    ],
  },
];

export default courseActivityRoutes;
