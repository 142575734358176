<template>
  <footer
    class="w-full py-8"
    :class="{
      'bg-gray-800': isDark,
      'bg-teal-600': isLight,
      'bg-pink-600': isColorful,
    }"
  >
    <div class="max-w-screen-xl text-sm mx-auto px-4">
      <div
        class="text-center space-y-4"
        :class="{
          'text-gray-300': isDark,
          'text-white': isColorful || isLight,
        }"
      >
        <div>
          &copy; 1984&mdash;{{ year }} NACD International | The National
          Association for Child Development. All rights reserved.
        </div>
        <div></div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapGetters("user", ["isLight", "isDark", "isColorful"]),
  },
};
</script>
