import { ONLINE_APPLICATION_FORMS_VIEW_ARCHIVE } from "@/rbac/links.js";
import { VIEW_APPLICATION_FORM } from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_created_at || "desc";

  if (
    route.query.order_by_country_name ||
    route.query.order_by_type_name ||
    route.query.order_by_status_name ||
    route.query.order_by_come_from_name ||
    route.query.order_by_updated_at
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    id: route.params.id,

    country_id: parseInt(route.query.country_id) || null,
    order_by_country_name: route.query.order_by_country_name || null,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    come_from_id: parseInt(route.query.come_from_id) || null,
    order_by_come_from_name: route.query.order_by_come_from_name || null,

    order_by_created_at: orderByDefault,
    order_by_updated_at: route.query.order_by_updated_at || null,
  };
}

const clientApplicationFormRoutes = [
  {
    path: "application-forms",
    name: "ClientApplicationFormIndex",
    meta: {
      title: "Application Form Archive",
      permission: ONLINE_APPLICATION_FORMS_VIEW_ARCHIVE,
    },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "client-application-form-index" */ "@/views/client/application-form/Index.vue"
      ),
  },
  {
    path: "application-forms/:applicationFormId",
    name: "ClientApplicationFormView",
    meta: {
      title: "View Application Form",
      permission: VIEW_APPLICATION_FORM,
    },
    props: (route) => ({
      id: route.params.id,
      applicationFormId: parseInt(route.params.applicationFormId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-application-form-view" */ "@/views/client/application-form/View.vue"
      ),
  },
];

export default clientApplicationFormRoutes;
