<template>
  <BaseLabel :label="label" v-if="label" />

  <div :class="disabledClass">{{ value }}</div>

  <!-- <input
    v-bind="$attrs"
    type="text"
    :class="disabledClass"
    :value="value"
    disabled="true"
  /> -->
</template>

<script>
import { inputMixin } from "@/mixins/form.js";

export default {
  props: {
    size: {
      type: String,
      default: "md",
    },
    value: {
      type: [String, Number],
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    bgClass: {
      type: String,
      default: "bg-gray-100",
    },
  },
  mixins: [inputMixin],
  computed: {
    disabledClass() {
      return `${this.activeClass} ${this.bgClass} break-all`;
    },
  },
};
</script>

<style lang="scss" scoped>
div {
  min-height: 40px;
  min-width: 100px;
}
</style>
