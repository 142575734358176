import router from "@/router/index.js";

const TOKEN = "token";
const REFRESH_TOKEN = "refresh-token";
const USER = "user";
const LOGIN_AS = "login-as";

class AuthService {
  logout() {
    this.clearAll();
    return router.replace({ name: "Login" });
  }

  logoutAndLoginAsStaff(username) {
    this.clearAll();
    return router.replace({
      name: "LoginAsStaff",
      query: { staff_username: username },
    });
  }

  handleNetworkError() {
    router.push({ name: "NetworkError" });
  }

  setLoginAs(value) {
    localStorage.setItem(LOGIN_AS, value);
  }

  getLoginAs() {
    let result = localStorage.getItem(LOGIN_AS);
    return result ? Boolean(result) : false;
  }

  removeLoginAs() {
    localStorage.removeItem(LOGIN_AS);
  }

  setToken(token) {
    localStorage.setItem(TOKEN, token);
  }

  getToken() {
    return localStorage.getItem(TOKEN);
  }

  removeToken() {
    localStorage.removeItem(TOKEN);
  }

  setRefreshToken(token) {
    localStorage.setItem(REFRESH_TOKEN, token);
  }

  getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN);
  }

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN);
  }

  setUser(user) {
    localStorage.setItem(USER, JSON.stringify(user));
  }

  getUser() {
    return JSON.parse(localStorage.getItem(USER));
  }

  permissions() {
    let user = this.getUser();

    return user ? user.user.roles[0].permissions.map((item) => item.name) : [];
  }

  hasPermission(permission) {
    return this.permissions().includes(permission);
  }

  id() {
    return this.getUser().user_id;
  }

  removeUser() {
    localStorage.removeItem(USER);
  }

  clearAll() {
    localStorage.clear();
  }

  isGuest() {
    return this.getToken() === null || localStorage.getItem(USER) === null;
  }

  isAuth() {
    return !this.isGuest();
  }

  setData({ access_token, refresh_token, staff }) {
    this.setToken(access_token);
    this.setRefreshToken(refresh_token);
    this.setUser(staff);
  }
}

export default AuthService;
