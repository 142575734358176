import {
  HISTORICAL_REPORTS,
  ALL_REPORTS,
  CHAPTERS_REPORTS,
  VIEW_CHAPTER_STATISTIC,
  STAFF_REPORTS,
  VIEW_STAFF_STATISTIC,
} from "@/rbac/links.js";

import { idNameProps, staffProps } from "@/services/props.js";

const reportRoutes = [
  {
    path: "/reports",
    component: () =>
      import(
        /* webpackChunkName: "report-layout-full-width" */ "@/views/report/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ReportView",
        meta: { title: "All Reports", permission: ALL_REPORTS },
        component: () =>
          import(
            /* webpackChunkName: "report-view" */ "@/views/report/View.vue"
          ),
      },
      {
        path: "history",
        name: "ReportHistoryView",
        meta: { title: "Historical Reports", permission: HISTORICAL_REPORTS },
        component: () =>
          import(
            /* webpackChunkName: "report-history" */ "@/views/report/History.vue"
          ),
      },
      {
        path: "chapters",
        name: "ChapterStatistic",
        meta: { title: "Chapters Reports", permission: CHAPTERS_REPORTS },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "chapters" */ "@/views/chapter/Index.vue"
          ),
      },
      {
        path: "chapters/:id/stats",
        name: "ChapterStatisticView",
        meta: {
          title: "View Chapter Report",
          permission: VIEW_CHAPTER_STATISTIC,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "chapter-stats" */ "@/views/chapter/Statistic.vue"
          ),
      },
      {
        path: "staff",
        name: "StaffStatistic",
        meta: { title: "Staff Reports", permission: STAFF_REPORTS },
        props: (route) => staffProps(route),
        component: () =>
          import(/* webpackChunkName: "chapters" */ "@/views/staff/Staff.vue"),
      },
      {
        path: "staff/:id/stats",
        name: "StaffStatisticView",
        meta: {
          title: "View Staff Report",
          permission: VIEW_STAFF_STATISTIC,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "staff-stats" */ "@/views/staff/Statistic.vue"
          ),
      },
    ],
  },
];

export default reportRoutes;
