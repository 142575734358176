import { APPLICATION_FORM_PAGE_ASSIGNMENTS } from "@/rbac/links.js";

const applicationFormPageAssignmentRoutes = [
  {
    path: "/application-form-page-assignemnts",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ApplicationFormPageAssignmentIndex",
        meta: {
          title: "Application Form Page Assignments",
          permission: APPLICATION_FORM_PAGE_ASSIGNMENTS,
        },
        component: () =>
          import(
            /* webpackChunkName: "application-form-page-assignment-index" */ "@/views/system/application-form-page-assignment/Index.vue"
          ),
      },
    ],
  },
];

export default applicationFormPageAssignmentRoutes;
