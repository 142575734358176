import { VIEW_NACD_COURSE_INTRO } from "@/rbac/links.js";

const courseIntroRoutes = [
  {
    path: "/academy/course-intro",
    component: () =>
      import(
        /* webpackChunkName: "academy-course-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: ":id",
        name: "AcademyCourseIntroView",
        meta: {
          title: "View Course Intro",
          permission: VIEW_NACD_COURSE_INTRO,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "academy-course-intro-view" */ "@/views/academy/course/Intro.vue"
          ),
      },
    ],
  },
];

export default courseIntroRoutes;
