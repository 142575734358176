<template>
  <div class="flex items-start border-l-4 p-4" :class="className" role="alert">
    <div class="flex-1">
      <p class="font-bold">{{ model.name }}</p>
      <div v-html="model.content"></div>
    </div>
    <button class="h-5 w-5" @click="close">
      <BaseLoader color="gray" v-if="loading" />
      <BaseCloseIcon class="text-gray-600" v-else />
    </button>
  </div>
</template>

<script>
import CloseAnnouncement from "@/graphql/mutations/announcement/close.gql";

import {
  ANNOUNCEMENT_MESSAGE_TYPE_SUCCESS,
  ANNOUNCEMENT_MESSAGE_TYPE_ERROR,
  ANNOUNCEMENT_MESSAGE_TYPE_WARNING,
  ANNOUNCEMENT_MESSAGE_TYPE_INFO,
} from "@/constants";

import { errorMixin } from "@/mixins/error.js";
import { notificationMixin } from "@/mixins/notification.js";

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  mixins: [errorMixin, notificationMixin],
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    close() {
      this.clearNotifications();

      this.loading = true;

      this.$apollo
        .mutate({
          mutation: CloseAnnouncement,
          variables: {
            model: {
              id: this.model.id,
            },
          },
        })
        .then(() => {
          this.$emit("close", this.model);
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  computed: {
    className() {
      let result;

      switch (this.model.message_type_id) {
        case ANNOUNCEMENT_MESSAGE_TYPE_SUCCESS:
          result = "green";
          break;
        case ANNOUNCEMENT_MESSAGE_TYPE_ERROR:
          result = "red";
          break;
        case ANNOUNCEMENT_MESSAGE_TYPE_WARNING:
          result = "yellow";
          break;
        case ANNOUNCEMENT_MESSAGE_TYPE_INFO:
          result = "blue";
          break;
      }

      return `bg-${result}-200 border-${result}-600 text-${result}-600`;
    },
  },
};
</script>
