<template>
  <nav class="flex select-none" aria-label="Breadcrumb">
    <ol class="inline-flex items-center space-x-1">
      <li v-for="(link, index) in links" :key="`link-${index}`">
        <div class="flex items-center">
          <BaseRightIcon class="w-5 h-5 text-gray-400" v-if="index !== 0" />

          <template v-if="index !== links.length - 1">
            <router-link :to="link.to">
              <span
                class="text-sm font-medium text-blue-600 hover:text-blue-800 hover:underline cursor-pointer"
              >
                {{ link.label }}
              </span>
            </router-link>
          </template>
          <span v-else class="text-sm font-medium text-gray-500">
            {{ link.label }}
          </span>
        </div>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
