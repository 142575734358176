import { MANAGE_COURSES } from "@/rbac/links.js";
import { SORT_COURSE, VIEW_COURSE } from "@/rbac/permissions.js";

import courseActivityRoutes from "@/router/modules/course/activity.js";
import courseIntroActivityRoutes from "@/router/modules/course/intro-activity.js";
import courseCategoryRoutes from "@/router/modules/course/category.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_id ||
    route.query.order_by_name ||
    route.query.order_by_is_active_name ||
    route.query.order_by_is_enabled_for_active_families_name ||
    route.query.order_by_is_enabled_for_inactive_families_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,

    is_active: route.query.is_active ? parseInt(route.query.is_active) : null,
    order_by_is_active_name: route.query.order_by_is_active_name || null,

    is_enabled_for_active_families: route.query.is_enabled_for_active_families
      ? parseInt(route.query.is_enabled_for_active_families)
      : null,
    order_by_is_enabled_for_active_families_name:
      route.query.order_by_is_enabled_for_active_families_name || null,

    is_enabled_for_inactive_families: route.query
      .is_enabled_for_inactive_families
      ? parseInt(route.query.is_enabled_for_inactive_families)
      : null,
    order_by_is_enabled_for_inactive_families_name:
      route.query.order_by_is_enabled_for_inactive_families_name || null,
  };
}

const courseRoutes = [
  {
    path: "/courses",
    component: () =>
      import(
        /* webpackChunkName: "course-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "CourseIndex",
        meta: { title: "Manage Courses", permission: MANAGE_COURSES },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "course-index" */ "@/views/course/Index.vue"
          ),
      },
      {
        path: ":id",
        name: "CourseView",
        meta: { title: "View Course", permission: VIEW_COURSE },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-view" */ "@/views/course/View.vue"
          ),
      },
      {
        path: "sort",
        name: "CourseSort",
        meta: { title: "Sort Course", permission: SORT_COURSE },
        component: () =>
          import(
            /* webpackChunkName: "course-sort" */ "@/views/course/Sort.vue"
          ),
      },
    ],
  },
  ...courseCategoryRoutes,
  ...courseActivityRoutes,
  ...courseIntroActivityRoutes,
];

export default courseRoutes;
