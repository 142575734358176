import { APPLICATION_FORM_INCOMES } from "@/rbac/links.js";

import { idNameProps } from "@/services/props.js";

const applicationFormIncomeRoutes = [
  {
    path: "/application-form-incomes",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ApplicationFormIncomeIndex",
        meta: {
          title: "Application Form Incomes",
          permission: APPLICATION_FORM_INCOMES,
        },
        props: (route) => idNameProps(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-income-index" */ "@/views/system/application-form-income/Index.vue"
          ),
      },
    ],
  },
];

export default applicationFormIncomeRoutes;
