<template>
  <div class="radio">
    <input
      type="radio"
      :checked="isChecked"
      :value="value"
      :disabled="isChecked"
    />
    <label class="radio-label">
      <span class="text-gray-700" v-if="label"> {{ label }} </span>
    </label>
  </div>
  <!-- <label class="inline-flex items-center">
    <input
      class="h-4 w-4"
      type="radio"
      :checked="isChecked"
      :value="value"
      disabled
    />
    <span class="ml-1 text-gray-700" v-if="label"> {{ label }} </span>
  </label> -->
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      name: "",
    },
    modelValue: {
      type: [String, Number, Boolean, Array],
      default: "",
    },
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
  },
  computed: {
    ids() {
      return this.modelValue.map((item) => {
        if (item.id !== null) {
          return item.id;
        }
      });
    },
    isChecked() {
      if (Array.isArray(this.modelValue)) {
        return this.ids.includes(this.value);
      } else {
        return this.modelValue === this.value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$color1: rgb(243, 244, 246);
$color2: #3197ee;

.radio {
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: "";
        background: $color1;
        border-radius: 100%;
        border: 1px solid darken($color1, 35%);
        display: inline-block;
        width: 1em;
        height: 1em;
        top: 0.25em;
        position: relative;
        margin-right: 0.24em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }

    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 3px $color1;
          border-color: darken($color1, 35%);
          background: darken($color1, 35%);
        }
      }
    }
  }
}
</style>
