import { PERMISSIONS, PERMISSION_CATEGORIES } from "@/rbac/links.js";
import { VIEW_PERMISSION } from "@/rbac/permissions.js";

const permissionRoutes = [
  {
    path: "/permissions",
    component: () =>
      import(/* webpackChunkName: "rbac-layout" */ "@/views/rbac/Layout.vue"),
    children: [
      {
        path: "",
        name: "PermissionIndex",
        meta: { title: "Permissions", permission: PERMISSIONS },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,

          category_id: parseInt(route.query.category_id) || null,
          order_by_category_name: route.query.order_by_category_name || null,

          label: route.query.label || null,
          order_by_label: route.query.order_by_label || null,

          order_by_created_at: route.query.order_by_created_at || null,
          order_by_updated_at: route.query.order_by_updated_at || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "permission-index" */ "@/views/permission/Index.vue"
          ),
      },
      {
        path: ":id/view",
        name: "PermissionView",
        meta: { title: "View Permission", permission: VIEW_PERMISSION },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "permission-view" */ "@/views/permission/View.vue"
          ),
      },
    ],
  },
  {
    path: "/permission-categories",
    component: () =>
      import(/* webpackChunkName: "rbac-layout" */ "@/views/rbac/Layout.vue"),
    children: [
      {
        path: "",
        name: "PermissionCategoryIndex",
        meta: {
          title: "Permission Categoriess",
          permission: PERMISSION_CATEGORIES,
        },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "permission-index" */ "@/views/permission-category/Index.vue"
          ),
      },
    ],
  },
];

export default permissionRoutes;
