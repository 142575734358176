<template>
  <router-link
    class="inline-flex items-center transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none pr-2 py-1"
    :to="to"
  >
    <BaseLeftIcon class="h-5 w-5" />
    {{ label }}
  </router-link>
</template>

<script>
export default {
  props: {
    to: {
      type: Object,
      required: true,
    },
    label: {
      type: String,
      default: "Back",
    },
  },
};
</script>
