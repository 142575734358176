<template>
  <span class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full">
    {{ label }}
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: [String, Number],
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped></style>
