<template>
  <div
    id="flashMessage"
    class="bottom-3 fixed px-4 py-2 right-3 rounded-xl text-white"
    :class="className"
  >
    {{ notification.message }}
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { messageMixin } from "@/mixins/message.js";

export default {
  mixins: [messageMixin],
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      timeout: null,
      type: this.notification.type,
    };
  },
  mounted() {
    this.timeout = setTimeout(() => this.remove(this.notification), 3000);
  },
  beforeUnmount() {
    clearTimeout(this.timeout);
  },
  methods: {
    ...mapActions("notification", ["remove"]),
  },
};
</script>
