import { SUCCESS, ERROR, INFO } from "@/constants";

const messageMixin = {
  computed: {
    className() {
      let result;
      switch (this.type) {
        default:
          result = "gray";
          break;
        case SUCCESS:
          result = "green";
          break;
        case ERROR:
          result = "red";
          break;
        case INFO:
          result = "blue";
          break;
      }

      return `bg-${result}-600`;
    },
  },
};

export { messageMixin };
