import { LIMBO_STEP_ARTICLES } from "@/rbac/links.js";
import {
  CREATE_LIMBO_STEP_ARTICLE,
  VIEW_LIMBO_STEP_ARTICLE,
  UPDATE_LIMBO_STEP_ARTICLE,
} from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_id ||
    route.query.order_by_limbo_step_name ||
    route.query.order_by_title ||
    route.query.order_by_label ||
    route.query.order_by_video_title
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    limbo_step_id: parseInt(route.query.limbo_step_id) || null,
    order_by_limbo_step_name: route.query.order_by_limbo_step_name || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,

    title: route.query.title || null,
    order_by_title: route.query.order_by_title || null,

    label: route.query.label || null,
    order_by_label: route.query.order_by_label || null,

    video_title: route.query.video_title || null,
    order_by_video_title: route.query.order_by_video_title || null,
  };
}

const limboStepArticleArticleRoutes = [
  {
    path: "/limbo-step-articles",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "LimboStepArticleIndex",
        meta: { title: "Limbo Step Articles", permission: LIMBO_STEP_ARTICLES },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "limbo-step-article-index" */ "@/views/system/limbo-step-article/Index.vue"
          ),
      },
      {
        path: "create",
        name: "LimboStepArticleCreate",
        meta: {
          title: "Create Limbo Step Article",
          permission: CREATE_LIMBO_STEP_ARTICLE,
        },
        component: () =>
          import(
            /* webpackChunkName: "limbo-step-article-create" */ "@/views/system/limbo-step-article/Create.vue"
          ),
      },
      {
        path: ":id/view",
        name: "LimboStepArticleView",
        meta: {
          title: "View Limbo Step Article",
          permission: VIEW_LIMBO_STEP_ARTICLE,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "limbo-step-article-view" */ "@/views/system/limbo-step-article/View.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "LimboStepArticleEdit",
        meta: {
          title: "Edit Limbo Step Article",
          permission: UPDATE_LIMBO_STEP_ARTICLE,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "limbo-step-article-edit" */ "@/views/system/limbo-step-article/Edit.vue"
          ),
      },
    ],
  },
];

export default limboStepArticleArticleRoutes;
