import { FEEDBACKS } from "@/rbac/links.js";

const noteRoutes = [
  {
    path: "/notes",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "NoteIndex",
        meta: { title: "Feedbacks", permission: FEEDBACKS },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          user_id: parseInt(route.query.user_id) || null,
          username: route.query.username || null,
          email: route.query.email || null,
          ip: route.query.ip || null,
          device: route.query.device || null,
          os: route.query.os || null,
          browser: route.query.browser || null,
          language: route.query.language || null,
          is_seen: route.query.is_seen ? parseInt(route.query.is_seen) : null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "auth-log-index" */ "@/views/system/note/Index.vue"
          ),
      },
    ],
  },
];

export default noteRoutes;
