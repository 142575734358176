<template>
  <h1 class="text-3xl mb-4 text-center sm:mb-0 sm:text-left" :class="font">
    <slot />
  </h1>
</template>

<script>
export default {
  props: {
    font: {
      type: String,
      default: "font-normal",
    },
  },
};
</script>
