import { APPLICATION_FORM_COUNTRY_INCOME_DISCOUNTS } from "@/rbac/links.js";
import { SORT_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT } from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_country_name ||
    route.query.order_by_income_name ||
    route.query.order_by_discount_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    country_id: parseInt(route.query.country_id) || null,
    order_by_country_name: route.query.order_by_country_name || null,

    income_id: parseInt(route.query.income_id) || null,
    order_by_income_name: route.query.order_by_income_name || null,

    discount_id: parseInt(route.query.discount_id) || null,
    order_by_discount_name: route.query.order_by_discount_name || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,
  };
}

const applicationFormCountryIncomeDiscountRoutes = [
  {
    path: "/application-form-country-income-discounts",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ApplicationFormCountryIncomeDiscountIndex",
        meta: {
          title: "Application Form Country Income Discounts",
          permission: APPLICATION_FORM_COUNTRY_INCOME_DISCOUNTS,
        },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-country-income-discount-index" */ "@/views/system/application-form-country-income-discount/Index.vue"
          ),
      },
      {
        path: "sort",
        name: "ApplicationFormCountryIncomeDiscountSort",
        meta: {
          title: "Sort Application Form Country Income Discounts",
          permission: SORT_APPLICATION_FORM_COUNTRY_INCOME_DISCOUNT,
        },
        component: () =>
          import(
            /* webpackChunkName: "application-form-country-income-discount-index" */ "@/views/system/application-form-country-income-discount/Sort.vue"
          ),
      },
    ],
  },
];

export default applicationFormCountryIncomeDiscountRoutes;
