<template>
  <div
    class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
  >
    <input
      type="checkbox"
      class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer"
      :checked="modelValue"
      @input="$emit('update:modelValue', $event.target.checked)"
    />
    <label
      for="toggle"
      class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
    ></label>
  </div>

  <label for="toggle" class="text-xs text-gray-700" v-if="label">
    {{ label }}
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [Boolean, Number],
      defalt: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-checkbox:checked {
  //   @apply right-0 border-green-400;
  right: 0;
  border-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}
.toggle-checkbox:checked + .toggle-label {
  //   @apply bg-green-400;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}
</style>
