import Layout from "@/views/profile/Layout.vue";

import {
  MY_DASHBOARD,
  VIEW_MY_PROFILE,
  EDIT_MY_PROFILE,
  CHANGE_MY_PASSWORD,
} from "@/rbac/links.js";

const profileRoutes = [
  {
    path: "",
    name: "ProfileLayout",
    component: Layout,
    children: [
      {
        path: "dashboard",
        name: "ProfileDashboard",
        meta: { title: "My Dashboard", permission: MY_DASHBOARD },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 20,

          user_id: parseInt(route.query.user_id) || null,
          family_id: parseInt(route.query.family_id) || null,
          staff_id: parseInt(route.query.staff_id) || null,
          client_id: route.query.client_id || null,
          username: route.query.username || null,
          email: route.query.email || null,
          family_full_name: route.query.family_full_name || null,
          staff_full_name: route.query.staff_full_name || null,
          type_id: parseInt(route.query.type_id) || null,
          operation_id: parseInt(route.query.operation_id) || null,
          message: route.query.message || null,
          original_attributes: route.query.original_attributes || null,
          changed_attributes: route.query.changed_attributes || null,

          order_by_created_at: route.query.order_by_created_at || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "profile-view" */ "@/views/track/Index.vue"
          ),
      },
      {
        path: "",
        name: "RedirectProfileView",
        redirect: { name: "ProfileView" },
      },
      {
        path: "view",
        name: "ProfileView",
        meta: { title: "My Profile", permission: VIEW_MY_PROFILE },
        component: () =>
          import(
            /* webpackChunkName: "profile-view" */ "@/views/profile/View.vue"
          ),
      },
      {
        path: "edit",
        name: "ProfileEdit",
        meta: { title: "Edit My Profile", permission: EDIT_MY_PROFILE },
        component: () =>
          import(
            /* webpackChunkName: "profile-edit" */ "@/views/profile/Edit.vue"
          ),
      },
      {
        path: "change-password",
        name: "ProfileChangePassword",
        meta: { title: "Change Password", permission: CHANGE_MY_PASSWORD },
        component: () =>
          import(
            /* webpackChunkName: "profile-change-password" */ "@/views/profile/ChangePassword.vue"
          ),
      },
    ],
  },
];

export default profileRoutes;
