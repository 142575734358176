import { EVALUATION_VIDEOS, UPLOAD_EVALUATION_VIDEO } from "@/rbac/links.js";

const clientEvaluationVideoRoutes = [
  {
    path: "evaluation-videos",
    name: "ClientEvaluationVideoIndex",
    meta: { title: "Evaluation Videos", permission: EVALUATION_VIDEOS },
    props: (route) => ({
      id: route.params.id,

      page: parseInt(route.query.page) || 1,
      limit: parseInt(route.query.limit) || 10,

      video_id: parseInt(route.query.video_id) || null,
      order_by_video_id: route.query.order_by_video_id || null,

      name: route.query.name || null,
      order_by_name: route.query.order_by_name || null,

      description: route.query.description || null,
      order_by_description: route.query.order_by_description || null,

      staff_comments: route.query.staff_comments || null,
      order_by_staff_comments: route.query.order_by_staff_comments || null,

      family_comments: route.query.family_comments || null,
      order_by_family_comments: route.query.order_by_family_comments || null,

      tag_id: parseInt(route.query.tag_id) || null,
      order_by_created_at: route.query.order_by_order_by_created_at || null,

      is_coach_seen: route.query.is_coach_seen
        ? parseInt(route.query.is_coach_seen)
        : null,
      is_evaluator_seen: route.query.is_evaluator_seen
        ? parseInt(route.query.is_evaluator_seen)
        : null,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-video-index" */ "@/views/client/evaluation-video/Index.vue"
      ),
  },
  {
    path: "evaluation-videos/upload",
    name: "ClientEvaluationVideoUpload",
    meta: {
      title: "Upload Evaluation Video",
      permission: UPLOAD_EVALUATION_VIDEO,
    },
    props: (route) => ({
      id: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-video-upload" */ "@/views/client/evaluation-video/Upload.vue"
      ),
  },
];

export default clientEvaluationVideoRoutes;
