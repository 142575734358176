<template>
  <div class="flex justify-end">
    <router-link
      class="px-4 py-2"
      :class="{
        border: !isLeft(index) && !isRight(index),
        'border-l border-t border-b': isLeft(index),
        'border-t border-b border-r': isRight(index),
        'text-base font-medium text-white bg-blue-500 hover:bg-blue-800':
          button === limit,
        'text-base font-medium text-black bg-white hover:bg-gray-100':
          button !== limit,
      }"
      v-for="(button, index) in buttons"
      :key="`button-${index}`"
      :to="generateLink(button)"
    >
      {{ button }}
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    limit: {
      type: Number,
      required: true,
    },
    to: {
      type: Object,
      required: true,
    },
    buttons: {
      type: Array,
      default: () => [10, 20, 50, 100],
    },
  },
  methods: {
    generateLink(limit) {
      return {
        ...this.to,
        query: {
          ...this.$route.query,
          ...{ limit, page: 1 },
        },
      };
    },
    isLeft(index) {
      return index === 0;
    },
    isRight(index) {
      return index === this.buttons.length - 1;
    },
  },
};
</script>

<style lang="scss" scoped></style>
