import { VIEW_ANY_EVALUATION_VIDEO_COMMENTS } from "@/rbac/permissions.js";

const evaluationVideoCommentRoutes = [
  {
    path: "/evaluation-video-comments/:evaluationVideoId/view",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "EvaluationVideoCommentIndex",
        meta: {
          title: "Evaluation Video Comments",
          permission: VIEW_ANY_EVALUATION_VIDEO_COMMENTS,
        },
        props: (route) => {
          return {
            evaluationVideoId: parseInt(route.params.evaluationVideoId),
            page: parseInt(route.query.page) || 1,
            limit: parseInt(route.query.limit) || 10,
          };
        },
        component: () =>
          import(
            /* webpackChunkName: "evaluation-video-comment-index" */ "@/views/system/evaluation-video-comment/Index.vue"
          ),
      },
    ],
  },
];

export default evaluationVideoCommentRoutes;
