import { LIMBO_STEPS } from "@/rbac/links.js";

import {
  CREATE_LIMBO_STEP,
  VIEW_LIMBO_STEP,
  UPDATE_LIMBO_STEP,
} from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_number || "asc";

  if (
    route.query.order_by_id ||
    route.query.order_by_title ||
    route.query.order_by_description
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    number: parseInt(route.query.number) || null,
    order_by_number: orderByDefault,

    title: route.query.title || null,
    order_by_title: route.query.order_by_title || null,

    description: route.query.description || null,
    order_by_description: route.query.order_by_description || null,
  };
}

const limboStepRoutes = [
  {
    path: "/limbo-steps",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "LimboStepIndex",
        meta: { title: "Limbo Steps", permission: LIMBO_STEPS },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "limbo-step-index" */ "@/views/system/limbo-step/Index.vue"
          ),
      },
      {
        path: "create",
        name: "LimboStepCreate",
        meta: { title: "Create Limbo Step", permission: CREATE_LIMBO_STEP },
        component: () =>
          import(
            /* webpackChunkName: "limbo-step-create" */ "@/views/system/limbo-step/Create.vue"
          ),
      },
      {
        path: ":id/view",
        name: "LimboStepView",
        meta: { title: "View Limbo Step", permission: VIEW_LIMBO_STEP },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "limbo-step-view" */ "@/views/system/limbo-step/View.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "LimboStepEdit",
        meta: { title: "Edit Limbo Step", permission: UPDATE_LIMBO_STEP },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "limbo-step-edit" */ "@/views/system/limbo-step/Edit.vue"
          ),
      },
    ],
  },
];

export default limboStepRoutes;
