import { CRON_EMAIL_LAYOUTS } from "@/rbac/links.js";
import {
  CREATE_CRON_EMAIL_LAYOUT,
  VIEW_CRON_EMAIL_LAYOUT,
  UPDATE_CRON_EMAIL_LAYOUT,
} from "@/rbac/permissions.js";

const cronEmailLayoutRoutes = [
  {
    path: "/cron-email-layouts",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "CronEmailLayoutIndex",
        meta: { title: "Cron Email Layouts", permission: CRON_EMAIL_LAYOUTS },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          from_day_id: parseInt(route.query.from_day_id) || null,
          order_by_from_day_id: route.query.order_by_from_day_id || null,

          from_month_id: parseInt(route.query.from_month_id) || null,
          order_by_from_month_name:
            route.query.order_by_from_month_name || null,

          to_day_id: parseInt(route.query.to_day_id) || null,
          order_by_to_day_id: route.query.order_by_to_day_id || null,

          to_month_id: parseInt(route.query.to_month_id) || null,
          order_by_to_month_name: route.query.order_by_to_month_name || null,

          old_layout_id: parseInt(route.query.old_layout_id) || null,
          order_by_old_layout_name:
            route.query.order_by_old_layout_name || null,

          new_layout_id: parseInt(route.query.new_layout_id) || null,
          order_by_new_layout_name:
            route.query.order_by_new_layout_name || null,

          order_by_created_at: route.query.order_by_created_at || null,
          order_by_updated_at: route.query.order_by_updated_at || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "cron-email-layou-index" */ "@/views/system/cron-email-layout/Index.vue"
          ),
      },
      {
        path: "create",
        name: "CronEmailLayoutCreate",
        meta: {
          title: "Create Cron Email Layout",
          permission: CREATE_CRON_EMAIL_LAYOUT,
        },
        component: () =>
          import(
            /* webpackChunkName: "cron-email-layout-create" */ "@/views/system/cron-email-layout/Create.vue"
          ),
      },
      {
        path: ":id/view",
        name: "CronEmailLayoutView",
        meta: {
          title: "View Cron Email Layout",
          permission: VIEW_CRON_EMAIL_LAYOUT,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "cron-email-layout-view" */ "@/views/system/cron-email-layout/View.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "CronEmailLayoutEdit",
        meta: {
          title: "Edit Cron Email Layout",
          permission: UPDATE_CRON_EMAIL_LAYOUT,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "cron-email-layout-edit" */ "@/views/system/cron-email-layout/Edit.vue"
          ),
      },
    ],
  },
];

export default cronEmailLayoutRoutes;
