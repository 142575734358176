import {
  CREATE_COURSE_CATEGORY_CONTENT,
  VIEW_COURSE_CATEGORY_CONTENT,
  UPDATE_COURSE_CATEGORY_CONTENT,
} from "@/rbac/permissions.js";

const courseCategoryContentContentRoutes = [
  {
    path: "course-category-contents/create",
    name: "CourseCategoryContentCreate",
    meta: {
      title: "Create Course Category Content",
      permission: CREATE_COURSE_CATEGORY_CONTENT,
    },
    props: (route) => ({
      id: parseInt(route.params.id),
    }),
    component: () =>
      import(
        /* webpackChunkName: "course-category-content-create" */ "@/views/course-category-content/Create.vue"
      ),
  },
  {
    path: "course-category-contents/:contentId",
    name: "CourseCategoryContentView",
    meta: {
      title: "View Course Category Content",
      permission: VIEW_COURSE_CATEGORY_CONTENT,
    },
    props: (route) => ({
      id: parseInt(route.params.id),
      contentId: parseInt(route.params.contentId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "course-category-content-view" */ "@/views/course-category-content/View.vue"
      ),
  },
  {
    path: "course-category-contents/:contentId/edit",
    name: "CourseCategoryContentEdit",
    meta: {
      title: "Edit Course Category Content",
      permission: UPDATE_COURSE_CATEGORY_CONTENT,
    },
    props: (route) => ({
      id: parseInt(route.params.id),
      contentId: parseInt(route.params.contentId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "course-category-content-edit" */ "@/views/course-category-content/Edit.vue"
      ),
  },
];

export default courseCategoryContentContentRoutes;
