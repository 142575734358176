import { PAPER_APPLICATION_FORMS_VIEW_ARCHIVE } from "@/rbac/links.js";

function props(route) {
  let orderByDefault = route.query.order_by_date || "desc";

  if (
    route.query.order_by_label ||
    route.query.order_by_url ||
    route.query.order_by_type_name
  ) {
    orderByDefault = null;
  }

  return {
    id: route.params.id,
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    order_by_date: orderByDefault,

    label: route.query.label || null,
    order_by_label: route.query.order_by_label || null,
  };
}

const clientPaperApplicationFormRoutes = [
  {
    path: "paper-application-forms",
    name: "ClientPaperApplicationFormIndex",
    meta: {
      title: "Paper Application Forms Archive",
      permission: PAPER_APPLICATION_FORMS_VIEW_ARCHIVE,
    },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "client-paper-application-form-index" */ "@/views/client/paper-application-form/Index.vue"
      ),
  },
];

export default clientPaperApplicationFormRoutes;
