import ErrorService from "@/services/ErrorService.js";

const errorMixin = {
  methods: {
    handleError(error) {
      this.notifications = [
        ...this.notifications,
        ...ErrorService.handle(error),
      ];
    },
  },
};

export { errorMixin };
