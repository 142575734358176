<template>
  <div
    class="rounded-2xl px-4 py-2 break-all whitespace-pre-line"
    :class="[
      note.user_id === model.user_id
        ? 'bg-gray-600 text-white'
        : 'bg-gray-200 text-gray-800',
    ]"
  >
    {{ note.note }}

    <div
      class="text-small"
      :class="[
        note.user_id === model.user_id
          ? 'text-right text-gray-300'
          : 'text-left text-gray-500',
      ]"
    >
      {{ createdAt }}
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    note: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createdAt() {
      return moment(this.note.created_at).format("lll");
    },
  },
};
</script>

<style lang="scss" scoped>
.text-small {
  font-size: 10px;
}
</style>
