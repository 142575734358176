import { DOCUMENT_VIEW_ARCHIVE } from "@/rbac/links.js";

function props(route) {
  let orderByDefault = route.query.order_by_date || "desc";

  if (
    route.query.order_by_label ||
    route.query.order_by_url ||
    route.query.order_by_type_name
  ) {
    orderByDefault = null;
  }

  return {
    id: route.params.id,
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    order_by_date: orderByDefault,

    label: route.query.label || null,
    order_by_label: route.query.order_by_label || null,

    url: route.query.url || null,
    order_by_url: route.query.order_by_url || null,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,
  };
}

const clientDocumentRoutes = [
  {
    path: "documents",
    name: "ClientDocumentIndex",
    meta: { title: "Document Archive", permission: DOCUMENT_VIEW_ARCHIVE },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "client-program-index" */ "@/views/client/document/Index.vue"
      ),
  },
];

export default clientDocumentRoutes;
