<template>
  <div class="relative">
    <BaseRequiredLabel :label="label" v-if="label" />

    <input
      v-bind="$attrs"
      :autofocus="autofocus"
      :class="[isInvalid ? invalidClass : activeClass]"
      :placeholder="placeholder ? placeholder : label"
      :value="search"
      @focus="$emit('focus')"
    />

    <div
      class="absolute mt-1 w-full z-10 rounded-md bg-white shadow-lg"
      v-if="show"
    >
      <ul
        tabindex="-1"
        role="listbox"
        aria-labelledby="listbox-label"
        aria-activedescendant="listbox-item-3"
        class="max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
      >
        <li
          role="option"
          class="text-gray-900 cursor-default select-none hover:bg-blue-500 hover:text-white relative py-2 pl-3 pr-9"
          v-for="item in activities"
          :key="item.id"
          @click="select(item)"
        >
          <div class="flex items-center">
            <span class="ml-3 block font-normal truncate">
              {{ item.name }}
            </span>
          </div>
        </li>
      </ul>
    </div>

    <BaseInputError :message="message" v-if="isInvalid" />
  </div>
</template>

<script>
let debounce = require("lodash.debounce");
import { inputMixin, requiredFormFieldMixin } from "@/mixins/form.js";
import { errorMixin } from "@/mixins/error.js";
import Activities from "@/graphql/queries/activity/all.gql";

export default {
  mixins: [errorMixin, inputMixin, requiredFormFieldMixin],
  data() {
    return {
      loading: false,
      skipQuery: true,
      show: false,

      search: "",
      activities: [],
    };
  },
  watch: {
    search: debounce(function () {
      this.activities = [];

      if (this.modelValue !== "") {
        this.show = true;
        this.skipQuery = false;
      }
    }, 500),
  },
  apollo: {
    activities: {
      query: Activities,
      variables() {
        return {
          filter: {
            name: this.modelValue,
            order_by_name: "asc",
          },
        };
      },
      update(data) {
        this.skipQuery = true;
        return data.activities;
      },
      skip() {
        return this.skipQuery;
      },
      error(error) {
        this.handleError(error);
      },
    },
  },
  methods: {
    select(item) {
      this.show = false;
      this.search = item.name;
      this.$emit("item-selected", item);
    },
  },
};
</script>

<style lang="scss" scoped></style>
