import { RE_EVALUATION_FORMS } from "@/rbac/links.js";

function props(route) {
  let orderByDefault = route.query.order_by_updated_at || "desc";

  if (
    route.query.order_by_client_id ||
    route.query.order_by_client_full_name ||
    route.query.order_by_family_full_name ||
    route.query.order_by_coach_full_name ||
    route.query.order_by_evaluator_full_name ||
    route.query.order_by_status_name ||
    route.query.order_by_created_at ||
    route.query.order_by_date
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    client_id: route.query.client_id || null,
    order_by_client_id: route.query.order_by_client_id || null,

    client_full_name: route.query.client_full_name || null,
    order_by_client_full_name: route.query.order_by_client_full_name || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    coach_id: parseInt(route.query.coach_id) || null,
    order_by_coach_full_name: route.query.order_by_coach_full_name || null,

    evaluator_id: parseInt(route.query.evaluator_id) || null,
    order_by_evaluator_full_name:
      route.query.order_by_evaluator_full_name || null,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    order_by_created_at: route.query.order_by_created_at || null,
    order_by_updated_at: orderByDefault,
    order_by_date: route.query.order_by_date || null,
  };
}

const reEvaluationRoutes = [
  {
    path: "/re-evaluations",
    component: () =>
      import(
        /* webpackChunkName: "layout-full-width" */ "@/views/layouts/FullWidth.vue"
      ),
    children: [
      {
        path: "",
        name: "ReEvaluationIndex",
        meta: { title: "Re-Evaluation Forms", permission: RE_EVALUATION_FORMS },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "re-evaluation-index" */ "@/views/re-evaluation/Index.vue"
          ),
      },
    ],
  },
];

export default reEvaluationRoutes;
