import courseRoutes from "@/router/modules/academy/course.js";
import courseCategoryRoutes from "@/router/modules/academy/category.js";
import courseActivityRoutes from "@/router/modules/academy/activity.js";
import courseIntroRoutes from "@/router/modules/academy/intro.js";

const academyCourseRoutes = [
  ...courseRoutes,
  ...courseCategoryRoutes,
  ...courseIntroRoutes,
  ...courseActivityRoutes,
];

export default academyCourseRoutes;
