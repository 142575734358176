<template>
  <div class="w-full bg-gray-100 h-screen">
    <div
      class="container flex items-center justify-center flex-1 h-full mx-auto text-center"
    >
      <div class="w-full max-w-lg">
        <h1 class="text-4xl">Network Error</h1>
        <h3 class="my-4">
          It looks like you are experiencing some network issues, please
        </h3>
        <h3 class="my-4">
          <a
            href="#"
            class="px-6 py-2 transition ease-in duration-200 uppercase rounded-full hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none"
            @click="$router.go(-1)"
          >
            click here
          </a>
        </h3>
        <h3>to try again</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      required: true,
      default: "page",
    },
  },
};
</script>
