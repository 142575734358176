import { TIME_ZONES } from "@/rbac/links.js";

const timeZoneRoutes = [
  {
    path: "/time-zones",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "TimeZoneIndex",
        meta: { title: "Time Zones", permission: TIME_ZONES },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,

          label: route.query.label || null,
          order_by_label: route.query.order_by_label || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "time-zone-index" */ "@/views/system/time-zone/Index.vue"
          ),
      },
    ],
  },
];

export default timeZoneRoutes;
