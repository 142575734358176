import { COURSE_ACTIVITIES } from "@/rbac/links.js";
import {
  CREATE_COURSE_ACTIVITY,
  SORT_COURSE_ACTIVITY,
  UPDATE_COURSE_ACTIVITY,
  VIEW_COURSE_ACTIVITY,
} from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_id ||
    route.query.order_by_name ||
    route.query.order_by_category_name ||
    route.query.order_by_is_active_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    category_id: parseInt(route.query.category_id) || null,
    order_by_category_name: route.query.order_by_category_name || null,

    is_active: route.query.is_active ? parseInt(route.query.is_active) : null,
    order_by_is_active_name: route.query.order_by_is_active_name || null,
  };
}

const courseActivityRoutes = [
  {
    path: "/course-activities",
    component: () =>
      import(
        /* webpackChunkName: "course-activity-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "CourseActivityIndex",
        meta: { title: "Course Activities", permission: COURSE_ACTIVITIES },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "course-activity-index" */ "@/views/course-activity/Index.vue"
          ),
      },
      {
        path: "create",
        name: "CourseActivityCreate",
        meta: {
          title: "Create Course Activity",
          permission: CREATE_COURSE_ACTIVITY,
        },
        component: () =>
          import(
            /* webpackChunkName: "course-activity-create" */ "@/views/course-activity/Create.vue"
          ),
      },
      {
        path: "sort",
        name: "CourseActivitySort",
        meta: {
          title: "Sort Course Activities",
          permission: SORT_COURSE_ACTIVITY,
        },
        component: () =>
          import(
            /* webpackChunkName: "course-activity-sort" */ "@/views/course-activity/Sort.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "CourseActivityEdit",
        meta: {
          title: "Edit Course Activity",
          permission: UPDATE_COURSE_ACTIVITY,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-activity-edit" */ "@/views/course-activity/Edit.vue"
          ),
      },
      {
        path: ":id/view",
        name: "CourseActivityView",
        meta: {
          title: "View Course Activity",
          permission: VIEW_COURSE_ACTIVITY,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-activity-view" */ "@/views/course-activity/View.vue"
          ),
      },
    ],
  },
];

export default courseActivityRoutes;
