const requiredModelValue = {
  modelValue: {
    type: [String, Number, Boolean],
    required: true,
  },
};

const notRequiredModelValue = {
  modelValue: {
    type: [String, Number, Boolean, Array, Object, Date],
    required: false,
  },
};

const formFields = {
  autofocus: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: "",
  },
  isInvalid: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  placeholder: {
    type: String,
    default: "",
  },
};

const requiredFormFieldMixin = {
  props: {
    ...formFields,
    ...requiredModelValue,
  },
};

const notRequiredFormFieldMixin = {
  props: {
    ...formFields,
    ...notRequiredModelValue,
  },
};

const SMALL = "sm";
const MEDIUM = "md";
const LARGE = "lg";

const sizeMixin = {
  props: {
    size: {
      type: String,
      default: MEDIUM,
    },
  },
  computed: {
    sizeClass() {
      let result = "";
      switch (this.size) {
        case SMALL:
          result = "py-1 px-2 sm:text-sm";
          break;
        case MEDIUM:
          result = "py-2 px-4";
          break;
        case LARGE:
          result = "py-3 px-5";
          break;
      }

      return result;
    },
  },
};

const inputMixin = {
  mixins: [sizeMixin],
  data() {
    return {
      baseClass:
        "rounded-sm border-transparent flex-1 appearance-none border border-gray-300 w-full bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent",
    };
  },
  computed: {
    disabledClass() {
      return `${this.activeClass} bg-gray-100`;
    },
    autosavedClass() {
      return `${this.activeClass} bg-green-100`;
    },
    activeClass() {
      return `${this.baseClass} ${this.sizeClass}`;
    },
    invalidClass() {
      return `ring-red-500 ring-2 ${this.baseClass} ${this.sizeClass}`;
    },
  },
};

const selectInputMixin = {
  mixins: [sizeMixin],
  props: {
    defaultOption: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      baseActiveClass:
        "rounded-sm border-transparent flex-1 border border-gray-300 w-full bg-white shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-blue-600 focus:border-transparent",
      baseInvalidClass:
        "ring-red-500 ring-2 rounded-sm border-transparent flex-1 border border-gray-300 w-full bg-white shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent",
    };
  },
  computed: {
    itemColor() {
      return this.modelValue === "" ? "text-gray-400" : "text-gray-700";
    },
    activeClass() {
      return `${this.baseActiveClass} ${this.itemColor} ${this.sizeClass}`;
    },
    invalidClass() {
      return `${this.baseInvalidClass} ${this.itemColor} ${this.sizeClass}`;
    },
  },
};

const passwordInputMixin = {
  data() {
    return {
      passwordType: "password",
      passwordConfirmationType: "password",
    };
  },
  methods: {
    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },
    togglePasswordConfirmationType() {
      if (this.passwordConfirmationType === "password") {
        this.passwordConfirmationType = "text";
      } else {
        this.passwordConfirmationType = "password";
      }
    },
  },
};

const customSelectInputMixin = {
  mixins: [selectInputMixin],
  data() {
    return {
      selectedItem: null,
      show: false,
    };
  },
  watch: {
    modelValue: function (val) {
      this.selectedItem = null;
      this.items.forEach((item) => {
        if (val === item.id) {
          this.selectedItem = item;
        }
      });
    },
  },
  methods: {
    isSelected(item) {
      return this.modelValue === item.id;
    },
    select(item) {
      this.show = false;
      this.selectedItem = item ? item : null;
      this.$emit("update:modelValue", item ? item.id : "");
    },
  },
};

export {
  requiredFormFieldMixin,
  notRequiredFormFieldMixin,
  inputMixin,
  sizeMixin,
  selectInputMixin,
  customSelectInputMixin,
  passwordInputMixin,
};
