<template>
  <div class="shadow sm:rounded-md">
    <div class="px-4 py-5 bg-white sm:p-6">
      <slot />
    </div>
    <div class="px-4 py-3 bg-gray-50 text-center sm:text-right">
      <div class="sm:inline-flex sm:justify-center">
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
