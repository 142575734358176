import { EMAIL_LOGS } from "@/rbac/links.js";
import { VIEW_EMAIL_LOG } from "@/rbac/permissions.js";

const emailLogRoutes = [
  {
    path: "/email-logs",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "EmailLogIndex",
        meta: { title: "Email Logs", permission: EMAIL_LOGS },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,
          from: route.query.from || null,
          to: route.query.to || null,
          subject: route.query.subject || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "email-log-index" */ "@/views/system/email-log/Index.vue"
          ),
      },
      {
        path: ":id/view",
        name: "EmailLogView",
        meta: { title: "View Email Log", permission: VIEW_EMAIL_LOG },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "email-log-view" */ "@/views/system/email-log/View.vue"
          ),
      },
    ],
  },
];

export default emailLogRoutes;
