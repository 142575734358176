import NotFound from "@/views/NotFound.vue";
import NetworkError from "@/views/NetworkError.vue";

const errorRoutes = [
  // in api call update:
  // check if 404
  // this.$router.push({name: "404Resource", params: {resource: "name of resource"}})
  // else this.$router.push({name: "NetworkError"})
  {
    path: "/404/:resource",
    name: "404Resource",
    component: NotFound,
    props: true,
  },
  {
    path: "/network-error",
    name: "NetworkError",
    component: NetworkError,
  },
  { path: "/:catchAll(.*)", name: "NotFound", component: NotFound },
];

export default errorRoutes;
