import { DEVELOPMENTAL_PROFILE_ITEMS } from "@/rbac/links.js";

import {
  CREATE_DEVELOPMENTAL_PROFILE_ITEM,
  SORT_DEVELOPMENTAL_PROFILE_ITEM,
  UPDATE_DEVELOPMENTAL_PROFILE_ITEM,
  VIEW_DEVELOPMENTAL_PROFILE_ITEM,
} from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_id ||
    route.query.order_by_name ||
    route.query.order_by_description ||
    route.query.order_by_category_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    description: route.query.description || null,
    order_by_description: route.query.order_by_description || null,

    category_id: parseInt(route.query.category_id) || null,
    order_by_category_name: route.query.order_by_category_name || null,
  };
}

const developmentalProfileItemRoutes = [
  {
    path: "/developmental-profile-items",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "DevelopmentalProfileItemIndex",
        meta: {
          title: "Developmental Profile Items",
          permission: DEVELOPMENTAL_PROFILE_ITEMS,
        },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "developmental-profile-item-index" */ "@/views/system/developmental-profile-item/Index.vue"
          ),
      },
      {
        path: "create",
        name: "DevelopmentalProfileItemCreate",
        meta: {
          title: "Create Developmental Profile Item",
          permission: CREATE_DEVELOPMENTAL_PROFILE_ITEM,
        },
        component: () =>
          import(
            /* webpackChunkName: "developmental-profile-item-create" */ "@/views/system/developmental-profile-item/Create.vue"
          ),
      },
      {
        path: "sort",
        name: "DevelopmentalProfileItemSort",
        meta: {
          title: "Sort Developmental Profile Items",
          permission: SORT_DEVELOPMENTAL_PROFILE_ITEM,
        },
        component: () =>
          import(
            /* webpackChunkName: "developmental-profile-item-sort" */ "@/views/system/developmental-profile-item/Sort.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "DevelopmentalProfileItemEdit",
        meta: {
          title: "Edit Developmental Profile Item",
          permission: UPDATE_DEVELOPMENTAL_PROFILE_ITEM,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "developmental-profile-item-edit" */ "@/views/system/developmental-profile-item/Edit.vue"
          ),
      },
      {
        path: ":id/view",
        name: "DevelopmentalProfileItemView",
        meta: {
          title: "View Developmental Profile Item",
          permission: VIEW_DEVELOPMENTAL_PROFILE_ITEM,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "developmental-profile-item-view" */ "@/views/system/developmental-profile-item/View.vue"
          ),
      },
    ],
  },
];

export default developmentalProfileItemRoutes;
