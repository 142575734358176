import { APPLICATION_FORM_COUNTRY_INCOME_FEES } from "@/rbac/links.js";
import { SORT_APPLICATION_FORM_COUNTRY_INCOME_FEE } from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_country_name ||
    route.query.order_by_income_name ||
    route.query.order_by_fee_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 20,

    country_id: parseInt(route.query.country_id) || null,
    order_by_country_name: route.query.order_by_country_name || null,

    income_id: parseInt(route.query.income_id) || null,
    order_by_income_name: route.query.order_by_income_name || null,

    fee_id: parseInt(route.query.fee_id) || null,
    order_by_fee_name: route.query.order_by_fee_name || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,
  };
}

const applicationFormCountryIncomeFeeRoutes = [
  {
    path: "/application-form-country-income-fees",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ApplicationFormCountryIncomeFeeIndex",
        meta: {
          title: "Application Form Country Income Fees",
          permission: APPLICATION_FORM_COUNTRY_INCOME_FEES,
        },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "application-form-country-income-fee-index" */ "@/views/system/application-form-country-income-fee/Index.vue"
          ),
      },
      {
        path: "sort",
        name: "ApplicationFormCountryIncomeFeeSort",
        meta: {
          title: "Sort Application Form Country Income Fees",
          permission: SORT_APPLICATION_FORM_COUNTRY_INCOME_FEE,
        },
        component: () =>
          import(
            /* webpackChunkName: "application-form-country-income-fee-index" */ "@/views/system/application-form-country-income-fee/Sort.vue"
          ),
      },
    ],
  },
];

export default applicationFormCountryIncomeFeeRoutes;
