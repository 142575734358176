import { createRouter, createWebHistory } from "vue-router";
import DashboardLayout from "@/views/layouts/DashboardLayout.vue";
import AuthService from "@/services/AuthService";
import { nextTick } from "vue";
import profileRoutes from "@/router/modules/profile.js";
import staffRoutes from "@/router/modules/staff.js";
import familyRoutes from "@/router/modules/family.js";
import clientRoutes from "@/router/modules/client/index.js";
import activityRoutes from "@/router/modules/activity.js";
import courseRoutes from "@/router/modules/course/index.js";
import academyCourseRoutes from "@/router/modules/academy/index.js";
import systemFormRoutes from "@/router/modules/system/index.js";
import reEvaluationRoutes from "@/router/modules/re-evaluation.js";
import applicationFormRoutes from "@/router/modules/application-form.js";
import evaluationVideoRoutes from "@/router/modules/evaluation-video.js";
import reportRoutes from "@/router/modules/report.js";
import rbacRoutes from "@/router/modules/rbac/index.js";

import dropdownRoutes from "@/router/modules/dropdown.js";
import errorRoutes from "@/router/modules/error.js";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: DashboardLayout,
    meta: { requiresAuth: true },
    children: [
      ...profileRoutes,
      ...familyRoutes,
      ...clientRoutes,
      ...staffRoutes,
      ...dropdownRoutes,
      ...activityRoutes,
      ...courseRoutes,
      ...academyCourseRoutes,
      ...reEvaluationRoutes,
      ...applicationFormRoutes,
      ...evaluationVideoRoutes,
      ...systemFormRoutes,
      ...reportRoutes,
      ...rbacRoutes,
      {
        path: "/forbidden",
        name: "Forbidden",
        meta: { title: "Forbidden" },
        component: () =>
          import(/* webpackChunkName: "forbidden" */ "@/views/Forbidden.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: { title: "Login" },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/login-as-staff",
    name: "LoginAsStaff",
    meta: { title: "Login as Staff" },
    props: (route) => ({
      staff_username: route.query.staff_username,
    }),
    component: () =>
      import(
        /* webpackChunkName: "login-as-staff" */ "@/views/LoginAsStaff.vue"
      ),
  },
  {
    path: "/reset-password",
    name: "SendResetPasswordLink",
    meta: { title: "Send Reset Password Link" },
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/SendResetPasswordLink.vue"
      ),
  },
  {
    path: "/reset-password/:token",
    name: "ResetPassword",
    meta: { title: "Reset Password" },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "@/views/ResetPassword.vue"
      ),
  },
  ...errorRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // linkActiveClass: "active",
  linkExactActiveClass: "active",
});

let authService = new AuthService();

const DEFAULT_TITLE = "NACD Staff Portal";

router.beforeEach((to, from, next) => {
  nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    authService.isGuest()
  ) {
    next({ name: "Login" });
  } else if (
    !authService.isGuest() &&
    (to.name === "Login" ||
      to.name === "ResetPassword" ||
      to.name === "SendResetPasswordLink")
  ) {
    next(from);
  } else {
    if (hasNoPermission(to)) {
      next({ name: "Forbidden" });
    } else {
      next();
    }
  }
});

function hasNoPermission(to) {
  return (
    authService.isAuth() &&
    to.name !== "Forbidden" &&
    !authService.hasPermission(to.meta.permission)
  );
}

export default router;
