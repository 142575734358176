import { SUCCESS, ERROR, INFO } from "@/constants";

const notificationMixin = {
  data() {
    return {
      notifications: [],
    };
  },
  methods: {
    addInfoNotification(message) {
      this.addCustomNotification(INFO, message);
    },
    addErrorNotification(message) {
      this.addCustomNotification(ERROR, message);
    },
    addSuccessfulNotification(message) {
      this.addCustomNotification(SUCCESS, message);
    },
    addCustomNotification(type, message) {
      let notification = {
        id: Date.now() + Math.random() * 10000,
        type: type,
        message: message,
      };

      this.addNotification(notification);
    },
    addNotification(notification) {
      this.notifications.push(notification);
    },
    clearNotifications() {
      this.notifications = [];
    },
    closeNotification(notification) {
      this.notifications = this.notifications.filter(
        (item) => item.id !== notification.id
      );
    },
  },
};

export { notificationMixin };
