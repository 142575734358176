import { APPLICATION_FORM_PAGES } from "@/rbac/links.js";

import {
  CREATE_APPLICATION_FORM_PAGE,
  VIEW_APPLICATION_FORM_PAGE,
  UPDATE_APPLICATION_FORM_PAGE,
} from "@/rbac/permissions.js";

const applicationFormPageRoutes = [
  {
    path: "/application-form-pages",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ApplicationFormPageIndex",
        meta: {
          title: "Application Form Pages",
          permission: APPLICATION_FORM_PAGES,
        },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "application-form-page-index" */ "@/views/system/application-form-page/Index.vue"
          ),
      },
      {
        path: "create",
        name: "ApplicationFormPageCreate",
        meta: {
          title: "Create Application Form Page",
          permission: CREATE_APPLICATION_FORM_PAGE,
        },
        component: () =>
          import(
            /* webpackChunkName: "application-form-page-create" */ "@/views/system/application-form-page/Create.vue"
          ),
      },
      {
        path: ":id/view",
        name: "ApplicationFormPageView",
        meta: {
          title: "View Application Form Page",
          permission: VIEW_APPLICATION_FORM_PAGE,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "application-form-page-view" */ "@/views/system/application-form-page/View.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "ApplicationFormPageEdit",
        meta: {
          title: "Edit Application Form Page",
          permission: UPDATE_APPLICATION_FORM_PAGE,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "application-form-page-edit" */ "@/views/system/application-form-page/Edit.vue"
          ),
      },
    ],
  },
];

export default applicationFormPageRoutes;
