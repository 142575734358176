<template>
  <span :class="[orderBy !== null ? 'text-gray-800 font-bold' : '']">
    {{ label }}
  </span>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    orderBy: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
