import { NO_PROFILE_IMAGE } from "@/constants";

const staffPhotoMixin = {
  computed: {
    photo() {
      return this.staff && this.staff.photo
        ? this.staff.photo
        : NO_PROFILE_IMAGE;
    },
  },
};

const modelPhotoMixin = {
  computed: {
    photo() {
      return this.model && this.model.photo
        ? this.model.photo
        : NO_PROFILE_IMAGE;
    },
  },
};

export { staffPhotoMixin, modelPhotoMixin };
