<template>
  <BaseRequiredLabel :label="label" v-if="label" />

  <select
    v-bind="{
      ...$attrs,
      onChange: ($event) => {
        $emit('update:modelValue', $event.target.value);
      },
      onFocus: ($event) => {
        $emit('focus');
      },
    }"
    :class="[isInvalid ? invalidClass : activeClass]"
    :value="modelValue"
  >
    <option value="">{{ defaultOption }}</option>
    <option
      v-for="item in items"
      :key="item.id"
      :value="item.id"
      :selected="item.id === modelValue"
    >
      {{ item.name }}
    </option>
  </select>

  <BaseInputError :message="message" v-if="isInvalid" />
</template>

<script>
import { selectInputMixin, requiredFormFieldMixin } from "@/mixins/form.js";

export default {
  mixins: [selectInputMixin, requiredFormFieldMixin],
};
</script>

<style lang="scss" scoped></style>
