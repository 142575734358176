import { ANNOUNCEMENTS } from "@/rbac/links.js";

import {
  CREATE_ANNOUNCEMENT,
  VIEW_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  SORT_ANNOUNCEMENT,
} from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_id ||
    route.query.order_by_name ||
    route.query.order_by_description ||
    route.query.order_by_type_name ||
    route.query.order_by_message_type_name ||
    route.query.order_by_is_active_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    description: route.query.description || null,
    order_by_description: route.query.order_by_description || null,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,

    message_type_id: parseInt(route.query.message_type_id) || null,
    order_by_message_type_name: route.query.order_by_message_type_name || null,

    is_active: route.query.is_active ? parseInt(route.query.is_active) : null,
    order_by_is_active_name: route.query.order_by_is_active_name || null,
  };
}
const announcementRoutes = [
  {
    path: "/announcements",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "AnnouncementIndex",
        meta: { title: "Announcements", permission: ANNOUNCEMENTS },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "announcement-index" */ "@/views/system/announcement/Index.vue"
          ),
      },
      {
        path: "create",
        name: "AnnouncementCreate",
        meta: { title: "Create Announcement", permission: CREATE_ANNOUNCEMENT },
        component: () =>
          import(
            /* webpackChunkName: "announcement-create" */ "@/views/system/announcement/Create.vue"
          ),
      },
      {
        path: "sort",
        name: "AnnouncementSort",
        meta: { title: "Sort Announcements", permission: SORT_ANNOUNCEMENT },
        component: () =>
          import(
            /* webpackChunkName: "announcement-create" */ "@/views/system/announcement/Sort.vue"
          ),
      },
      {
        path: ":id/view",
        name: "AnnouncementView",
        meta: { title: "View Announcement", permission: VIEW_ANNOUNCEMENT },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "announcement-view" */ "@/views/system/announcement/View.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "AnnouncementEdit",
        meta: { title: "Edit Announcement", permission: UPDATE_ANNOUNCEMENT },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "announcement-edit" */ "@/views/system/announcement/Edit.vue"
          ),
      },
    ],
  },
];

export default announcementRoutes;
