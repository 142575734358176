import { COURSE_INTRO_ACTIVITIES } from "@/rbac/links.js";
import {
  CREATE_COURSE_INTRO_ACTIVITY,
  UPDATE_COURSE_INTRO_ACTIVITY,
  VIEW_COURSE_INTRO_ACTIVITY,
} from "@/rbac/permissions.js";

const courseIntroActivityRoutes = [
  {
    path: "/course-intro-activities",
    component: () =>
      import(
        /* webpackChunkName: "course-intro-activity-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "CourseIntroActivityIndex",
        meta: {
          title: "Course Intro Activities",
          permission: COURSE_INTRO_ACTIVITIES,
        },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          course_id: parseInt(route.query.course_id) || null,
          order_by_course_name: route.query.order_by_course_name || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-intro-activity-index" */ "@/views/course-intro-activity/Index.vue"
          ),
      },
      {
        path: "create",
        name: "CourseIntroActivityCreate",
        meta: {
          title: "Create Course Intro Activity",
          permission: CREATE_COURSE_INTRO_ACTIVITY,
        },
        component: () =>
          import(
            /* webpackChunkName: "course-intro-activity-create" */ "@/views/course-intro-activity/Create.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "CourseIntroActivityEdit",
        meta: {
          title: "Edit Course Intro Activity",
          permission: UPDATE_COURSE_INTRO_ACTIVITY,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-intro-activity-edit" */ "@/views/course-intro-activity/Edit.vue"
          ),
      },
      {
        path: ":id/view",
        name: "CourseIntroActivityView",
        meta: {
          title: "View Course Intro Activity",
          permission: VIEW_COURSE_INTRO_ACTIVITY,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-intro-activity-view" */ "@/views/course-intro-activity/View.vue"
          ),
      },
    ],
  },
];

export default courseIntroActivityRoutes;
