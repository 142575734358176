import { COURSE_CATEGORIES } from "@/rbac/links.js";
import {
  CREATE_COURSE_CATEGORY,
  SORT_COURSE_CATEGORY,
  VIEW_COURSE_CATEGORY,
  UPDATE_COURSE_CATEGORY,
} from "@/rbac/permissions.js";

import courseCategoryContentContentRoutes from "@/router/modules/course/category-content.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (
    route.query.order_by_id ||
    route.query.order_by_name ||
    route.query.order_by_description ||
    route.query.order_by_course_name ||
    route.query.order_by_is_active_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,

    description: route.query.description || null,
    order_by_description: route.query.order_by_description || null,

    course_id: parseInt(route.query.course_id) || null,
    order_by_course_name: route.query.order_by_course_name || null,

    is_active: route.query.is_active ? parseInt(route.query.is_active) : null,
    order_by_is_active_name: route.query.order_by_is_active_name || null,
  };
}

const courseCategoryRoutes = [
  {
    path: "/course-categories",
    component: () =>
      import(
        /* webpackChunkName: "course-category-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "CourseCategoryIndex",
        meta: { title: "Course Categories", permission: COURSE_CATEGORIES },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "course-category-index" */ "@/views/course-category/Index.vue"
          ),
      },
      {
        path: "create",
        name: "CourseCategoryCreate",
        meta: {
          title: "Create Course Category",
          permission: CREATE_COURSE_CATEGORY,
        },
        component: () =>
          import(
            /* webpackChunkName: "course-category-create" */ "@/views/course-category/Create.vue"
          ),
      },
      {
        path: "sort",
        name: "CourseCategorySort",
        meta: {
          title: "Sort Course Categories",
          permission: SORT_COURSE_CATEGORY,
        },
        component: () =>
          import(
            /* webpackChunkName: "course-category-sort" */ "@/views/course-category/Sort.vue"
          ),
      },
    ],
  },
  {
    path: "/course-categories/:id",
    props: (route) => ({
      id: parseInt(route.params.id),
    }),
    component: () =>
      import(
        /* webpackChunkName: "course-category-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "CourseCategoryView",
        meta: {
          title: "View Course Category",
          permission: VIEW_COURSE_CATEGORY,
        },
        props: (route) => ({
          id: parseInt(route.params.id),

          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          order: parseInt(route.query.order) || null,
          order_by_order: route.query.order_by_order || null,

          tag: route.query.tag || null,
          order_by_tag: route.query.order_by_tag || null,

          content: route.query.content || null,
          order_by_content: route.query.order_by_content || null,

          class_name: route.query.class_name || null,
          order_by_class: route.query.order_by_class || null,

          attribute: route.query.attribute || null,
          order_by_attribute: route.query.order_by_attribute || null,

          attribute_value: route.query.attribute_value || null,
          order_by_attribute_value:
            route.query.order_by_attribute_value || null,

          type: route.query.type || null,
          order_by_type: route.query.order_by_type || null,

          size: route.query.size || null,
          order_by_size: route.query.order_by_size || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-category-view" */ "@/views/course-category/View.vue"
          ),
      },
      {
        path: "edit",
        name: "CourseCategoryEdit",
        meta: {
          title: "Edit Course Category",
          permission: UPDATE_COURSE_CATEGORY,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "course-category-edit" */ "@/views/course-category/Edit.vue"
          ),
      },
      ...courseCategoryContentContentRoutes,
    ],
  },
];

export default courseCategoryRoutes;
