<template>
  <div
    class="fixed right-8 bottom-6 sm:right-14 sm:bottom-14 w-10/12 sm:w-96 z-20"
    v-if="show"
  >
    <div
      class="flex items-center justify-center text-white p-4 rounded-t-xl"
      :class="themeClass"
    >
      <img
        v-show="isLoaded"
        :src="photo"
        alt="Profile Image"
        class="h-10 w-10 rounded-full object-cover"
        @load="onImageLoad"
      />
      <section
        v-show="!isLoaded"
        class="h-10 w-10 rounded-full bg-gray-300 animate-pulse"
      ></section>
    </div>

    <div class="p-4 bg-white space-y-2 text-sm border border-gray-300">
      <div
        class="overflow-y-scroll space-y-2 h-40 sm:h-60 py-4 flex flex-col"
        ref="chat"
      >
        <BaseAlertContainer
          :notifications="notifications"
          @close="closeNotification"
          v-if="notifications.length > 0"
        />

        <BaseButton
          name="secondary"
          type="button"
          class="text-sm"
          v-if="userNotesPagination.has_more_pages"
          :disabled="loadingMore"
          :class="{ disabled: loadingMore }"
          @click="loadMore"
        >
          <BaseLoader color="gray" v-if="loadingMore" />
          <span v-else>Load More...</span>
        </BaseButton>

        <div
          v-for="note in userNotes"
          :key="note.id"
          class="inline-flex"
          :class="[
            note.user_id === model.user_id
              ? 'place-self-end'
              : 'place-self-start',
          ]"
        >
          <FeedbackMessage :note="note" :model="model" />
        </div>
      </div>

      <BaseLine />

      <BaseRequiredTextarea
        :autofocus="true"
        placeholder="Enter your note..."
        :isInvalid="validationService.has('note')"
        :message="validationService.get('note')"
        v-model="form.note"
        @focus="validationService.clear()"
      />

      <BaseButton
        name="link"
        type="button"
        class="inline-flex"
        :disabled="loading"
        :class="{ disabled: loading }"
        @click="submit"
      >
        <template v-slot:icon>
          <BaseLoader color="gray mr-2" v-if="loading" />

          <BasePlaneIcon class="h-5 w-5 mr-2" v-if="!loading" />
        </template>
        Send
      </BaseButton>
    </div>
  </div>

  <div class="fixed right-2 bottom-2 sm:right-5 sm:bottom-6 z-20">
    <button
      class="flex items-center p-4 uppercase rounded-full bg-green-600 hover:bg-green-700 text-white border-2 border-green-700 focus:outline-none"
      @click="toggle"
    >
      <BasePlusIcon class="h-5 w-5 sm:h-10 sm:w-10" v-if="!show" />
      <BaseMinusIcon class="h-5 w-5 sm:h-10 sm:w-10" v-if="show" />
    </button>
  </div>
</template>

<script>
import UserNotes from "@/graphql/queries/note/userNotes.gql";
import CreateNote from "@/graphql/mutations/note/create.gql";
import ValidationService from "@/services/ValidationService.js";
import FeedbackMessage from "@/components/misc/FeedbackMessage.vue";

import { modelPhotoMixin } from "@/mixins/staff.js";
import { nextTick } from "vue";
import { errorMixin } from "@/mixins/error.js";
import { notificationMixin } from "@/mixins/notification.js";
import { mapGetters } from "vuex";

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  mixins: [modelPhotoMixin, errorMixin, notificationMixin],
  components: { FeedbackMessage },
  data() {
    return {
      isLoaded: false,
      loadingMore: false,
      loading: false,
      show: false,
      form: this.createForm(),
      validationService: new ValidationService({
        note: null,
      }),

      limit: 10,
      page: 1,
      userNotes: [],
      userNotesPagination: {},
    };
  },
  apollo: {
    userNotes: {
      query: UserNotes,
      fetchPolicy: "no-cache",
      variables() {
        return this.variablesObj();
      },
      error(error) {
        this.handleError(error);
      },
      update(data) {
        this.userNotesPagination = data.userNotesPagination;
        return [...data.userNotes.reverse(), ...this.userNotes];
      },
    },
  },
  methods: {
    onImageLoad() {
      this.isLoaded = true;
    },
    loadMore() {
      this.clearNotifications();

      this.page += 1;

      this.loadingMore = true;

      this.$apollo.queries.userNotes
        .refetch()
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => {
          this.loadingMore = false;
        });
    },
    variablesObj() {
      return {
        limit: this.limit,
        page: this.page,
        filter: {
          order_by_created_at: "desc",
        },
      };
    },
    createForm() {
      return {
        note: "",
      };
    },
    toggle() {
      this.show = !this.show;

      nextTick(() => {
        if (this.show) {
          this.scrollToBottom();
        }
      });
    },
    scrollToBottom() {
      let chat = this.$refs.chat;

      chat.scrollTo({
        left: 0,
        top: chat.scrollHeight,
      });
    },
    submit() {
      if (this.isValid()) {
        this.loading = true;

        this.$apollo
          .mutate({
            mutation: CreateNote,
            variables: {
              model: {
                note: this.form.note,
                path: window.location.href,
              },
            },
          })
          .then((response) => {
            this.userNotes.push(response.data.createNote);

            this.form = this.createForm();

            nextTick(() => {
              this.scrollToBottom();
            });
          })
          .catch((error) => {
            this.handleError(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    isValid() {
      if (this.form.note === "") {
        this.validationService.set("note", "Note cannot be blank");
      }

      return this.validationService.hasNoErrors();
    },
  },
  computed: {
    ...mapGetters("user", ["isLight", "isDark", "isColorful"]),
    themeClass() {
      let result = "";
      if (this.isDark) {
        result += "bg-slate-800";
      }
      if (this.isLight) {
        result += "bg-orange";
      }
      if (this.isColorful) {
        result += "bg-pink";
      }

      return result;
    },
    messages() {
      return this.userNotes.slice().reverse();
    },
  },
};
</script>

<style lang="scss" scoped></style>
