<template>
  <label class="flex items-center space-x-1">
    <input
      v-bind="$attrs"
      type="checkbox"
      class="form-tick appearance-none bg-white bg-check h-4 w-4 border border-gray-300 rounded-sm checked:bg-blue-500 checked:border-transparent focus:outline-none"
      :checked="modelValue"
      @change="$emit('update:modelValue', $event.target.checked)"
    />
    <label class="text-gray-700 font-normal" v-if="label">
      {{ label }}
    </label>
  </label>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Boolean,
      defalt: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-check {
  background-image: url("/icons/check.svg") !important;
}
.checked\:border-transparent:checked {
  border-color: transparent !important;
}
.checked\:bg-blue-500:checked {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(59, 130, 246, var(--tw-bg-opacity)) !important;
}
// input[type="checkbox"]:checked {
//   background-color: red;
//   /* or whatever styles you want depending on your design */
//   /* be as obvious as possible that it's a checkbox and that it's checked! */
// }
</style>
