<template>
  <div class="flex flex-col">
    <BaseUpIcon
      :class="[orderBy === 'asc' ? 'text-gray-800' : '', baseClass]"
      @click="sort('asc')"
    />

    <BaseDownIcon
      :class="[orderBy === 'desc' ? 'text-gray-800' : '', baseClass]"
      @click="sort('desc')"
    />
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: String,
      required: true,
    },
    orderBy: {
      type: String,
      default: null,
    },
    newQuery: {
      type: Object,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      baseClass: "h-3 w-3 cursor-pointer hover:text-gray-800",
    };
  },
  methods: {
    sort(direction) {
      let newQuery = this.newQuery;

      newQuery[`order_by_${this.field}`] = direction;

      this.$router.push({
        name: this.routeName,
        query: {
          ...this.$route.query,
          ...newQuery,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
