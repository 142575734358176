import clientProgramRoutes from "@/router/modules/client/program.js";
import clientEvaluationVideoRoutes from "@/router/modules/client/evaluation-video.js";
import clientDocumentRoutes from "@/router/modules/client/document.js";
import clientPaperApplicationFormRoutes from "@/router/modules/client/paper-application-form.js";
import clientEvaluationRoutes from "@/router/modules/client/evaluation.js";
import clientReEvaluationRoutes from "@/router/modules/client/re-evaluation.js";
import clientApplicationFormRoutes from "@/router/modules/client/application-form.js";

import {
  MANAGE_CLIENTS,
  MY_CLIENTS,
  CLIENT_SCHEDULING,
  UNASSIGNED_CLIENTS,
  EXPORT_CLIENTS,
  VIEW_CLIENT_PROFILE,
  EDIT_CLIENT_PROFILE,
} from "@/rbac/links.js";

function props(route) {
  let orderByDefault = route.query.order_by_last_evaluation_date || "desc";

  if (
    route.query.order_by_id ||
    route.query.order_by_family_full_name ||
    route.query.order_by_first_name ||
    route.query.order_by_last_name ||
    route.query.order_by_coach_full_name ||
    route.query.order_by_evaluator_full_name ||
    route.query.order_by_chapter_name ||
    route.query.order_by_client_status_name ||
    route.query.order_by_program_code_1_name ||
    route.query.order_by_is_homeschooled_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: route.query.id || null,
    order_by_id: route.query.order_by_id || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    first_name: route.query.first_name || null,
    order_by_first_name: route.query.order_by_first_name || null,

    last_name: route.query.last_name || null,
    order_by_last_name: route.query.order_by_last_name || null,

    coach_id: parseInt(route.query.coach_id) || null,
    order_by_coach_full_name: route.query.order_by_coach_full_name || null,

    evaluator_id: parseInt(route.query.evaluator_id) || null,
    order_by_evaluator_full_name:
      route.query.order_by_evaluator_full_name || null,

    chapter_id: parseInt(route.query.chapter_id) || null,
    order_by_chapter_name: route.query.order_by_chapter_name || null,

    client_status_id: parseInt(route.query.client_status_id) || null,
    order_by_client_status_name:
      route.query.order_by_client_status_name || null,

    program_code_1_id: parseInt(route.query.program_code_1_id) || null,
    order_by_program_code_1_name:
      route.query.order_by_program_code_1_name || null,

    is_homeschooled: route.query.is_homeschooled
      ? parseInt(route.query.is_homeschooled)
      : null,

    order_by_is_homeschooled_name:
      route.query.order_by_is_homeschooled_name || null,
    order_by_last_evaluation_date: orderByDefault,
  };
}

function unassignedProps(route) {
  let orderByDefault = route.query.order_by_created_at || "desc";

  if (
    route.query.order_by_id ||
    route.query.order_by_family_full_name ||
    route.query.order_by_first_name ||
    route.query.order_by_last_name ||
    route.query.order_by_coach_full_name ||
    route.query.order_by_evaluator_full_name ||
    route.query.order_by_chapter_name ||
    route.query.order_by_client_status_name
  ) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: route.query.id || null,
    order_by_id: route.query.order_by_id || null,

    family_full_name: route.query.family_full_name || null,
    order_by_family_full_name: route.query.order_by_family_full_name || null,

    first_name: route.query.first_name || null,
    order_by_first_name: route.query.order_by_first_name || null,

    last_name: route.query.last_name || null,
    order_by_last_name: route.query.order_by_last_name || null,

    coach_id: parseInt(route.query.coach_id) || null,
    order_by_coach_full_name: route.query.order_by_coach_full_name || null,

    evaluator_id: parseInt(route.query.evaluator_id) || null,
    order_by_evaluator_full_name:
      route.query.order_by_evaluator_full_name || null,
    chapter_id: parseInt(route.query.chapter_id) || null,
    order_by_chapter_name: route.query.order_by_chapter_name || null,
    client_status_id: parseInt(route.query.client_status_id) || null,
    order_by_client_status_name:
      route.query.order_by_client_status_name || null,

    order_by_created_at: orderByDefault,
  };
}

const clientRoutes = [
  {
    path: "/clients",
    name: "ClientIndex",
    meta: { title: "Manage Clients", permission: MANAGE_CLIENTS },
    props: (route) => props(route),
    component: () =>
      import(/* webpackChunkName: "client-index" */ "@/views/client/Index.vue"),
  },
  {
    path: "my-clients",
    name: "MyClientIndex",
    meta: { title: "My Clients", permission: MY_CLIENTS },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "my-client-index" */ "@/views/client/MyClients.vue"
      ),
  },
  {
    path: "client-scheduling",
    name: "ClientScheduleIndex",
    meta: { title: "Client Scheduling", permission: CLIENT_SCHEDULING },
    props: (route) => ({
      page: parseInt(route.query.page) || 1,
      limit: parseInt(route.query.limit) || 10,
      id: route.query.id || null,
      full_name: route.query.full_name || null,
      family_full_name: route.query.family_full_name || null,
      client_status_id: parseInt(route.query.client_status_id) || null,
      type_id: parseInt(route.query.type_id) || null,
      evaluator_id: parseInt(route.query.evaluator_id) || null,
    }),
    component: () =>
      import(
        /* webpackChunkName: "schedule-index" */ "@/views/client/Schedule.vue"
      ),
  },
  {
    path: "unassigned-clients",
    name: "UnassignedClientIndex",
    meta: { title: "Unassigned Clients", permission: UNASSIGNED_CLIENTS },
    props: (route) => unassignedProps(route),
    component: () =>
      import(
        /* webpackChunkName: "unassigned-client-index" */ "@/views/client/UnassignedClients.vue"
      ),
  },
  {
    path: "/clients/:id",
    name: "ClientLayout",
    props: (route) => ({
      id: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-layout" */ "@/views/client/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ClientView",
        meta: { title: "View Client", permission: VIEW_CLIENT_PROFILE },
        props: (route) => ({
          id: route.params.id,
        }),
        component: () =>
          import(
            /* webpackChunkName: "client-view" */ "@/views/client/View.vue"
          ),
      },
      {
        path: "edit",
        name: "ClientEdit",
        meta: { title: "Edit Client", permission: EDIT_CLIENT_PROFILE },
        props: (route) => ({
          id: route.params.id,
        }),
        component: () =>
          import(
            /* webpackChunkName: "client-edit" */ "@/views/client/Edit.vue"
          ),
      },
      ...clientEvaluationVideoRoutes,
      ...clientProgramRoutes,
      ...clientDocumentRoutes,
      ...clientPaperApplicationFormRoutes,
      ...clientEvaluationRoutes,
      ...clientReEvaluationRoutes,
      ...clientApplicationFormRoutes,
    ],
  },
  {
    path: "/clients/export",
    name: "ClientExport",
    meta: { title: "Export Clients", permission: EXPORT_CLIENTS },
    component: () =>
      import(
        /* webpackChunkName: "client-export" */ "@/views/client/Export.vue"
      ),
  },
];

export default clientRoutes;
