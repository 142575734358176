<template>
  <div>
    <BaseAlertContainer
      :notifications="notifications"
      @close="closeNotification"
      v-if="notifications.length > 0"
    />

    <Announcement
      v-for="announcement in userAnnouncements"
      class="mb-4"
      :model="announcement"
      :key="announcement.id"
      @close="close"
    />

    <div class="bg-red-200 border-red-600 text-red-600"></div>
    <div class="bg-green-200 border-green-600 text-green-600"></div>
    <div class="bg-yellow-200 border-yellow-600 text-yellow-600"></div>
    <div class="bg-blue-200 border-blue-600 text-blue-600"></div>
  </div>
</template>

<script>
import Announcement from "@/components/announcement/Announcement.vue";
import UserAnnouncements from "@/graphql/queries/announcement/userAnnouncements.gql";
import { errorMixin } from "@/mixins/error.js";
import { notificationMixin } from "@/mixins/notification.js";

export default {
  props: {
    type_id: {
      type: Number,
      required: true,
    },
  },
  mixins: [errorMixin, notificationMixin],
  components: { Announcement },
  data() {
    return {
      userAnnouncements: [],
    };
  },
  created() {
    let channel = this.$pusher.subscribe("announcements");
    channel.bind("App\\Events\\AnnouncementChanged", this.refetch);
  },
  apollo: {
    userAnnouncements: {
      query: UserAnnouncements,
      fetchPolicy: "no-cache",
      variables() {
        return {
          filter: {
            is_active: true,
            announcement_type_id: this.type_id,
            order_by_order: "asc",
          },
        };
      },
      error(error) {
        this.handleError(error);
      },
    },
  },
  methods: {
    close(announcement) {
      this.userAnnouncements = this.userAnnouncements.filter(
        (item) => item.id !== announcement.id
      );
    },
    refetch() {
      this.clearNotifications();

      this.$apollo.queries.userAnnouncements.refetch().catch((error) => {
        this.handleError(error);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
