import announcementRoutes from "@/router/modules/system/announcement.js";
import limboStepRoutes from "@/router/modules/system/limbo-step.js";
import limboStepArticleArticleRoutes from "@/router/modules/system/limbo-step-article.js";
import applicationFormFeeRoutes from "@/router/modules/system/application-form-fee.js";
import applicationFormIncomeRoutes from "@/router/modules/system/application-form-income.js";
import applicationFormCountryIncomeDiscountRoutes from "@/router/modules/system/application-form-country-income-discount.js";
import applicationFormCountryIncomeFeeRoutes from "@/router/modules/system/application-form-country-income-fee.js";
import applicationFormPageRoutes from "@/router/modules/system/page.js";
import applicationFormPageAssignmentRoutes from "@/router/modules/system/page-assignment.js";
import applicationFormDiscountRoutes from "@/router/modules/system/application-form-discount.js";
import emailLogRoutes from "@/router/modules/system/email-log.js";
import authLogRoutes from "@/router/modules/system/auth-log.js";
import noteRoutes from "@/router/modules/system/note.js";
import logRoutes from "@/router/modules/system/log.js";
import applicationFormPartRoutes from "@/router/modules/system/application-form-part.js";
import applicationFormCountryRoutes from "@/router/modules/system/application-form-country.js";
import timeZoneRoutes from "@/router/modules/system/time-zone.js";
import emailLayoutRoutes from "@/router/modules/system/email-layout.js";
import emailNotificationRoutes from "@/router/modules/system/email-notification.js";
import cronEmailLayoutRoutes from "@/router/modules/system/cron-email-layout.js";
import evaluationVideoCommentRoutes from "@/router/modules/system/evaluation-video-comment.js";
import developmentalProfileItemRoutes from "@/router/modules/system/developmental-profile-item.js";
import commonFormRoutes from "@/router/modules/system/common-form.js";

const systemFormRoutes = [
  ...announcementRoutes,
  ...applicationFormPageRoutes,
  ...applicationFormPageAssignmentRoutes,
  ...applicationFormFeeRoutes,
  ...applicationFormIncomeRoutes,
  ...applicationFormCountryIncomeDiscountRoutes,
  ...applicationFormCountryIncomeFeeRoutes,
  ...applicationFormDiscountRoutes,
  ...limboStepRoutes,
  ...limboStepArticleArticleRoutes,
  ...emailLogRoutes,
  ...authLogRoutes,
  ...noteRoutes,
  ...logRoutes,
  ...applicationFormPartRoutes,
  ...applicationFormCountryRoutes,
  ...timeZoneRoutes,
  ...cronEmailLayoutRoutes,
  ...emailLayoutRoutes,
  ...emailNotificationRoutes,
  ...evaluationVideoCommentRoutes,
  ...developmentalProfileItemRoutes,
  ...commonFormRoutes,
];

export default systemFormRoutes;
