import AuthService from "@/services/AuthService";
import { StaffLogin } from "@/graphql/mutations/auth/staffLogin.gql";
import { StaffLoginAsStaff } from "@/graphql/mutations/auth/staffLoginAsStaff.gql";

import { StaffResetPassword } from "@/graphql/mutations/auth/staffResetPassword.gql";
import { apolloClient } from "@/apollo";
import { SUCCESS, NO_PROFILE_IMAGE } from "@/constants";
import { THEME_LIGHT, THEME_DARK, THEME_COLORFUL } from "@/constants";

let authService = new AuthService();

export const namespaced = true;

export const state = {
  user: authService.getUser(),
  loginAs: authService.getLoginAs(),
};

export const mutations = {
  SET_LOGIN_AS(state, value) {
    state.loginAs = value;
  },
  SET_USER(state, user) {
    state.user = user;
  },
};

export const actions = {
  update({ commit }, user) {
    if (user.user_id === authService.id()) {
      commit("SET_USER", user);
      authService.setUser(user);
    }
  },
  resetPassword({ commit, dispatch }, model) {
    return apolloClient
      .mutate({
        mutation: StaffResetPassword,
        variables: {
          model,
        },
      })
      .then((response) => {
        let data = response.data.staffResetPassword;
        commit("SET_USER", data.staff);
        authService.setData(data);

        let notification = {
          type: SUCCESS,
          message: "You are successfully logged in!",
        };

        dispatch("notification/add", notification, { root: true });
      });
  },
  login({ commit, dispatch }, credentials) {
    return apolloClient
      .mutate({
        mutation: StaffLogin,
        variables: {
          login: credentials,
        },
      })
      .then((response) => {
        let data = response.data.staffLogin;
        commit("SET_USER", data.staff);
        authService.setData(data);

        let notification = {
          type: SUCCESS,
          message: "You are successfully signed in!",
        };

        dispatch("notification/add", notification, { root: true });
      });
  },
  loginAsStaff({ commit, dispatch }, credentials) {
    return apolloClient
      .mutate({
        mutation: StaffLoginAsStaff,
        variables: {
          login: credentials,
        },
      })
      .then((response) => {
        let data = response.data.staffLoginAsStaff;
        commit("SET_USER", data.staff);
        commit("SET_LOGIN_AS", true);
        authService.setLoginAs(true);
        authService.setData(data);

        let notification = {
          type: SUCCESS,
          message: "You are successfully signed in!",
        };

        dispatch("notification/add", notification, { root: true });

        return data;
      });
  },
  logoutAndLoginAsFamily({ commit }, username) {
    authService.logout().then(() => {
      commit("SET_USER", null);
      commit("SET_LOGIN_AS", false);

      window.location.href = `${process.env.VUE_APP_FAMILY_URL}/login-as-family?family_username=${username}`;
    });
  },
  logoutAndLoginAsStaff({ dispatch }, username) {
    authService.logoutAndLoginAsStaff(username).then(() => {
      dispatch("afterLogout");
    });
  },
  logout({ dispatch }) {
    authService.logout().then(() => {
      dispatch("afterLogout");
    });
  },
  afterLogout({ commit, dispatch }) {
    commit("SET_USER", null);
    commit("SET_LOGIN_AS", false);

    let notification = {
      type: SUCCESS,
      message: "You are successfully signed out!",
    };

    dispatch("notification/add", notification, { root: true });
  },
};

export const getters = {
  photo: (state) =>
    state.user && state.user.photo ? state.user.photo : NO_PROFILE_IMAGE,
  permissions(state) {
    return state.user
      ? state.user.user.roles[0].permissions.map((item) => item.name)
      : [];
  },
  isDark: (state) => {
    return state.user.theme_id === THEME_DARK;
  },
  isLight(state) {
    return state.user.theme_id === THEME_LIGHT;
  },
  isColorful(state) {
    return state.user.theme_id === THEME_COLORFUL;
  },
};
