import UserAnnouncements from "@/graphql/queries/announcement/userAnnouncements.gql";
import { ANNOUNCEMENT_TYPE_STAFF } from "@/constants";

import { apolloClient } from "@/apollo";

export const namespaced = true;

export const state = {
  announcements: [],
};

export const mutations = {
  SET(state, announcements) {
    state.announcements = announcements;
  },
};

export const actions = {
  fetch({ commit }) {
    return apolloClient
      .query({
        query: UserAnnouncements,
        fetchPolicy: "no-cache",
        variables: {
          filter: {
            is_active: true,
            announcement_type_id: ANNOUNCEMENT_TYPE_STAFF,
            order_by_order: "asc",
          },
        },
      })
      .then((response) => {
        commit("SET", response.data.userAnnouncements);
      });
  },
};
