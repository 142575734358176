import { APPLICATION_FORM_DISCOUNTS } from "@/rbac/links.js";

const applicationFormDiscountRoutes = [
  {
    path: "/application-form-discounts",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "ApplicationFormDiscountIndex",
        meta: {
          title: "Application Form Discounts",
          permission: APPLICATION_FORM_DISCOUNTS,
        },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,

          price: parseInt(route.query.price) || null,
          order_by_price: route.query.order_by_price || null,
        }),
        component: () =>
          import(
            /* webpackChunkName: "application-form-discount-index" */ "@/views/system/application-form-discount/Index.vue"
          ),
      },
    ],
  },
];

export default applicationFormDiscountRoutes;
