const formComponents = require.context(
  "@/components/form",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const buttonComponents = require.context(
  "@/components/buttons",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const loaderComponents = require.context(
  "@/components/loaders",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const messageComponents = require.context(
  "@/components/messages",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const iconComponents = require.context(
  "@/components/icons",
  false,
  /Base[A-Z]\w+\.(vue|js)$/
);

const baseComponents = [
  formComponents,
  buttonComponents,
  loaderComponents,
  messageComponents,
  iconComponents,
];

export default baseComponents;
