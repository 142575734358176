import {
  EVALUATION_VIEW_CURRENT,
  EVALUATION_EDIT_CURRENT,
  EVALUATION_CREATE_NEW,
  EVALUATION_VIEW_ARCHIVE,
} from "@/rbac/links.js";

import { VIEW_EVALUATION, UPDATE_EVALUATION } from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_id || "desc";

  if (
    route.query.order_by_date ||
    route.query.order_by_type_name ||
    route.query.order_by_status_name ||
    route.query.order_by_is_visible_name
  ) {
    orderByDefault = null;
  }

  return {
    id: route.params.id,

    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    order_by_id: orderByDefault,

    order_by_date: route.query.order_by_date || null,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    is_visible: route.query.is_visible
      ? parseInt(route.query.is_visible)
      : null,
    order_by_is_visible_name: route.query.order_by_is_visible_name || null,
  };
}

const clientEvaluationRoutes = [
  {
    path: "evaluations",
    name: "ClientEvaluationIndex",
    meta: { title: "Evaluation Archive", permission: EVALUATION_VIEW_ARCHIVE },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-index" */ "@/views/client/evaluation/Index.vue"
      ),
  },
  {
    path: "evaluations/create",
    name: "ClientEvaluationCreate",
    meta: { title: "Create Evaluation", permission: EVALUATION_CREATE_NEW },
    props: (route) => ({
      id: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-create" */ "@/views/client/evaluation/Create.vue"
      ),
  },
  {
    path: "evaluations/edit-current",
    name: "ClientEvaluationEditCurrent",
    meta: { title: "Edit Evaluation", permission: EVALUATION_EDIT_CURRENT },
    props: (route) => ({
      id: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-edit-current" */ "@/views/client/evaluation/EditCurrent.vue"
      ),
  },
  {
    path: "evaluations/view-current",
    name: "ClientEvaluationViewCurrent",
    meta: { title: "View Evaluation", permission: EVALUATION_VIEW_CURRENT },
    props: (route) => ({
      id: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-view-current" */ "@/views/client/evaluation/ViewCurrent.vue"
      ),
  },
  {
    path: "evaluations/:evaluationId",
    name: "ClientEvaluationView",
    meta: { title: "View Evaluation", permission: VIEW_EVALUATION },
    props: (route) => ({
      id: route.params.id,
      evaluationId: parseInt(route.params.evaluationId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-view" */ "@/views/client/evaluation/View.vue"
      ),
  },
  {
    path: "evaluations/:evaluationId/edit",
    name: "ClientEvaluationEdit",
    meta: { title: "Edit Evaluation", permission: UPDATE_EVALUATION },
    props: (route) => ({
      id: route.params.id,
      evaluationId: parseInt(route.params.evaluationId),
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-evaluation-edit" */ "@/views/client/evaluation/Edit.vue"
      ),
  },
];

export default clientEvaluationRoutes;
