import {
  MANAGE_STAFF_PROFILES,
  CREATE_STAFF_PROFILE,
  MANAGE_STAFF_TEAM,
  STAFF_CHANGE_PASSWORD,
} from "@/rbac/links.js";

import { VIEW_STAFF, UPDATE_STAFF } from "@/rbac/permissions.js";

import { staffProps } from "@/services/props.js";

const staffRoutes = [
  {
    path: "/staff",
    name: "StaffLayout",
    component: () =>
      import(/* webpackChunkName: "staff-layout" */ "@/views/staff/Layout.vue"),
    children: [
      {
        path: "",
        name: "StaffIndex",
        meta: { title: "Manage Staff", permission: MANAGE_STAFF_PROFILES },
        props: (route) => staffProps(route),
        component: () =>
          import(
            /* webpackChunkName: "staff-index" */ "@/views/staff/Index.vue"
          ),
      },
      {
        path: "create",
        name: "StaffCreate",
        meta: {
          title: "Create Staff Profile",
          permission: CREATE_STAFF_PROFILE,
        },
        component: () =>
          import(
            /* webpackChunkName: "staff-create" */ "@/views/staff/Create.vue"
          ),
      },
      {
        path: "team",
        name: "StaffTeam",
        meta: { title: "Manage Staff Team", permission: MANAGE_STAFF_TEAM },
        component: () =>
          import(
            /* webpackChunkName: "staff-team" */ "@/views/staff-team/Index.vue"
          ),
      },
      {
        path: ":id",
        name: "StaffView",
        meta: { title: "View Staff Profile ", permission: VIEW_STAFF },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(/* webpackChunkName: "staff-view" */ "@/views/staff/View.vue"),
      },
      {
        path: ":id/edit",
        name: "StaffEdit",
        meta: { title: "Edit Staff Profile", permission: UPDATE_STAFF },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(/* webpackChunkName: "staff-edit" */ "@/views/staff/Edit.vue"),
      },
      {
        path: ":id/change-password",
        name: "StaffChangePassword",
        meta: {
          title: "Change Password Staff Profile",
          permission: STAFF_CHANGE_PASSWORD,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "staff-change-password" */ "@/views/staff/ChangePassword.vue"
          ),
      },
    ],
  },
];

export default staffRoutes;
