import { mapGetters } from "vuex";

const permissionMixin = {
  methods: {
    hasPermission(permission) {
      return this.permissions.includes(permission);
    },
  },
  computed: {
    ...mapGetters("user", ["permissions"]),
  },
};

export { permissionMixin };
