import {
  PROGRAM_VIEW_CURRENT,
  PROGRAM_EDIT_CURRENT,
  PROGRAM_CREATE_NEW,
  PROGRAM_VIEW_ARCHIVE,
} from "@/rbac/links.js";

import { VIEW_PROGRAM } from "@/rbac/permissions";

function props(route) {
  let orderByDefault = route.query.order_by_date || "desc";

  if (
    route.query.order_by_type_name ||
    route.query.order_by_status_name ||
    route.query.order_by_is_visible_name
  ) {
    orderByDefault = null;
  }

  return {
    id: route.params.id,

    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    order_by_date: orderByDefault,

    type_id: parseInt(route.query.type_id) || null,
    order_by_type_name: route.query.order_by_type_name || null,

    status_id: parseInt(route.query.status_id) || null,
    order_by_status_name: route.query.order_by_status_name || null,

    is_visible: route.query.is_visible
      ? parseInt(route.query.is_visible)
      : null,
    order_by_is_visible_name: route.query.order_by_is_visible_name || null,
  };
}

const clientProgramRoutes = [
  {
    path: "programs",
    name: "ClientProgramIndex",
    meta: { title: "Program Archive", permission: PROGRAM_VIEW_ARCHIVE },
    props: (route) => props(route),
    component: () =>
      import(
        /* webpackChunkName: "client-program-index" */ "@/views/client/program/Index.vue"
      ),
  },
  {
    path: "programs/create",
    name: "ClientProgramCreate",
    meta: { title: "Create Program", permission: PROGRAM_CREATE_NEW },
    props: (route) => ({
      id: route.params.id,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-program-create" */ "@/views/client/program/Create.vue"
      ),
  },
  {
    path: "programs/edit-current",
    name: "ClientProgramEditCurrent",
    meta: { title: "Edit Program", permission: PROGRAM_EDIT_CURRENT },
    props: (route) => ({
      page: parseInt(route.query.page) || 1,
      limit: parseInt(route.query.limit) || 100,

      id: route.params.id,

      group: route.query.group || null,
      description: route.query.description || null,
      notes: route.query.notes || null,
      frequency: route.query.frequency || null,
      duration: route.query.duration || null,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-program-edit-current" */ "@/views/client/program/EditCurrent.vue"
      ),
  },
  {
    path: "programs/view-current",
    name: "ClientProgramViewCurrent",
    meta: { title: "View Program", permission: PROGRAM_VIEW_CURRENT },
    props: (route) => ({
      page: parseInt(route.query.page) || 1,
      limit: parseInt(route.query.limit) || 100,

      id: route.params.id,

      group: route.query.group || null,
      description: route.query.description || null,
      notes: route.query.notes || null,
      frequency: route.query.frequency || null,
      duration: route.query.duration || null,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-program-view-current" */ "@/views/client/program/ViewCurrent.vue"
      ),
  },
  {
    path: "programs/:programId",
    name: "ClientProgramView",
    meta: { title: "View Program", permission: VIEW_PROGRAM },
    props: (route) => ({
      id: route.params.id,
      programId: parseInt(route.params.programId),

      page: parseInt(route.query.page) || 1,
      limit: parseInt(route.query.limit) || 100,

      group: route.query.group || null,
      description: route.query.description || null,
      notes: route.query.notes || null,
      frequency: route.query.frequency || null,
      duration: route.query.duration || null,
    }),
    component: () =>
      import(
        /* webpackChunkName: "client-program-view" */ "@/views/client/program/View.vue"
      ),
  },
];

export default clientProgramRoutes;
