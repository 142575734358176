import { VIEW_NACD_COURSE_CATEGORY } from "@/rbac/links.js";

const courseCategoryRoutes = [
  {
    path: "/academy/course-categories",
    component: () =>
      import(
        /* webpackChunkName: "academy-course-category-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: ":id",
        name: "AcademyCourseCategoryView",
        meta: {
          title: "View Course Category",
          permission: VIEW_NACD_COURSE_CATEGORY,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "academy-course-category-view" */ "@/views/academy/category/View.vue"
          ),
      },
    ],
  },
];

export default courseCategoryRoutes;
