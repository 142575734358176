import { COMMON_FORMS } from "@/rbac/links.js";

import {
  CREATE_COMMON_FORM,
  SORT_COMMON_FORM,
  UPDATE_COMMON_FORM,
  VIEW_COMMON_FORM,
} from "@/rbac/permissions.js";

function props(route) {
  let orderByDefault = route.query.order_by_order || "asc";

  if (route.query.order_by_id || route.query.order_by_name) {
    orderByDefault = null;
  }

  return {
    page: parseInt(route.query.page) || 1,
    limit: parseInt(route.query.limit) || 10,

    id: parseInt(route.query.id) || null,
    order_by_id: route.query.order_by_id || null,

    order: parseInt(route.query.order) || null,
    order_by_order: orderByDefault,

    name: route.query.name || null,
    order_by_name: route.query.order_by_name || null,
  };
}

const commonFormRoutes = [
  {
    path: "/frequently-used-files",
    component: () =>
      import(
        /* webpackChunkName: "system-layout" */ "@/views/system/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "CommonFormIndex",
        meta: {
          title: "Frequently Used Files",
          permission: COMMON_FORMS,
        },
        props: (route) => props(route),
        component: () =>
          import(
            /* webpackChunkName: "common-form-index" */ "@/views/system/common-form/Index.vue"
          ),
      },
      {
        path: "create",
        name: "CommonFormCreate",
        meta: {
          title: "Create Frequently Used File",
          permission: CREATE_COMMON_FORM,
        },
        component: () =>
          import(
            /* webpackChunkName: "common-form-create" */ "@/views/system/common-form/Create.vue"
          ),
      },
      {
        path: "sort",
        name: "CommonFormSort",
        meta: {
          title: "Sort Frequently Used Files",
          permission: SORT_COMMON_FORM,
        },
        component: () =>
          import(
            /* webpackChunkName: "common-form-sort" */ "@/views/system/common-form/Sort.vue"
          ),
      },
      {
        path: ":id/edit",
        name: "CommonFormEdit",
        meta: {
          title: "Edit Frequently Used File",
          permission: UPDATE_COMMON_FORM,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "common-form-edit" */ "@/views/system/common-form/Edit.vue"
          ),
      },
      {
        path: ":id/view",
        name: "CommonFormView",
        meta: {
          title: "View Frequently Used File",
          permission: VIEW_COMMON_FORM,
        },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "common-form-view" */ "@/views/system/common-form/View.vue"
          ),
      },
    ],
  },
];

export default commonFormRoutes;
