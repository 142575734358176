<template>
  <button v-bind="$attrs" :class="className">
    <slot name="icon" />
    <slot />
  </button>
</template>

<script>
import { sizeMixin } from "@/mixins/form.js";
import { mapGetters } from "vuex";

export default {
  props: {
    name: {
      type: String,
      required: true,
      default: "default",
    },
  },
  mixins: [sizeMixin],
  data() {
    return {
      baseClass:
        "flex justify-center items-center w-full transition ease-in duration-200 text-center font-semibold shadow-md",
    };
  },
  computed: {
    ...mapGetters("user", ["isLight", "isDark", "isColorful"]),
    className() {
      return `${this.baseClass} ${this.colorClassName} ${this.sizeClass}`;
    },
    colorClassName() {
      let result;
      switch (this.name) {
        default:
          result = "gray";
          break;
        case "secondary":
          return "text-gray-700 border border-gray-300 bg-white hover:bg-gray-50 focus:ring-gray-500 focus:ring-offset-gray-200";
        case "orange":
          result = "orange";
          break;
        case "success":
          result = "green";
          break;
        case "error":
          result = "red";
          break;
        case "warning":
          result = "yellow";
          break;
        case "info":
          result = "blue";
          break;
        case "indigo":
          result = "indigo";
          break;
        case "teal":
          result = "teal";
          break;
        case "purple":
          result = "purple";
          break;
        case "link":
          result = "text-white ";
          if (this.isDark) {
            result += "bg-slate-800";
          }
          if (this.isLight) {
            result += "bg-orange";
          }
          if (this.isColorful) {
            result += "bg-pink";
          }

          return result;
      }

      return `text-white bg-${result}-600 hover:bg-${result}-700 focus:ring-${result}-500 focus:ring-offset-${result}-200 focus:outline-none focus:ring-2 focus:ring-offset-2 `;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/option-links.scss";

.disabled {
  cursor: not-allowed;
  background-color: #d3d3d3;
}
</style>
