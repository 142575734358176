<template>
  <BaseLabel :label="label" v-if="label" />

  <textarea
    v-bind="$attrs"
    ref="textarea"
    :autofocus="autofocus"
    :class="[isInvalid ? invalidClass : activeClass]"
    :placeholder="placeholder ? placeholder : label"
    :value="modelValue"
    @input="onInput($event)"
    @focus="$emit('focus')"
  ></textarea>

  <BaseInputError :message="message" v-if="isInvalid" />
</template>

<script>
import { inputMixin, notRequiredFormFieldMixin } from "@/mixins/form.js";

export default {
  mixins: [inputMixin, notRequiredFormFieldMixin],
  mounted() {
    this.resize();
  },
  methods: {
    onInput(e) {
      this.$emit("update:modelValue", e.target.value);
      this.resize();
    },
    resize() {
      let element = this.$refs["textarea"];
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
    },
  },
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  overflow: hidden;
  min-height: 100px;
}
</style>
