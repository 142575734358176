import { ROLES } from "@/rbac/links.js";
import { VIEW_ROLE } from "@/rbac/permissions.js";

const roleRoutes = [
  {
    path: "/roles",
    component: () =>
      import(/* webpackChunkName: "rbac-layout" */ "@/views/rbac/Layout.vue"),
    children: [
      {
        path: "",
        name: "RoleIndex",
        meta: { title: "Roles", permission: ROLES },
        props: (route) => ({
          page: parseInt(route.query.page) || 1,
          limit: parseInt(route.query.limit) || 10,

          id: parseInt(route.query.id) || null,
          order_by_id: route.query.order_by_id || null,

          name: route.query.name || null,
          order_by_name: route.query.order_by_name || null,

          label: route.query.label || null,
          order_by_label: route.query.order_by_label || null,

          is_staff: route.query.is_staff
            ? parseInt(route.query.is_staff)
            : null,
          order_by_is_staff_name: route.query.order_by_is_staff_name || null,

          order_by_created_at: route.query.order_by_created_at || null,
          order_by_updated_at: route.query.order_by_updated_at || null,
        }),
        component: () =>
          import(/* webpackChunkName: "role-index" */ "@/views/role/Index.vue"),
      },
      {
        path: ":id/view",
        name: "RoleView",
        meta: { title: "View Role", permission: VIEW_ROLE },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(/* webpackChunkName: "role-view" */ "@/views/role/View.vue"),
      },
    ],
  },
];

export default roleRoutes;
