import { NACD_COURSES, VIEW_NACD_COURSE } from "@/rbac/links.js";

const courseRoutes = [
  {
    path: "/academy/courses",
    component: () =>
      import(
        /* webpackChunkName: "academy-course-layout-full-width" */ "@/views/course/Layout.vue"
      ),
    children: [
      {
        path: "",
        name: "AcademyCourseIndex",
        meta: { title: "Courses", permission: NACD_COURSES },
        component: () =>
          import(
            /* webpackChunkName: "academy-course-index" */ "@/views/academy/course/Index.vue"
          ),
      },
      {
        path: ":id",
        name: "AcademyCourseView",
        meta: { title: "View Course", permission: VIEW_NACD_COURSE },
        props: (route) => ({
          id: parseInt(route.params.id),
        }),
        component: () =>
          import(
            /* webpackChunkName: "academy-course-view" */ "@/views/academy/course/View.vue"
          ),
      },
    ],
  },
];

export default courseRoutes;
